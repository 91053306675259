import errorHandler from 'utils/apiHandler';
import { put } from 'redux-saga/effects';

import { CHANGE_PASSWORD_URL } from 'apis';
import { changePasswordStart, changePasswordSuccess, changePasswordFail } from 'store/actions';

// change password saga
export function* changePasswordSaga({ payload }) {
  const { data, toast, close } = payload;
  yield put(changePasswordStart());
  yield errorHandler({
    endpoint: CHANGE_PASSWORD_URL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(changePasswordSuccess(data));
      toast.success(response?.message);
      if (close) close();
    },
    failHandler: yield function* (response) {
      yield put(changePasswordFail(response));
      toast.error(response);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    payload: data,
    apiType: 'put',
    token: true,
  });
}
