export const GET_DASHBOARD_STATS_SAGA = 'GET_DASHBOARD_STATS_SAGA';
export const GET_DASHBOARD_STATS_START = 'GET_DASHBOARD_STATS_START';
export const GET_DASHBOARD_STATS_SUCCESS = 'GET_DASHBOARD_STATS_SUCCESS';
export const GET_DASHBOARD_STATS_FAIL = 'GET_DASHBOARD_STATS_FAIL';

export const GET_RECENT_DEVICE_SAGA = 'GET_RECENT_DEVICE_SAGA';
export const GET_RECENT_DEVICE_START = 'GET_RECENT_DEVICE_START';
export const GET_RECENT_DEVICE_SUCCESS = 'GET_RECENT_DEVICE_SUCCESS';
export const GET_RECENT_DEVICE_FAIL = 'GET_RECENT_DEVICE_FAIL';

export const GET_DASHBOARD_SALES_SAGA = 'GET_DASHBOARD_SALES_SAGA';
export const GET_DASHBOARD_SALES_START = 'GET_DASHBOARD_SALES_START';
export const GET_DASHBOARD_SALES_SUCCESS = 'GET_DASHBOARD_SALES_SUCCESS';
export const GET_DASHBOARD_SALES_FAIL = 'GET_DASHBOARD_SALES_FAIL';

export const CLEAR_AUTH = 'CLEAR_AUTH';

export const GET_TOP_ARTISTS_SAGA = 'GET_TOP_ARTISTS_SAGA';
export const GET_TOP_ARTISTS_START = 'GET_TOP_ARTISTS_START';
export const GET_TOP_ARTISTS_SUCCESS = 'GET_TOP_ARTISTS_SUCCESS';
export const GET_TOP_ARTISTS_FAIL = 'GET_TOP_ARTISTS_FAIL';

export const GET_HOT_NFT_SAGA = 'GET_HOT_NFT_SAGA';
export const GET_HOT_NFT_START = 'GET_HOT_NFT_START';
export const GET_HOT_NFT_SUCCESS = 'GET_HOT_NFT_SUCCESS';
export const GET_HOT_NFT_FAIL = 'GET_HOT_NFT_FAIL';

export const GET_TOP_COLLECTIONS_SAGA = 'GET_TOP_COLLECTIONS_SAGA';
export const GET_TOP_COLLECTIONS_START = 'GET_TOP_COLLECTIONS_START';
export const GET_TOP_COLLECTIONS_SUCCESS = 'GET_TOP_COLLECTIONS_SUCCESS';
export const GET_TOP_COLLECTIONS_FAIL = 'GET_TOP_COLLECTIONS_FAIL';
