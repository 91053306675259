/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import ModalContext from './ModalContext';

const ModalState = ({ children }) => {
  const [modalState, setModalState] = useState({
    open: false,
    title: '',
    message: '',
    notifyType: 1,
    autoClose: true,
    redirectURL: '',
    buttonClick: undefined,
    showCancelbtn: true,
    showPrimaryButton: true,
    showCloseButton: true,
    handleClick: '',
    handleCloseModal: null,
    FooterComponent: '',
    customModalOpen: false,
    customModalType: '',
    tempCustomModalData: null,
    geolocationModalOpen: false,
  });

  const openModal = props => {
    setModalState({
      ...modalState,
      ...props,
    });
  };
  const closeModal = () => {
    setModalState({
      open: false,
      title: '',
      message: '',
      notifyType: 1,
      autoClose: true,
      redirectURL: '',
      handleClick: '',
      buttonClick: undefined,
      showCancelbtn: true,
      showPrimaryButton: true,
      showCloseButton: true,
      handleCloseModal: null,
      FooterComponent: '',
      customModalOpen: false,
      customModalType: '',
      tempCustomModalData: null,
      geolocationModalOpen: false,
    });
  };
  const State = {
    modalState,
    openModal,
    closeModal,
  };
  return <ModalContext.Provider value={State}>{children}</ModalContext.Provider>;
};
export default ModalState;
