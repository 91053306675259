export const GET_TRANSACTION_LIST_SAGA = 'GET_TRANSACTION_LIST_SAGA';
export const GET_TRANSACTION_LIST_START = 'GET_TRANSACTION_LIST_START';
export const GET_TRANSACTION_LIST_SUCCESS = 'GET_TRANSACTION_LIST_SUCCESS';
export const GET_TRANSACTION_LIST_FAIL = 'GET_TRANSACTION_LIST_FAIL';

export const GET_NFT_TRANSACTIONS_LIST_SAGA = 'GET_NFT_TRANSACTIONS_LIST_SAGA';
export const GET_NFT_TRANSACTIONS_LIST_START = 'GET_NFT_TRANSACTIONS_LIST_START';
export const GET_NFT_TRANSACTIONS_LIST_SUCCESS = 'GET_NFT_TRANSACTIONS_LIST_SUCCESS';
export const GET_NFT_TRANSACTIONS_LIST_FAIL = 'GET_NFT_TRANSACTIONS_LIST_FAIL';

export const GET_FUND_TRANSACTIONS_LIST_SAGA = 'GET_FUND_TRANSACTIONS_LIST_SAGA';
export const GET_FUND_TRANSACTIONS_LIST_START = 'GET_FUND_TRANSACTIONS_LIST_START';
export const GET_FUND_TRANSACTIONS_LIST_SUCCESS = 'GET_FUND_TRANSACTIONS_LIST_SUCCESS';
export const GET_FUND_TRANSACTIONS_LIST_FAIL = 'GET_FUND_TRANSACTIONS_LIST_FAIL';
