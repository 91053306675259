import * as actionLabels from '../../actionLabels';
import {
  GET_USER_DEVICE_LIST_START,
  GET_USER_DEVICE_LIST_FAIL,
  GET_USER_DEVICE_LIST_SUCCESS,
  GET_USER_DEVICE_LIST_SAGA,
  GET_USER_LIST_START,
  GET_USER_LIST_FAIL,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_SAGA,
  DISABLE_USER_START,
  DISABLE_USER_FAIL,
  DISABLE_USER_SUCCESS,
  DISABLE_USER_SAGA,
} from 'store/actionLabels';

// get user list
export const getUserListAction = payload => ({
  type: GET_USER_LIST_SAGA,
  payload,
});

export const getUserListStart = payload => ({
  type: GET_USER_LIST_START,
  payload,
});

export const getUserListSuccess = payload => ({
  type: GET_USER_LIST_SUCCESS,
  payload,
});

export const getUserListFail = payload => ({
  type: GET_USER_LIST_FAIL,
  payload,
});

// get user details
export const getUserDetailsAction = payload => ({
  type: actionLabels.GET_USER_DETAILS_SAGA,
  payload,
});

export const getUserDetailsStart = payload => ({
  type: actionLabels.GET_USER_DETAILS_START,
  payload,
});

export const getUserDetailsSuccess = payload => ({
  type: actionLabels.GET_USER_DETAILS_SUCCESS,
  payload,
});

export const getUserDetailsFail = payload => ({
  type: actionLabels.GET_USER_DETAILS_FAIL,
  payload,
});

// disable user
export const disableUserAction = payload => ({
  type: DISABLE_USER_SAGA,
  payload,
});

export const disableUserStart = payload => ({
  type: DISABLE_USER_START,
  payload,
});

export const disableUserSuccess = payload => ({
  type: DISABLE_USER_SUCCESS,
  payload,
});

export const disableUserFail = payload => ({
  type: DISABLE_USER_FAIL,
  payload,
});

// get user device list
export const getUserDeviceListAction = payload => ({
  type: GET_USER_DEVICE_LIST_SAGA,
  payload,
});

export const getUserDeviceListStart = payload => ({
  type: GET_USER_DEVICE_LIST_START,
  payload,
});

export const getUserDeviceListSuccess = payload => ({
  type: GET_USER_DEVICE_LIST_SUCCESS,
  payload,
});

export const getUserDeviceListFail = payload => ({
  type: GET_USER_DEVICE_LIST_FAIL,
  payload,
});

//  suspend user
export const suspendUserAction = payload => ({
  type: actionLabels.SUSPEND_USER_SAGA,
  payload,
});

export const suspendUserStart = payload => ({
  type: actionLabels.SUSPEND_USER_START,
  payload,
});

export const suspendUserSuccess = payload => ({
  type: actionLabels.SUSPEND_USER_SUCCESS,
  payload,
});

export const suspendUserFail = payload => ({
  type: actionLabels.SUSPEND_USER_FAIL,
  payload,
});

//  get user collecton list
export const getUserCollectionListAction = payload => ({
  type: actionLabels.GET_USER_COLLECTION_LIST_SAGA,
  payload,
});

export const getUserCollectionListStart = payload => ({
  type: actionLabels.GET_USER_COLLECTION_LIST_START,
  payload,
});

export const getUserCollectionListSuccess = payload => ({
  type: actionLabels.GET_USER_COLLECTION_LIST_SUCCESS,
  payload,
});

export const getUserCollectionListFail = payload => ({
  type: actionLabels.GET_USER_COLLECTION_LIST_FAIL,
  payload,
});

//  get user transaction list
export const getUserTransactionListAction = payload => ({
  type: actionLabels.GET_USER_TRANSACTION_LIST_SAGA,
  payload,
});

export const getUserTransactionListStart = payload => ({
  type: actionLabels.GET_USER_TRANSACTION_LIST_START,
  payload,
});

export const getUserTransactionListSuccess = payload => ({
  type: actionLabels.GET_USER_TRANSACTION_LIST_SUCCESS,
  payload,
});

export const getUserTransactionListFail = payload => ({
  type: actionLabels.GET_USER_TRANSACTION_LIST_FAIL,
  payload,
});
