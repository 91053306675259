const Notification = () => {
    return (
       <svg width="18" height="18" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
       <g clip-path="url(#clip0_2940_23972)">
       <path d="M12.0196 3.41016C8.7096 3.41016 6.0196 6.10016 6.0196 9.41016V12.3002C6.0196 12.9102 5.7596 13.8402 5.4496 14.3602L4.2996 16.2702C3.5896 17.4502 4.0796 18.7602 5.3796 19.2002C9.6896 20.6402 14.3396 20.6402 18.6496 19.2002C19.8596 18.8002 20.3896 17.3702 19.7296 16.2702L18.5796 14.3602C18.2796 13.8402 18.0196 12.9102 18.0196 12.3002V9.41016C18.0196 6.11016 15.3196 3.41016 12.0196 3.41016Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
       <path d="M13.8699 3.70043C13.5599 3.61043 13.2399 3.54043 12.9099 3.50043C11.9499 3.38043 11.0299 3.45043 10.1699 3.70043C10.4599 2.96043 11.1799 2.44043 12.0199 2.44043C12.8599 2.44043 13.5799 2.96043 13.8699 3.70043Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
       <path d="M15.0195 19.5596C15.0195 21.2096 13.6695 22.5596 12.0195 22.5596C11.1995 22.5596 10.4395 22.2196 9.89953 21.6796C9.35953 21.1396 9.01953 20.3796 9.01953 19.5596" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
       </g>
       </svg>
    )
  }
  
export default Notification;