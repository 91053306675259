import { useSelector } from 'react-redux';
import { Navbar, NavbarBrand } from 'reactstrap';

import { ATOMIC_LOGO } from 'assets/images';

const GuestHeader = ({ children }) => {
  const { Theme } = useSelector(state => state.auth);

  return (
    <div>
      <Navbar className="guest-header justify-content-between" expand="md" data-theme={Theme}>
        <NavbarBrand href="/">
          <div className="d-flex align-items-center auth-logo ps-3">
            <img src={ATOMIC_LOGO} alt="logo" className="logo logo-dark img-fluid h30px" id='guest_header_logo' />
            <h5 className="ms-2">ATOMIC PEOPLE</h5>
          </div>
        </NavbarBrand>
      </Navbar>
      {children}
    </div>
  );
};

export default GuestHeader;
