import { toast } from 'react-toastify';
import { put } from 'redux-saga/effects';
import * as ACTIONS from '../../actions';
import errorHandler from 'utils/apiHandler';
import { UPDATE_CMS_URL, GET_CMS_URL } from 'apis';

export function* getCmsContentSaga({ payload }) {
  yield put(ACTIONS.getCmsContentStart());
  const { requestBody } = payload;
  yield errorHandler({
    endpoint: GET_CMS_URL,
    successHandler: yield function* (response) {
      yield put(ACTIONS.getCmsContentSuccess(response));
    },
    failHandler: yield function* (response) {
      yield toast.error(response);
      yield put(ACTIONS.getCmsContentFail(response));
    },
    params: JSON.stringify(requestBody),
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    // token: true,
  });
}

export function* pushCmsContentSaga({ payload }) {
  const { id, body, setViewOnly, handleGetCmsContent } = payload;
  yield put(ACTIONS.pushCmsContentStart());
  yield errorHandler({
    endpoint: `${UPDATE_CMS_URL}/${id}`,
    successHandler: yield function* (response) {
      yield put(ACTIONS.pushCmsContentSuccess(response));
      toast.success(response?.msg || response?.message);
      if (setViewOnly) setViewOnly(true);
      if (handleGetCmsContent) handleGetCmsContent();
    },
    failHandler: yield function* (response) {
      yield put(ACTIONS.pushCmsContentFail(response));
      toast.error(response);
    },
    payload: body,
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'put',
    token: true,
  });
}
