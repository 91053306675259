import { Link } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';

export const calculateDuration = (startDate, endDate) => {
  // Parse the dates using Moment.js
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  // Calculate the duration in days, hours, and minutes
  const durationDays = endMoment.diff(startMoment, 'days');
  const durationHours = endMoment.diff(startMoment, 'hours') % 24;
  const durationMinutes = endMoment.diff(startMoment, 'minutes') % 60;

  // Construct the duration string
  let durationString = '';
  if (durationDays > 0) {
    durationString += `${durationDays} days `;
  }
  if (durationHours > 0) {
    durationString += `${durationHours} hours `;
  }
  if (durationMinutes > 0) {
    durationString += `${durationMinutes} mins`;
  }

  return durationString.trim();
};

export const formatDate = (date, type = 'DD-MM-YYYY', sep = '-') => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (type === 'YYYY-MM-DD') {
    return [year, month, day].join(sep);
  }
  if (type === 'DD-MM-YYYY') {
    return [day, month, year].join(sep);
  }
};

export const formatTime = (date, isSecond = false) => {
  let d = new Date(date);
  let hours = d.getHours();
  let minutes = d.getMinutes();
  let seconds = d.getSeconds();
  if (hours <= 9) hours = '0' + hours;
  if (minutes <= 9) minutes = '0' + minutes;
  if (seconds <= 9) seconds = '0' + seconds;

  if (isSecond) {
    return `${hours}:${minutes}:${seconds}`;
  } else {
    return `${hours}:${minutes}`;
  }
};

export const toTitleCase = (str = '') => {
  if (str) {
    const titleCase = str
      .toLowerCase()
      .split(' ')
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
    return titleCase;
  }
};

// to remove key-value pairs if value is empty
export const filteredData = data => {
  Object.entries(data)
    .filter(([key, value]) => value !== '')
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});
};

export const linkToPage = (to, name, target = '') => {
  if (target === 'blank') {
    return (
      <a
        id={!to ? '' : 'website_link'}
        href={to}
        rel="noreferrer"
        target="_blank"
        aria-disabled={!to}
      >
        {name}
      </a>
    );
  } else {
    return (
      <Link to={to} id="website_link">
        {name}
      </Link>
    );
  }
};

export const downloadDocument = documentURL => {
  const tag = document.createElement('a');
  tag.href = documentURL;
  document.body.appendChild(tag);
  tag.click();
  document.body.removeChild(tag);
};

export const toFixedDigit = (val = 0) => {
  if (typeof val === 'number') {
    return `$${val?.toFixed(2)}`;
  } else {
    return '-';
  }
};

export const maskedEmail = email => {
  const atIndex = email.indexOf('@');

  if (atIndex >= 3) {
    const hiddenEmail = email.substring(2, atIndex).replace(/./g, '*');
    const visibleEmail = email.substring(0, 2) + hiddenEmail + email.substring(atIndex);
    return visibleEmail;
  }

  return email;
};

export const roundValue = value => {
  if (!Number.isInteger(value)) {
    const stringValue = value?.toString();
    const decimalIndex = stringValue?.indexOf('.');
    if (decimalIndex !== -1 && stringValue?.length - decimalIndex - 1 > 6) {
      const roundedValue = Number(value)?.toFixed(6);
      return parseFloat(roundedValue) !== 0 ? roundedValue : '0';
    }
  }
  return value;
};

export const copyToClipboard = text => {
  const el = document.createElement('textarea');
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  toast.success('Copied to clipboard');
};
