/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

const initialState = {
  // get admin
  getAdminLoading: false,
  getAdminErrorMsg: '',
  admin: [],
  // get all admin
  getAllAdminLoading: false,
  getAllAdminErrorMsg: '',
  allAdmin: [],
  // get admin list
  getAdminListLoading: false,
  getAdminListErrorMsg: '',
  adminList: [],
  // create admin
  createAdminLoading: false,
  createAdminErrorMsg: '',
  // update admin
  updateAdminLoading: false,
  updateAdminErrorMsg: '',
  // delete admin
  deleteAdminLoading: false,
  deleteAdminErrorMsg: '',
  // disable enable admin
  disableEnableAdminLoading: false,
  disableEnableAdminErrorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // get admin
    case actionLabels.GET_ADMIN_START:
      return { ...state, getAdminLoading: true, getAdminErrorMsg: '' };
    case actionLabels.GET_ADMIN_SUCCESS:
      return {
        ...state,
        getAdminLoading: false,
        getAdminErrorMsg: '',
        admin: payload,
      };
    case actionLabels.GET_ADMIN_FAIL:
      return {
        ...state,
        getAdminLoading: false,
        getAdminErrorMsg: payload,
        admin: [],
      };

    // get all admin
    case actionLabels.GET_ALL_ADMIN_START:
      return { ...state, getAllAdminLoading: true, getAllAdminErrorMsg: '' };
    case actionLabels.GET_ALL_ADMIN_SUCCESS:
      return {
        ...state,
        getAllAdminLoading: false,
        getAllAdminErrorMsg: '',
        allAdmin: payload,
      };
    case actionLabels.GET_ALL_ADMIN_FAIL:
      return {
        ...state,
        getAllAdminLoading: false,
        getAllAdminErrorMsg: payload,
        allAdmin: [],
      };

    // get admin list
    case actionLabels.GET_ADMIN_LIST_START:
      return { ...state, getAdminListLoading: true, getAdminListErrorMsg: '' };
    case actionLabels.GET_ADMIN_LIST_SUCCESS:
      return {
        ...state,
        getAdminListLoading: false,
        getAdminListErrorMsg: '',
        adminList: payload,
      };
    case actionLabels.GET_ADMIN_LIST_FAIL:
      return {
        ...state,
        getAdminListLoading: false,
        getAdminListErrorMsg: payload,
        adminList: [],
      };

    // create admin
    case actionLabels.CREATE_ADMIN_START:
      return { ...state, createAdminLoading: true, createAdminErrorMsg: '' };
    case actionLabels.CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        createAdminLoading: false,
        createAdminErrorMsg: '',
      };
    case actionLabels.CREATE_ADMIN_FAIL:
      return {
        ...state,
        createAdminLoading: false,
        createAdminErrorMsg: payload,
      };

    // update admin
    case actionLabels.UPDATE_ADMIN_START:
      return { ...state, updateAdminLoading: true, updateAdminErrorMsg: '' };
    case actionLabels.UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        updateAdminLoading: false,
        updateAdminErrorMsg: '',
      };
    case actionLabels.UPDATE_ADMIN_FAIL:
      return {
        ...state,
        updateAdminLoading: false,
        updateAdminErrorMsg: payload,
      };

    // delete admin
    case actionLabels.DELETE_ADMIN_START:
      return { ...state, deleteAdminLoading: true, deleteAdminErrorMsg: '' };
    case actionLabels.DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        deleteAdminLoading: false,
        deleteAdminErrorMsg: '',
      };
    case actionLabels.DELETE_ADMIN_FAIL:
      return {
        ...state,
        deleteAdminLoading: false,
        deleteAdminErrorMsg: payload,
      };

    // disable enable admin
    case actionLabels.DISABLE_ENABLE_ADMIN_START:
      return { ...state, disableEnableAdminLoading: true, disableEnableAdminErrorMsg: '' };
    case actionLabels.DISABLE_ENABLE_ADMIN_SUCCESS:
      return {
        ...state,
        disableEnableAdminLoading: false,
        disableEnableAdminErrorMsg: '',
      };
    case actionLabels.DISABLE_ENABLE_ADMIN_FAIL:
      return {
        ...state,
        disableEnableAdminLoading: false,
        disableEnableAdminErrorMsg: payload,
      };

    default:
      return state;
  }
};
