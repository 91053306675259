import * as actionLabels from '../../actionLabels';

// get drop requests
export const getDropsRequestsAction = payload => ({
  type: actionLabels.GET_DROPS_REQUESTS_SAGA,
  payload,
});

export const getDropsRequestsStart = payload => ({
  type: actionLabels.GET_DROPS_REQUESTS_START,
  payload,
});

export const getDropsRequestsSuccess = payload => ({
  type: actionLabels.GET_DROPS_REQUESTS_SUCCESS,
  payload,
});

export const getDropsRequestsFail = payload => ({
  type: actionLabels.GET_DROPS_REQUESTS_FAIL,
  payload,
});

// get live drops
export const getLiveDropsAction = payload => ({
  type: actionLabels.GET_LIVE_DROPS_SAGA,
  payload,
});

export const getLiveDropsStart = payload => ({
  type: actionLabels.GET_LIVE_DROPS_START,
  payload,
});

export const getLiveDropsSuccess = payload => ({
  type: actionLabels.GET_LIVE_DROPS_SUCCESS,
  payload,
});

export const getLiveDropsFail = payload => ({
  type: actionLabels.GET_LIVE_DROPS_FAIL,
  payload,
});

// get drops details
export const getDropsDetailsAction = payload => ({
  type: actionLabels.GET_DROPS_DETAILS_SAGA,
  payload,
});

export const getDropsDetailsStart = payload => ({
  type: actionLabels.GET_DROPS_DETAILS_START,
  payload,
});

export const getDropsDetailsSuccess = payload => ({
  type: actionLabels.GET_DROPS_DETAILS_SUCCESS,
  payload,
});

export const getDropsDetailsFail = payload => ({
  type: actionLabels.GET_DROPS_DETAILS_FAIL,
  payload,
});

// approve drop
export const approveDropsAction = payload => ({
  type: actionLabels.APPROVE_DROPS_SAGA,
  payload,
});

export const approveDropsStart = payload => ({
  type: actionLabels.APPROVE_DROPS_START,
  payload,
});

export const approveDropsSuccess = payload => ({
  type: actionLabels.APPROVE_DROPS_SUCCESS,
  payload,
});

export const approveDropsFail = payload => ({
  type: actionLabels.APPROVE_DROPS_FAIL,
  payload,
});

// reject drop
export const rejectDropsAction = payload => ({
  type: actionLabels.REJECT_DROPS_SAGA,
  payload,
});

export const rejectDropsStart = payload => ({
  type: actionLabels.REJECT_DROPS_START,
  payload,
});

export const rejectDropsSuccess = payload => ({
  type: actionLabels.REJECT_DROPS_SUCCESS,
  payload,
});

export const rejectDropsFail = payload => ({
  type: actionLabels.REJECT_DROPS_FAIL,
  payload,
});
