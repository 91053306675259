/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

const initialState = {
  // get transaction list
  getTransactionListLoading: false,
  getTransactionListErrorMsg: '',
  transactionList: [],
  // get nft transactions list
  getNftTransactionsListLoading: false,
  getNftTransactionsListErrorMsg: '',
  nftTransactionsList: [],
  // get fund transactions list
  getFundTransactionsListLoading: false,
  getFundTransactionsListErrorMsg: '',
  fundTransactionsList: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // get transaction list
    case actionLabels.GET_TRANSACTION_LIST_START:
      return { ...state, getTransactionListLoading: true, getTransactionListErrorMsg: '' };
    case actionLabels.GET_TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        getTransactionListLoading: false,
        getTransactionListErrorMsg: '',
        transactionList: payload,
      };
    case actionLabels.GET_TRANSACTION_LIST_FAIL:
      return {
        ...state,
        getTransactionListLoading: false,
        getTransactionListErrorMsg: payload,
        transactionList: [],
      };

    // get nft transactions list
    case actionLabels.GET_NFT_TRANSACTIONS_LIST_START:
      return { ...state, getNftTransactionsListLoading: true, getNftTransactionsListErrorMsg: '' };
    case actionLabels.GET_NFT_TRANSACTIONS_LIST_SUCCESS:
      return {
        ...state,
        getNftTransactionsListLoading: false,
        getNftTransactionsListErrorMsg: '',
        nftTransactionsList: payload,
      };
    case actionLabels.GET_NFT_TRANSACTIONS_LIST_FAIL:
      return {
        ...state,
        getNftTransactionsListLoading: false,
        getNftTransactionsListErrorMsg: payload,
        nftTransactionsList: [],
      };

    // get fund transactions list
    case actionLabels.GET_FUND_TRANSACTIONS_LIST_START:
      return {
        ...state,
        getFundTransactionsListLoading: true,
        getFundTransactionsListErrorMsg: '',
      };
    case actionLabels.GET_FUND_TRANSACTIONS_LIST_SUCCESS:
      return {
        ...state,
        getFundTransactionsListLoading: false,
        getFundTransactionsListErrorMsg: '',
        fundTransactionsList: payload,
      };
    case actionLabels.GET_FUND_TRANSACTIONS_LIST_FAIL:
      return {
        ...state,
        getFundTransactionsListLoading: false,
        getFundTransactionsListErrorMsg: payload,
        fundTransactionsList: [],
      };

    default:
      return state;
  }
};
