/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

const initialState = {
  // change password
  changePasswordLoading: false,
  changePasswordErrorMSg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // change password
    case actionLabels.CHANGE_PASSWORD_START:
      return { ...state, changePasswordLoading: true, changePasswordErrorMsg: '' };
    case actionLabels.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordErrorMsg: '',
      };
    case actionLabels.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordErrorMsg: payload,
      };
    default:
      return state;
  }
};
