/* eslint-disable import/no-anonymous-default-export */
import {
  PUSH_CMS_CONTENT_START,
  GET_CMS_CONTENT_START,
  GET_CMS_CONTENT_FAIL,
  GET_CMS_CONTENT_SUCCESS,
  PUSH_CMS_CONTENT_SUCCESS,
  PUSH_CMS_CONTENT_FAIL,
} from 'store/actionLabels';

const initialState = {
  // cms content
  getCmsContentLoading: false,
  getCmsContentErrorMsg: '',
  content: {},
  // update content
  updateCmsContentLoading: false,
  updateCmsContentErrorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // get cms
    case GET_CMS_CONTENT_START:
      return {
        ...state,
        getCmsContentLoading: true,
        getCmsContentErrorMsg: '',
        content: {},
      };
    case GET_CMS_CONTENT_SUCCESS:
      return {
        ...state,
        getCmsContentLoading: false,
        getCmsContentErrorMsg: '',
        content: payload?.data,
      };
    case GET_CMS_CONTENT_FAIL: {
      return {
        ...state,
        getCmsContentLoading: false,
        getCmsContentErrorMsg: payload,
        content: {},
      };
    }
    // update cms
    case PUSH_CMS_CONTENT_START:
      return {
        ...state,
        updateCmsContentLoading: true,
        updateCmsContentErrorMsg: '',
      };
    case PUSH_CMS_CONTENT_SUCCESS:
      return {
        ...state,
        updateCmsContentLoading: false,
        updateCmsContentErrorMsg: '',
      };
    case PUSH_CMS_CONTENT_FAIL: {
      return {
        ...state,
        updateCmsContentLoading: false,
        updateCmsContentErrorMsg: payload,
      };
    }
    default:
      return state;
  }
};
