// create role
export const CREATE_ROLE_SAGA = 'CREATE_ROLE_SAGA';
export const CREATE_ROLE_START = 'CREATE_ROLE_START';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAIL = 'CREATE_ROLE_FAIL';

// update role
export const UPDATE_ROLE_SAGA = 'UPDATE_ROLE_SAGA';
export const UPDATE_ROLE_START = 'UPDATE_ROLE_START';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAIL = 'UPDATE_ROLE_FAIL';

// delete role
export const DELETE_ROLE_SAGA = 'DELETE_ROLE_SAGA';
export const DELETE_ROLE_START = 'DELETE_ROLE_START';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAIL = 'DELETE_ROLE_FAIL';

// get role dropdown list
export const GET_ROLE_LIST_SAGA = 'GET_ROLE_LIST_SAGA';
export const GET_ROLE_LIST_START = 'GET_ROLE_LIST_START';
export const GET_ROLE_LIST_SUCCESS = 'GET_ROLE_LIST_SUCCESS';
export const GET_ROLE_LIST_FAIL = 'GET_ROLE_LIST_FAIL';

// get all role
export const GET_ALL_ROLE_SAGA = 'GET_ALL_ROLE_SAGA';
export const GET_ALL_ROLE_START = 'GET_ALL_ROLE_START';
export const GET_ALL_ROLE_SUCCESS = 'GET_ALL_ROLE_SUCCESS';
export const GET_ALL_ROLE_FAIL = 'GET_ALL_ROLE_FAIL';

// get role
export const GET_ROLE_SAGA = 'GET_ROLE_SAGA';
export const GET_ROLE_START = 'GET_ROLE_START';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_FAIL = 'GET_ROLE_FAIL';

//get permissions
export const GET_PERMISSIONS_SAGA = 'GET_PERMISSIONS_SAGA';
export const GET_PERMISSIONS_START = 'GET_PERMISSIONS_START';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FAIL = 'GET_PERMISSIONS_FAIL';
