export const GET_USER_LIST_SAGA = 'GET_USER_LIST_SAGA';
export const GET_USER_LIST_START = 'GET_USER_LIST_START';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAIL = 'GET_USER_LIST_FAIL';

export const GET_USER_DETAILS_SAGA = 'GET_USER_DETAILS_SAGA';
export const GET_USER_DETAILS_START = 'GET_USER_DETAILS_START';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAIL = 'GET_USER_DETAILS_FAIL';

export const DISABLE_USER_SAGA = 'DISABLE_USER_SAGA';
export const DISABLE_USER_START = 'DISABLE_USER_START';
export const DISABLE_USER_SUCCESS = 'DISABLE_USER_SUCCESS';
export const DISABLE_USER_FAIL = 'DISABLE_USER_FAIL';

export const GET_USER_DEVICE_LIST_SAGA = 'GET_USER_DEVICE_LIST_SAGA';
export const GET_USER_DEVICE_LIST_START = 'GET_USER_DEVICE_LIST_START';
export const GET_USER_DEVICE_LIST_SUCCESS = 'GET_USER_DEVICE_LIST_SUCCESS';
export const GET_USER_DEVICE_LIST_FAIL = 'GET_USER_DEVICE_LIST_FAIL';

export const SUSPEND_USER_SAGA = 'SUSPEND_USER_SAGA';
export const SUSPEND_USER_START = 'SUSPEND_USER_START';
export const SUSPEND_USER_SUCCESS = 'SUSPEND_USER_SUCCESS';
export const SUSPEND_USER_FAIL = 'SUSPEND_USER_FAIL';

export const GET_USER_COLLECTION_LIST_SAGA = 'GET_USER_COLLECTION_LIST_SAGA';
export const GET_USER_COLLECTION_LIST_START = 'GET_USER_COLLECTION_LIST_START';
export const GET_USER_COLLECTION_LIST_SUCCESS = 'GET_USER_COLLECTION_LIST_SUCCESS';
export const GET_USER_COLLECTION_LIST_FAIL = 'GET_USER_COLLECTION_LIST_FAIL';

export const GET_USER_TRANSACTION_LIST_SAGA = 'GET_USER_TRANSACTION_LIST_SAGA';
export const GET_USER_TRANSACTION_LIST_START = 'GET_USER_TRANSACTION_LIST_START';
export const GET_USER_TRANSACTION_LIST_SUCCESS = 'GET_USER_TRANSACTION_LIST_SUCCESS';
export const GET_USER_TRANSACTION_LIST_FAIL = 'GET_USER_TRANSACTION_LIST_FAIL';
