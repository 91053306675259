/* eslint-disable react-hooks/exhaustive-deps */
import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import LogoLoader from 'components/UI/Spinner/LogoSpinner';
import { guestRoutes } from './routes';
import GuestHeader from 'views/Layout/header/GuestHeader';
import { getProfileDetailsAction } from 'store/actions';

import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/theme.scss';
import './app.css';

function App() {
  const dispatch = useDispatch();
  const tokenPresent = sessionStorage.getItem('authToken') ?? false;

  const pathname = window.location.pathname.split('/')[1];

  const redirectHandler = () => {
    const guestRoute = guestRoutes
      .filter(item => item.redirectRoute === undefined)
      .map(item => item.path);
    return !guestRoute.includes(`/${pathname}`) && sessionStorage.getItem('authToken') == null ? (
      <Redirect to="/signin" />
    ) : null;
  };

  let mainContent = (
    <>
      {guestRoutes.map(
        route =>
          route.redirectRoute === undefined && (
            <Route key={route.name} path={route.path} exact={route.exact} name={route.name}>
              <GuestHeader>
                <route.component />
              </GuestHeader>
            </Route>
          ),
      )}
      {redirectHandler()}
    </>
  );
  if (tokenPresent) {
    mainContent = (
      <Route path="/" component={lazy(() => import('./views/MainContainer/MainContainer'))} />
    );
  }

  useEffect(() => {
    if (tokenPresent) dispatch(getProfileDetailsAction());
  }, [tokenPresent]);

  return (
    <Suspense fallback={<LogoLoader />}>
      <div className={tokenPresent ? 'mainbody' : 'authbody'}>
        <BrowserRouter>
          <div className="toastcontainer">
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              theme="colored"
              progressClassName="toastProgress"
              bodyClassName="toastBody"
            />
          </div>
          <Switch>{mainContent}</Switch>
        </BrowserRouter>
      </div>
    </Suspense>
  );
}

export default App;
