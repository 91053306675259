const Transaction = () => {
  return (
    <><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.9083 17.2999C12.75 17.2999 12.5917 17.2415 12.4667 17.1165C12.225 16.8749 12.225 16.4749 12.4667 16.2332L16.6416 12.0582C16.8833 11.8165 17.2833 11.8165 17.525 12.0582C17.7667 12.2999 17.7667 12.6999 17.525 12.9415L13.35 17.1165C13.225 17.2332 13.0667 17.2999 12.9083 17.2999Z" fill="black"/>
    <path d="M17.0834 13.1162H2.91675C2.57508 13.1162 2.29175 12.8329 2.29175 12.4912C2.29175 12.1495 2.57508 11.8662 2.91675 11.8662H17.0834C17.4251 11.8662 17.7084 12.1495 17.7084 12.4912C17.7084 12.8329 17.4251 13.1162 17.0834 13.1162Z" fill="black"/>
    <path d="M2.91662 8.13288C2.75828 8.13288 2.59995 8.07454 2.47495 7.94954C2.23328 7.70788 2.23328 7.30788 2.47495 7.06621L6.64995 2.89121C6.89162 2.64954 7.29162 2.64954 7.53328 2.89121C7.77495 3.13288 7.77495 3.53288 7.53328 3.77454L3.35828 7.94954C3.24162 8.06621 3.07495 8.13288 2.91662 8.13288Z" fill="black"/>
    <path d="M17.0834 8.13379H2.91675C2.57508 8.13379 2.29175 7.85046 2.29175 7.50879C2.29175 7.16712 2.57508 6.88379 2.91675 6.88379H17.0834C17.4251 6.88379 17.7084 7.16712 17.7084 7.50879C17.7084 7.85046 17.4251 8.13379 17.0834 8.13379Z" fill="black"/>
    </svg>
    </>
  )
}

export default Transaction