/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

const initialState = {
  // get dashboard stats
  dashboardStatsLoading: false,
  dashboardStatsErrorMsg: '',
  dashboardStats: null,
  // get recent devices
  getRecentDeviceLoading: false,
  getRecentDeviceErrorMsg: '',
  recentDevicesList: [],
  // get dashboard sales
  dashboardSalesLoading: false,
  dashboardSalesErrorMsg: '',
  dashboardSales: [],
  // get top artists
  topArtistsLoading: false,
  topArtistsErrorMsg: '',
  topArtists: [],
  // get hot nft
  hotNftLoading: false,
  hotNftErrorMsg: '',
  hotNft: [],
  // get top collection
  topCollectionLoading: false,
  topCollectionErrorMsg: '',
  topCollection: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // get dashboard stats
    case actionLabels.GET_DASHBOARD_STATS_START:
      return { ...state, dashboardStatsLoading: true, dashboardStatsErrorMsg: '' };
    case actionLabels.GET_DASHBOARD_STATS_SUCCESS:
      return {
        ...state,
        dashboardStatsLoading: false,
        dashboardStatsErrorMsg: '',
        dashboardStats: payload,
      };
    case actionLabels.GET_DASHBOARD_STATS_FAIL:
      return {
        ...state,
        dashboardStatsLoading: false,
        dashboardStatsErrorMsg: payload,
        dashboardStats: null,
      };
    // get recent devices
    case actionLabels.GET_RECENT_DEVICE_START:
      return { ...state, getRecentDeviceLoading: true, getRecentDeviceErrorMsg: '' };
    case actionLabels.GET_RECENT_DEVICE_SUCCESS:
      return {
        ...state,
        getRecentDeviceLoading: false,
        getRecentDeviceErrorMsg: '',
        recentDevicesList: payload,
      };
    case actionLabels.GET_RECENT_DEVICE_FAIL:
      return {
        ...state,
        getRecentDeviceLoading: false,
        getRecentDeviceErrorMsg: payload,
        recentDevicesList: [],
      };
    // get dashboard sales
    case actionLabels.GET_DASHBOARD_SALES_START:
      return { ...state, dashboardSalesLoading: true, dashboardSalesErrorMsg: '' };
    case actionLabels.GET_DASHBOARD_SALES_SUCCESS:
      return {
        ...state,
        dashboardSalesLoading: false,
        dashboardSalesErrorMsg: '',
        dashboardSales: payload,
      };
    case actionLabels.GET_DASHBOARD_SALES_FAIL:
      return {
        ...state,
        dashboardSalesLoading: false,
        dashboardSalesErrorMsg: payload,
        dashboardSales: [],
      };

    // get top artists
    case actionLabels.GET_TOP_ARTISTS_START:
      return { ...state, topArtistsLoading: true, topArtistsErrorMsg: '' };
    case actionLabels.GET_TOP_ARTISTS_SUCCESS:
      return {
        ...state,
        topArtistsLoading: false,
        topArtistsErrorMsg: '',
        topArtists: payload,
      };
    case actionLabels.GET_TOP_ARTISTS_FAIL:
      return {
        ...state,
        topArtistsLoading: false,
        topArtistsErrorMsg: payload,
        topArtists: [],
      };

    // get hot nft
    case actionLabels.GET_HOT_NFT_START:
      return { ...state, hotNftLoading: true, hotNftErrorMsg: '' };
    case actionLabels.GET_HOT_NFT_SUCCESS:
      return {
        ...state,
        hotNftLoading: false,
        hotNftErrorMsg: '',
        hotNft: payload,
      };
    case actionLabels.GET_HOT_NFT_FAIL:
      return {
        ...state,
        hotNftLoading: false,
        hotNftErrorMsg: payload,
        hotNft: [],
      };

    // get top collection
    case actionLabels.GET_TOP_COLLECTIONS_START:
      return { ...state, topCollectionLoading: true, topCollectionErrorMsg: '' };
    case actionLabels.GET_TOP_COLLECTIONS_SUCCESS:
      return {
        ...state,
        topCollectionLoading: false,
        topCollectionErrorMsg: '',
        topCollection: payload,
      };
    case actionLabels.GET_TOP_COLLECTIONS_FAIL:
      return {
        ...state,
        topCollectionLoading: false,
        topCollectionErrorMsg: payload,
        topCollection: [],
      };

    default:
      return state;
  }
};
