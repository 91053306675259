import React, { useEffect, useState } from 'react';

import Dummy from '../../assets/images/avatar.jpg';

const CustomImageInput = ({
  PreviewUrl,
  ref = React.createRef(),
  setFieldValue,
  id,
  errorMessage,
  imageURL,
  handleImageChange,
  accept = '',
  isSubmit = false,
  handleUpload,
}) => {
  const [isTouched, setIsTouched] = useState(false);
  useEffect(() => {
    if (PreviewUrl) setFieldValue(PreviewUrl);
  }, [PreviewUrl]);

  return (
    <div style={{ flex: 2 }}>
      <div className="wrapper justify-content-start">
        {/* {(PreviewUrl || imageURL) && accept === '.glb' ? (
          <THREEdModelExportPoc
            url={PreviewUrl || `${process.env.REACT_APP_IMAGE_URL}${imageURL}`}
          />
        ) : ( */}
        <img
          //   className="rounded"
          src={PreviewUrl || (imageURL ? `${process.env.REACT_APP_IMAGE_URL}${imageURL}` : Dummy)}
          alt="preview"
          style={{ height: '50px', width: '50px' }}
        />
        {/* )} */}
        <input
          name={id}
          ref={ref}
          type="file"
          id="file"
          onChange={handleImageChange}
          className="form-control d-none"
          accept={accept}
        />
        <div
          className="cursor-pointer mx-2 link link_color"
          onClick={() => {
            handleUpload(ref);
            setIsTouched(true);
          }}
        >
          Upload
        </div>
      </div>
      {(errorMessage || isTouched) && errorMessage && (
        <div className="invalid-feedback">{errorMessage}</div>
      )}
    </div>
  );
};

export default CustomImageInput;
