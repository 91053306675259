/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

const initialState = {
  // get role
  getRoleLoading: false,
  getRoleErrorMsg: '',
  role: [],
  // get all role
  getAllRoleLoading: false,
  getAllRoleErrorMsg: '',
  allRole: [],
  // get role list
  getRoleListLoading: false,
  getRoleListErrorMsg: '',
  roleList: [],
  // create role
  createRoleLoading: false,
  createRoleErrorMsg: '',
  // update role
  updateRoleLoading: false,
  updateRoleErrorMsg: '',
  // delete role
  deleteRoleLoading: false,
  deleteRoleErrorMsg: '',
  // get permissions
  getPermissionsLoading: false,
  getPermissionsErrorMsg: '',
  permissions: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // get role
    case actionLabels.GET_ROLE_START:
      return { ...state, getRoleLoading: true, getRoleErrorMsg: '' };
    case actionLabels.GET_ROLE_SUCCESS:
      return {
        ...state,
        getRoleLoading: false,
        getRoleErrorMsg: '',
        role: payload,
      };
    case actionLabels.GET_ROLE_FAIL:
      return {
        ...state,
        getRoleLoading: false,
        getRoleErrorMsg: payload,
        role: [],
      };

    // get all role
    case actionLabels.GET_ALL_ROLE_START:
      return { ...state, getAllRoleLoading: true, getAllRoleErrorMsg: '' };
    case actionLabels.GET_ALL_ROLE_SUCCESS:
      return {
        ...state,
        getAllRoleLoading: false,
        getAllRoleErrorMsg: '',
        allRole: payload,
      };
    case actionLabels.GET_ALL_ROLE_FAIL:
      return {
        ...state,
        getAllRoleLoading: false,
        getAllRoleErrorMsg: payload,
        allRole: [],
      };

    // get role list
    case actionLabels.GET_ROLE_LIST_START:
      return { ...state, getRoleListLoading: true, getRoleListErrorMsg: '' };
    case actionLabels.GET_ROLE_LIST_SUCCESS:
      return {
        ...state,
        getRoleListLoading: false,
        getRoleListErrorMsg: '',
        roleList: payload,
      };
    case actionLabels.GET_ROLE_LIST_FAIL:
      return {
        ...state,
        getRoleListLoading: false,
        getRoleListErrorMsg: payload,
        roleList: [],
      };

    // create role
    case actionLabels.CREATE_ROLE_START:
      return { ...state, createRoleLoading: true, createRoleErrorMsg: '' };
    case actionLabels.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        createRoleLoading: false,
        createRoleErrorMsg: '',
      };
    case actionLabels.CREATE_ROLE_FAIL:
      return {
        ...state,
        createRoleLoading: false,
        createRoleErrorMsg: payload,
      };

    // update role
    case actionLabels.UPDATE_ROLE_START:
      return { ...state, updateRoleLoading: true, updateRoleErrorMsg: '' };
    case actionLabels.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        updateRoleLoading: false,
        updateRoleErrorMsg: '',
      };
    case actionLabels.UPDATE_ROLE_FAIL:
      return {
        ...state,
        updateRoleLoading: false,
        updateRoleErrorMsg: payload,
      };

    // delete role
    case actionLabels.DELETE_ROLE_START:
      return { ...state, deleteRoleLoading: true, deleteRoleErrorMsg: '' };
    case actionLabels.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        deleteRoleLoading: false,
        deleteRoleErrorMsg: '',
      };
    case actionLabels.DELETE_ROLE_FAIL:
      return {
        ...state,
        deleteRoleLoading: false,
        deleteRoleErrorMsg: payload,
      };

    // get permissions
    case actionLabels.GET_PERMISSIONS_START:
      return { ...state, getPermissionsLoading: true, getPermissionsErrorMsg: '' };
    case actionLabels.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        getPermissionsLoading: false,
        getPermissionsErrorMsg: '',
        permissions: payload,
      };
    case actionLabels.GET_PERMISSIONS_FAIL:
      return {
        ...state,
        getPermissionsLoading: false,
        getPermissionsErrorMsg: payload,
        permissions: [],
      };

    default:
      return state;
  }
};
