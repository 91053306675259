/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

export const initialState = {
  // get user report list
  getUserReportListLoading: false,
  getUserReportListErrorMsg: '',
  userReportList: [],
  userReportListData: [],
  // get user report details
  getUserReportDetailsLoading: false,
  getUserReportDetailsErrorMsg: '',
  userReportDetailsList: [],
  // get user report collection list
  getUserReportCollectionListLoading: false,
  getUserReportCollectionListErrorMsg: '',
  userReportCollectionList: [],
  // get collection details
  getCollectionDetailsLoading: false,
  getCollectionDetailsErrorMsg: '',
  collectionDetails: null,
  // hide collection
  hideCollectionLoading: false,
  hideCollectionErrorMsg: '',
  //  get collection item list
  getCollectionItemListLoading: false,
  getCollectionItemListErrorMsg: '',
  collectionItemList: [],
  //  get user report collection item list
  getUserReportCollectionItemListLoading: false,
  getUserReportCollectionItemListErrorMsg: '',
  userReportCollectionItemList: [],
  //  get nft item details
  getNftItemDetailsLoading: false,
  getNftItemDetailsErrorMsg: '',
  nftItemDetails: null,
  // hide collection
  hideNftDetailsLoading: false,
  hideNftDetailsErrorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    //get user report list
    case actionLabels.GET_USER_REPORT_LIST_START:
      return { ...state, getUserReportListLoading: true, getUserReportListErrorMsg: '' };
    case actionLabels.GET_USER_REPORT_LIST_SUCCESS: {
      return {
        ...state,
        getUserReportListLoading: false,
        userReportList: payload?.data,
        getUserReportListErrorMsg: '',
      };
    }
    case actionLabels.GET_USER_REPORT_LIST_FAIL: {
      return {
        ...state,
        getUserReportListLoading: false,
        getUserReportListErrorMsg: payload,
        userReportList: [],
      };
    }
    //get user report details
    case actionLabels.GET_USER_REPORT_DETAILS_START:
      return { ...state, getUserReportDetailsLoading: true, getUserReportDetailsErrorMsg: '' };
    case actionLabels.GET_USER_REPORT_DETAILS_SUCCESS: {
      return {
        ...state,
        getUserReportDetailsLoading: false,
        userReportDetailsList: payload?.data,
        getUserReportDetailsErrorMsg: '',
      };
    }
    case actionLabels.GET_USER_REPORT_DETAILS_FAIL: {
      return {
        ...state,
        getUserReportDetailsLoading: false,
        getUserReportDetailsErrorMsg: payload,
        userReportDetailsList: [],
      };
    }

    //get user report collection list
    case actionLabels.GET_USER_REPORT_COLLECTION_LIST_START:
      return {
        ...state,
        getUserReportCollectionListLoading: true,
        getUserReportCollectionListErrorMsg: '',
      };
    case actionLabels.GET_USER_REPORT_COLLECTION_LIST_SUCCESS: {
      return {
        ...state,
        getUserReportCollectionListLoading: false,
        userReportCollectionList: payload?.data,
        getUserReportCollectionListErrorMsg: '',
      };
    }
    case actionLabels.GET_USER_REPORT_COLLECTION_LIST_FAIL: {
      return {
        ...state,
        getUserReportCollectionListLoading: false,
        getUserReportCollectionListErrorMsg: payload,
        userReportCollectionList: [],
      };
    }

    // get collection details
    case actionLabels.GET_COLLECTION_DETAILS_START:
      return {
        ...state,
        getCollectionDetailsLoading: true,
        getCollectionDetailsErrorMsg: '',
      };
    case actionLabels.GET_COLLECTION_DETAILS_SUCCESS: {
      return {
        ...state,
        getCollectionDetailsLoading: false,
        collectionDetails: payload?.data,
        getCollectionDetailsErrorMsg: '',
      };
    }
    case actionLabels.GET_COLLECTION_DETAILS_FAIL: {
      return {
        ...state,
        getCollectionDetailsLoading: false,
        getCollectionDetailsErrorMsg: payload,
        collectionDetails: null,
      };
    }

    // hide collection
    case actionLabels.HIDE_COLLECTION_START:
      return {
        ...state,
        hideCollectionLoading: true,
        hideCollectionErrorMsg: '',
      };
    case actionLabels.HIDE_COLLECTION_SUCCESS: {
      return {
        ...state,
        hideCollectionLoading: false,
        hideCollectionErrorMsg: '',
      };
    }
    case actionLabels.HIDE_COLLECTION_FAIL: {
      return {
        ...state,
        hideCollectionLoading: false,
        hideCollectionErrorMsg: payload,
      };
    }

    //  get collection item list
    case actionLabels.GET_COLLECTION_ITEM_LIST_START:
      return {
        ...state,
        getCollectionItemListLoading: true,
        getCollectionItemListErrorMsg: '',
      };
    case actionLabels.GET_COLLECTION_ITEM_LIST_SUCCESS: {
      return {
        ...state,
        getCollectionItemListLoading: false,
        collectionItemList: payload?.data,
        getCollectionItemListErrorMsg: '',
      };
    }
    case actionLabels.GET_COLLECTION_ITEM_LIST_FAIL: {
      return {
        ...state,
        getCollectionItemListLoading: false,
        getCollectionItemListErrorMsg: payload,
        collectionItemList: [],
      };
    }

    //  get user report collection item list
    case actionLabels.GET_USER_REPORT_COLLECTION_ITEM_LIST_START:
      return {
        ...state,
        getUserReportCollectionItemListLoading: true,
        getUserReportCollectionItemListErrorMsg: '',
      };
    case actionLabels.GET_USER_REPORT_COLLECTION_ITEM_LIST_SUCCESS: {
      return {
        ...state,
        getUserReportCollectionItemListLoading: false,
        userReportCollectionItemList: payload?.data,
        getUserReportCollectionItemListErrorMsg: '',
      };
    }
    case actionLabels.GET_USER_REPORT_COLLECTION_ITEM_LIST_FAIL: {
      return {
        ...state,
        getUserReportCollectionItemListLoading: false,
        getUserReportCollectionItemListErrorMsg: payload,
        userReportCollectionItemList: [],
      };
    }

    //  get nft item details
    case actionLabels.GET_NFT_ITEM_DETAILS_START:
      return {
        ...state,
        getNftItemDetailsLoading: true,
        getNftItemDetailsErrorMsg: '',
      };
    case actionLabels.GET_NFT_ITEM_DETAILS_SUCCESS: {
      return {
        ...state,
        getNftItemDetailsLoading: false,
        nftItemDetails: payload?.data,
        getNftItemDetailsErrorMsg: '',
      };
    }
    case actionLabels.GET_NFT_ITEM_DETAILS_FAIL: {
      return {
        ...state,
        getNftItemDetailsLoading: false,
        getNftItemDetailsErrorMsg: payload,
        nftItemDetails: null,
      };
    }

    //  nft details hide
    case actionLabels.HIDE_NFT_DETAILS_START:
      return {
        ...state,
        hideNftDetailsLoading: true,
        hideNftDetailsErrorMsg: '',
      };
    case actionLabels.HIDE_NFT_DETAILS_SUCCESS: {
      return {
        ...state,
        hideNftDetailsLoading: false,
        hideNftDetailsErrorMsg: '',
      };
    }
    case actionLabels.HIDE_NFT_DETAILS_FAIL: {
      return {
        ...state,
        hideNftDetailsLoading: false,
        hideNftDetailsErrorMsg: payload,
      };
    }

    default:
      return state;
  }
};
