import * as actionLabels from '../../actionLabels';

// get user report list
export const getUserReportListAction = payload => ({
  type: actionLabels.GET_USER_REPORT_LIST_SAGA,
  payload,
});

export const getUserReporListStart = payload => ({
  type: actionLabels.GET_USER_REPORT_LIST_START,
  payload,
});

export const getUserReporListSuccess = payload => ({
  type: actionLabels.GET_USER_REPORT_LIST_SUCCESS,
  payload,
});

export const getUserReporListFail = payload => ({
  type: actionLabels.GET_USER_REPORT_LIST_FAIL,
  payload,
});

// get user report details
export const getUserReportDetailsAction = payload => ({
  type: actionLabels.GET_USER_REPORT_DETAILS_SAGA,
  payload,
});

export const getUserReportDetailsStart = payload => ({
  type: actionLabels.GET_USER_REPORT_DETAILS_START,
  payload,
});

export const getUserReportDetailsSuccess = payload => ({
  type: actionLabels.GET_USER_REPORT_DETAILS_SUCCESS,
  payload,
});

export const getUserReportDetailsFail = payload => ({
  type: actionLabels.GET_USER_REPORT_DETAILS_FAIL,
  payload,
});

// get user report collection list
export const getUserReportCollectionListAction = payload => ({
  type: actionLabels.GET_USER_REPORT_COLLECTON_LIST_SAGA,
  payload,
});

export const getUserReportCollectionListStart = payload => ({
  type: actionLabels.GET_USER_REPORT_COLLECTION_LIST_START,
  payload,
});

export const getUserReportCollectionListSuccess = payload => ({
  type: actionLabels.GET_USER_REPORT_COLLECTION_LIST_SUCCESS,
  payload,
});

export const getUserReportCollectionListFail = payload => ({
  type: actionLabels.GET_USER_REPORT_COLLECTION_LIST_FAIL,
  payload,
});

// get collection details
export const getCollectionDetailsAction = payload => ({
  type: actionLabels.GET_COLLECTION_DETAILS_SAGA,
  payload,
});

export const getCollectionDetailsStart = payload => ({
  type: actionLabels.GET_COLLECTION_DETAILS_START,
  payload,
});

export const getCollectionDetailsSuccess = payload => ({
  type: actionLabels.GET_COLLECTION_DETAILS_SUCCESS,
  payload,
});

export const getCollectionDetailsFail = payload => ({
  type: actionLabels.GET_COLLECTION_DETAILS_FAIL,
  payload,
});

// hide collection 
export const hideCollectionAction = payload => ({
  type: actionLabels.HIDE_COLLECTION_SAGA,
  payload,
});

export const hideCollectionStart = payload => ({
  type: actionLabels.HIDE_COLLECTION_START,
  payload,
});

export const hideCollectionSuccess = payload => ({
  type: actionLabels.HIDE_COLLECTION_SUCCESS,
  payload,
});

export const hideCollectionFail = payload => ({
  type: actionLabels.HIDE_COLLECTION_FAIL,
  payload,
});

//  get collection item list 
export const getCollectionItemListAction = payload => ({
  type: actionLabels.GET_COLLECTION_ITEM_LIST_SAGA,
  payload,
});

export const getCollectionItemListStart = payload => ({
  type: actionLabels.GET_COLLECTION_ITEM_LIST_START,
  payload,
});

export const getCollectionItemListSuccess = payload => ({
  type: actionLabels.GET_COLLECTION_ITEM_LIST_SUCCESS,
  payload,
});

export const getCollectionItemListFail = payload => ({
  type: actionLabels.GET_COLLECTION_ITEM_LIST_FAIL,
  payload,
});

//  get user report collection item list 
export const getUserReportCollectionItemListAction = payload => ({
  type: actionLabels.GET_USER_REPORT_COLLECTION_ITEM_LIST_SAGA,
  payload,
});

export const getUserReportCollectionItemListStart = payload => ({
  type: actionLabels.GET_USER_REPORT_COLLECTION_ITEM_LIST_START,
  payload,
});

export const getUserReportCollectionItemListSuccess = payload => ({
  type: actionLabels.GET_USER_REPORT_COLLECTION_ITEM_LIST_SUCCESS,
  payload,
});

export const getUserReportCollectionItemListFail = payload => ({
  type: actionLabels.GET_USER_REPORT_COLLECTION_ITEM_LIST_FAIL,
  payload,
});

//  get  nft item details 
export const getNftItemDetailsAction = payload => ({
  type: actionLabels.GET_NFT_ITEM_DETAILS_SAGA,
  payload,
});

export const getNftItemDetailsStart = payload => ({
  type: actionLabels.GET_NFT_ITEM_DETAILS_START,
  payload,
});

export const getNftItemDetailsSuccess = payload => ({
  type: actionLabels.GET_NFT_ITEM_DETAILS_SUCCESS,
  payload,
});

export const getNftItemDetailsFail = payload => ({
  type: actionLabels.GET_NFT_ITEM_DETAILS_FAIL,
  payload,
});

//  hide nft details
export const hideNftDetailsAction = payload => ({
  type: actionLabels.HIDE_NFT_DETAILS_SAGA,
  payload,
});

export const hideNftDetailsStart = payload => ({
  type: actionLabels.HIDE_NFT_DETAILS_START,
  payload,
});

export const hideNftDetailsSuccess = payload => ({
  type: actionLabels.HIDE_NFT_DETAILS_SUCCESS,
  payload,
});

export const hideNftDetailsFail = payload => ({
  type: actionLabels.HIDE_NFT_DETAILS_FAIL,
  payload,
});

