import { toast } from 'react-toastify';

import errorHandler from 'utils/apiHandler';
import { put } from 'redux-saga/effects';
import * as actions from '../../actions';
import { ADMIN_LIST_URL, GET_ADMIN_URL, GET_ALL_ADMIN_URL, ADMIN_STATUS_URL } from 'apis';

// get admin dropdown list saga
export function* getAdminListSaga() {
  yield put(actions.getAdminListStart());
  yield errorHandler({
    endpoint: `${ADMIN_LIST_URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getAdminListSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getAdminListFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get admin saga
export function* getAdminSaga({ payload }) {
  const { id } = payload;
  yield put(actions.getAdminStart());
  yield errorHandler({
    endpoint: `${GET_ADMIN_URL}/${id}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getAdminSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getAdminFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get all admin saga
export function* getAllAdminSaga({ payload }) {
  const { limit, offset, URL = '' } = payload;
  yield put(actions.getAllAdminStart());
  yield errorHandler({
    endpoint: `${GET_ALL_ADMIN_URL}?limit=${limit}&offset=${offset}${URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getAllAdminSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getAllAdminFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// create admin
export function* createAdminSaga({ payload }) {
  const { data, handleAllAdmin } = payload;
  yield put(actions.createAdminStart());
  yield errorHandler({
    endpoint: GET_ADMIN_URL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.createAdminSuccess(data));
      toast.success(response?.message);
      if (handleAllAdmin) handleAllAdmin();
    },
    failHandler: yield function* (response) {
      yield put(actions.createAdminFail(response));
      toast.error(response?.message);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'post',
    payload: data,
    token: true,
  });
}

// update admin
export function* updateAdminSaga({ payload }) {
  const { id, data, handleAllAdmin } = payload;
  yield put(actions.updateAdminStart());
  yield errorHandler({
    endpoint: `${GET_ADMIN_URL}/${id}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.updateAdminSuccess(data));
      toast.success(response?.message);
      if (handleAllAdmin) handleAllAdmin();
    },
    failHandler: yield function* (response) {
      yield put(actions.updateAdminFail(response));
      toast.error(response?.message);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'put',
    payload: data,
    token: true,
  });
}

// delete admin
export function* deleteAdminSaga({ payload }) {
  yield put(actions.deleteAdminStart());
  const { id, handleAllAdmin, dispatch } = payload;
  yield errorHandler({
    endpoint: `${GET_ADMIN_URL}/${id}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.deleteAdminSuccess(data));
      toast.success(response?.message);
      if (handleAllAdmin) handleAllAdmin();
    },
    failHandler: yield function* (response) {
      yield put(actions.deleteAdminFail(response));
      toast.error(response);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'delete',
    token: true,
  });
}

// disable enable admin
export function* disableEnableAdminSaga({ payload }) {
  const { id, handleAllAdmin } = payload;
  yield put(actions.disableEnableAdminStart());
  yield errorHandler({
    endpoint: `${ADMIN_STATUS_URL}/${id}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.disableEnableAdminSuccess(data));
      toast.success(response?.message);
      if (handleAllAdmin) handleAllAdmin();
    },
    failHandler: yield function* (response) {
      yield put(actions.disableEnableAdminFail(response));
      toast.error(response?.message);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'put',
    token: true,
  });
}
