import * as actionLabels from '../../actionLabels';

// get user list
export const getTransactionListAction = payload => ({
  type: actionLabels.GET_TRANSACTION_LIST_SAGA,
  payload,
});

export const getTransactionListStart = payload => ({
  type: actionLabels.GET_TRANSACTION_LIST_START,
  payload,
});

export const getTransactionListSuccess = payload => ({
  type: actionLabels.GET_TRANSACTION_LIST_SUCCESS,
  payload,
});

export const getTransactionListFail = payload => ({
  type: actionLabels.GET_TRANSACTION_LIST_FAIL,
  payload,
});

// get nft transactions list

export const getNftTransactionsListAction = payload => ({
  type: actionLabels.GET_NFT_TRANSACTIONS_LIST_SAGA,
  payload,
});

export const getNftTransactionsListStart = payload => ({
  type: actionLabels.GET_NFT_TRANSACTIONS_LIST_START,
  payload,
});

export const getNftTransactionsListSuccess = payload => ({
  type: actionLabels.GET_NFT_TRANSACTIONS_LIST_SUCCESS,
  payload,
});

export const getNftTransactionsListFail = payload => ({
  type: actionLabels.GET_NFT_TRANSACTIONS_LIST_FAIL,
  payload,
});

// get fund transactions list

export const getFundTransactionsListAction = payload => ({
  type: actionLabels.GET_FUND_TRANSACTIONS_LIST_SAGA,
  payload,
});

export const getFundTransactionsListStart = payload => ({
  type: actionLabels.GET_FUND_TRANSACTIONS_LIST_START,
  payload,
});

export const getFundTransactionsListSuccess = payload => ({
  type: actionLabels.GET_FUND_TRANSACTIONS_LIST_SUCCESS,
  payload,
});

export const getFundTransactionsListFail = payload => ({
  type: actionLabels.GET_FUND_TRANSACTIONS_LIST_FAIL,
  payload,
});
