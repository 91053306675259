import { put } from 'redux-saga/effects';

import errorHandler from 'utils/apiHandler';
import { TRANSACTION_LIST_URL, TRANSACTION_FUNDS_URL, TRANSACTION_NFT_URL } from 'apis';
import {
  getTransactionListStart,
  getTransactionListSuccess,
  getTransactionListFail,
  getNftTransactionsListStart,
  getNftTransactionsListSuccess,
  getNftTransactionsListFail,
  getFundTransactionsListStart,
  getFundTransactionsListSuccess,
  getFundTransactionsListFail,
} from 'store/actions';

// get transaction list saga
export function* getTransactionListSaga({ payload }) {
  const { limit, offset, URL } = payload;
  yield put(getTransactionListStart());
  yield errorHandler({
    endpoint: `${TRANSACTION_LIST_URL}?limit=${limit}&offset=${offset}${URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getTransactionListSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getTransactionListFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get nft transactions list saga
export function* getNftTransactionsListSaga({ payload }) {
  const { limit, offset, URL, userId } = payload;
  yield put(getNftTransactionsListStart());
  let requestURL = `${TRANSACTION_NFT_URL}?limit=${limit}&offset=${offset}${URL}`;
  // Conditionally add userId to the requestURL if it exists
  if (userId) {
    requestURL += `&_user=${userId}`;
  }
  yield errorHandler({
    endpoint: requestURL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getNftTransactionsListSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getNftTransactionsListFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get fund transactions list saga
export function* getFundTransactionsListSaga({ payload }) {
  const { limit, offset, URL, userId } = payload;
  yield put(getFundTransactionsListStart());
  let requestURL = `${TRANSACTION_FUNDS_URL}?limit=${limit}&offset=${offset}${URL}`;
  // Conditionally add userId to the requestURL if it exists
  if (userId) {
    requestURL += `&_user=${userId}`;
  }
  yield errorHandler({
    endpoint: requestURL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getFundTransactionsListSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getFundTransactionsListFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}
