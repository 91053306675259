export const GET_USER_REPORT_LIST_SAGA = 'GET_USER_REPORT_LIST_SAGA';
export const GET_USER_REPORT_LIST_START = 'GET_USER_REPORT_LIST_START';
export const GET_USER_REPORT_LIST_SUCCESS = 'GET_USER_REPORT_LIST_SUCCESS';
export const GET_USER_REPORT_LIST_FAIL = 'GET_USER_REPORT_LIST_FAIL';

export const GET_USER_REPORT_DETAILS_SAGA = 'GET_USER_REPORT_DETAILS_SAGA';
export const GET_USER_REPORT_DETAILS_START = 'GET_USER_REPORT_DETAILS_START';
export const GET_USER_REPORT_DETAILS_SUCCESS = 'GET_USER_REPORT_DETAILS_SUCCESS';
export const GET_USER_REPORT_DETAILS_FAIL = 'GET_USER_REPORT_DETAILS_FAIL';

export const GET_USER_REPORT_COLLECTON_LIST_SAGA = 'GET_USER_REPORT_COLLECTON_LIST_SAGA';
export const GET_USER_REPORT_COLLECTION_LIST_START = 'GET_USER_REPORT_COLLECTION_LIST_START';
export const GET_USER_REPORT_COLLECTION_LIST_SUCCESS = 'GET_USER_REPORT_COLLECTION_LIST_SUCCESS';
export const GET_USER_REPORT_COLLECTION_LIST_FAIL = 'GET_USER_REPORT_COLLECTION_LIST_FAIL';

export const GET_COLLECTION_DETAILS_SAGA = 'GET_COLLECTION_DETAILS_SAGA';
export const GET_COLLECTION_DETAILS_START = 'GET_COLLECTION_DETAILS_START';
export const GET_COLLECTION_DETAILS_SUCCESS = 'GET_COLLECTION_DETAILS_SUCCESS';
export const GET_COLLECTION_DETAILS_FAIL = 'GET_COLLECTION_DETAILS_FAIL';

export const HIDE_COLLECTION_SAGA = 'HIDE_COLLECTION_SAGA';
export const HIDE_COLLECTION_START = 'HIDE_COLLECTION_START';
export const HIDE_COLLECTION_SUCCESS = 'HIDE_COLLECTION_SUCCESS';
export const HIDE_COLLECTION_FAIL = 'HIDE_COLLECTION_FAIL';

export const GET_COLLECTION_ITEM_LIST_SAGA = 'GET_COLLECTION_ITEM_LIST_SAGA';
export const GET_COLLECTION_ITEM_LIST_START = 'GET_COLLECTION_ITEM_LIST_START';
export const GET_COLLECTION_ITEM_LIST_SUCCESS = 'GET_COLLECTION_ITEM_LIST_SUCCESS';
export const GET_COLLECTION_ITEM_LIST_FAIL = 'GET_COLLECTION_ITEM_LIST_FAIL';

export const GET_USER_REPORT_COLLECTION_ITEM_LIST_SAGA = 'GET_USER_REPORT_COLLECTION_ITEM_LIST_SAGA';
export const GET_USER_REPORT_COLLECTION_ITEM_LIST_START = 'GET_USER_REPORT_COLLECTION_ITEM_LIST_START';
export const GET_USER_REPORT_COLLECTION_ITEM_LIST_SUCCESS = 'GET_USER_REPORT_COLLECTION_ITEM_LIST_SUCCESS';
export const GET_USER_REPORT_COLLECTION_ITEM_LIST_FAIL = 'GET_USER_REPORT_COLLECTION_ITEM_LIST_FAIL';

export const GET_NFT_ITEM_DETAILS_SAGA = 'GET_NFT_ITEM_DETAILS_SAGA';
export const GET_NFT_ITEM_DETAILS_START = 'GET_NFT_ITEM_DETAILS_START';
export const GET_NFT_ITEM_DETAILS_SUCCESS = 'GET_NFT_ITEM_DETAILS_SUCCESS';
export const GET_NFT_ITEM_DETAILS_FAIL = 'GET_NFT_ITEM_DETAILS_FAIL';

export const HIDE_NFT_DETAILS_SAGA = 'HIDE_NFT_DETAILS_SAGA';
export const HIDE_NFT_DETAILS_START = 'HIDE_NFT_DETAILS_START';
export const HIDE_NFT_DETAILS_SUCCESS = 'HIDE_NFT_DETAILS_SUCCESS';
export const HIDE_NFT_DETAILS_FAIL = 'HIDE_NFT_DETAILS_FAIL';
