import * as actionLabels from '../../actionLabels';

export const loginAction = payload => ({
  type: actionLabels.LOGIN_SAGA,
  payload,
});

export const loginStart = () => ({
  type: actionLabels.LOGIN_START,
});

export const loginSuccess = payload => ({
  type: actionLabels.LOGIN_SUCCESS,
  payload,
});

export const loginFail = payload => ({
  type: actionLabels.LOGIN_FAIL,
  payload,
});

export const clearAuth = () => ({
  type: actionLabels.CLEAR_AUTH,
});

export const forgetPasswordAction = payload => ({
  type: actionLabels.FORGET_PASSWORD_SAGA,
  payload,
});

export const forgetPasswordStart = () => ({
  type: actionLabels.FORGET_PASSWORD_START,
});

export const forgetPasswordSuccess = payload => ({
  type: actionLabels.FORGET_PASSWORD_SUCCESS,
  payload,
});

export const forgetPasswordFail = payload => ({
  type: actionLabels.FORGET_PASSWORD_FAIL,
  payload,
});

export const verifyOtpAction = payload => ({
  type: actionLabels.VERIFY_OTP_SAGA,
  payload,
});

export const verifyOtpStart = () => ({
  type: actionLabels.VERIFY_OTP_START,
});

export const verifyOtpSuccess = payload => ({
  type: actionLabels.VERIFY_OTP_SUCCESS,
  payload,
});

export const verifyOtpFail = payload => ({
  type: actionLabels.VERIFY_OTP_FAIL,
  payload
})
export const verifyForgetPasswordOtpAction = payload => ({
  type: actionLabels.VERIFY_FORGET_PASSWORD_OTP_SAGA,
  payload,
});
export const verifyForgetPasswordOtpStart = payload => ({
  type: actionLabels.VERIFY_FORGET_PASSWORD_OTP_START,
  payload,
});
export const verifyForgetPasswordOtpSuccess = payload => ({
  type: actionLabels.VERIFY_FORGET_PASSWORD_OTP_SUCCESS,
  payload,
});
export const verifyForgetPasswordOtpFail = payload => ({
  type: actionLabels.VERIFY_FORGET_PASSWORD_OTP_FAIL,
  payload,
});

export const resetPasswordAction = payload => ({
  type: actionLabels.RESET_PASSWORD_SAGA,
  payload,
});
export const resetPasswordStart = payload => ({
  type: actionLabels.RESET_PASSWORD_START,
  payload,
});
export const resetPasswordSuccess = payload => ({
  type: actionLabels.RESET_PASSWORD_SUCCESS,
  payload,
});
export const resetPasswordFail = payload => ({
  type: actionLabels.RESET_PASSWORD_FAIL,
  payload,
});

export const resendOtpAction = payload => ({
  type: actionLabels.RESEND_OTP_SAGA,
  payload,
});
export const resendOtpStart = payload => ({
  type: actionLabels.RESEND_OTP_START,
  payload,
});
export const resendOtpSuccess = payload => ({
  type: actionLabels.RESEND_OTP_SUCCESS,
  payload,
});
export const resendOtpFail = payload => ({
  type: actionLabels.RESEND_OTP_FAIL,
  payload,
});
