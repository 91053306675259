/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

const initialState = {
  // get reward list
  getRewardListLoading: false,
  getRewardListErrorMsg: '',
  rewardList: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // get reward list
    case actionLabels.GET_REWARD_LIST_START:
      return { ...state, getRewardListLoading: true, getRewardListErrorMsg: '' };
    case actionLabels.GET_REWARD_LIST_SUCCESS:
      return {
        ...state,
        getRewardListLoading: false,
        getRewardListErrorMsg: '',
        rewardList: payload,
      };
    case actionLabels.GET_REWARD_LIST_FAIL:
      return {
        ...state,
        getRewardListLoading: false,
        getRewardListErrorMsg: payload,
        rewardList: [],
      };
    default:
      return state;
  }
};
