const SuperAdmin = () => {
  return (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2940_23972)">
<path d="M13.1,6.8c0.2-1.3-0.2-2.4-1.2-3.4C11.1,2.6,10.1,2.2,9,2.1h0C7.8,2.1,6.8,2.6,6,3.3c-0.8,0.8-1.3,1.9-1.2,3
		c0,1.4,0.7,2.6,2,3.5L6.9,10H5.3c0,0,0,0,0,0C4.5,10,3.7,10.3,3,11c-0.6,0.6-0.9,1.4-1,2.3V17c0,0.6,0.4,0.9,0.9,0.9l7.3,0h0.3
		c0.2,0,0.4,0,0.7,0c0.2,0,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.3-0.2H3.1c-0.4,0-0.5-0.1-0.5-0.5v-3.7c0-1.5,1.2-2.7,2.7-2.7
		c1.6,0,3.3,0,5,0h2.2c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2h-1.7l0.2-0.1C12.3,9.1,12.9,8.1,13.1,6.8z
		 M8.9,10L8.9,10c-1,0-1.9-0.4-2.6-1.1C5.6,8.2,5.2,7.3,5.2,6.3c0-1,0.4-1.9,1.1-2.6C7,3,7.9,2.6,8.9,2.6c1,0,1.9,0.4,2.6,1.1
		c0.7,0.7,1.1,1.6,1.1,2.6c0,1-0.4,1.9-1.1,2.6C10.8,9.6,9.8,10,8.9,10z" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.2,13.5c0-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0,0h-0.2c-0.3,0-0.4-0.1-0.4-0.3c-0.1-0.2,0-0.4,0.1-0.5c0,0,0.1-0.1,0.1-0.1
		c0.2-0.2,0.2-0.3,0.1-0.4c-0.3-0.4-0.7-0.7-1-1c-0.1-0.1-0.2-0.1-0.4,0l0,0c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.2-0.5,0.1
		c-0.1,0-0.3-0.1-0.3-0.4c0-0.1,0-0.1,0-0.2v-0.1c0-0.2-0.1-0.3-0.2-0.3c-0.5,0-1,0-1.5,0c-0.1,0-0.2,0-0.2,0.3V11
		c0,0.3-0.1,0.4-0.3,0.4c-0.2,0.1-0.4,0-0.5-0.1c0,0-0.1-0.1-0.1-0.1l0,0c-0.1-0.2-0.2-0.1-0.4,0l0,0c-0.3,0.2-0.5,0.5-0.8,0.7
		c-0.3,0.4-0.3,0.4,0,0.8c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1-0.1,0.3-0.4,0.3h-0.2c-0.2,0-0.3,0.1-0.3,0.2c0,0.2,0,0.3-0.1,0.5l0,0.2
		l0,0.2c0,0.2,0,0.3,0.1,0.5c0,0.2,0.1,0.3,0.3,0.3h0.2c0.2,0,0.3,0.1,0.4,0.3c0.1,0.2,0,0.4-0.1,0.5c0,0-0.1,0.1-0.1,0.1l0,0
		c-0.2,0.2-0.1,0.3,0,0.4c0.3,0.4,0.7,0.7,1,1c0.1,0.1,0.2,0.1,0.4,0l0,0c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.1
		c0.1,0,0.1,0,0.2,0c0.2,0.1,0.3,0.2,0.3,0.4v0.3c0,0.1,0.1,0.2,0.2,0.2c0.5,0.1,1,0.1,1.6,0c0.1,0,0.2-0.1,0.2-0.2v-0.1
		c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.3,0.3-0.4c0.2-0.1,0.4,0,0.5,0.1c0,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.3,0.1,0.3,0
		c0.4-0.3,0.7-0.7,1.1-1c0.1-0.1,0.1-0.2,0-0.3c0,0-0.1-0.1-0.1-0.1l0,0c-0.1-0.1-0.3-0.2-0.1-0.5c0.1-0.2,0.3-0.3,0.4-0.3H18
		c0.1,0,0.2-0.1,0.2-0.2C18.3,14.5,18.3,14,18.2,13.5z M17.8,14.8h-0.5c-0.2,0-0.3,0-0.3,0.2c-0.1,0.2-0.1,0.4-0.2,0.5
		c-0.1,0.2-0.1,0.3,0.1,0.4c0,0,0.1,0.1,0.2,0.1l0.2,0.2l-0.8,0.8l-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.1-0.4-0.1
		c-0.2,0.1-0.3,0.2-0.6,0.2c-0.2,0.1-0.2,0.1-0.2,0.3v0.5H14l0-0.1c0,0,0-0.1,0-0.1c0.1-0.4-0.1-0.5-0.5-0.7
		c-0.4-0.2-0.6-0.2-0.9,0.2l-0.1,0.2l-0.1-0.1c-0.2-0.2-0.3-0.4-0.5-0.6l-0.1-0.1l0.2-0.2c0.1-0.1,0.1-0.1,0.2-0.2
		c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.2-0.2-0.4-0.2-0.6c-0.1-0.2-0.1-0.3-0.3-0.3H11v-1h0.3c0.1,0,0.2,0,0.2,0c0.2,0,0.3-0.1,0.3-0.2
		c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.3-0.1-0.4c0,0-0.1-0.1-0.2-0.1l-0.2-0.2l0.9-0.7l0.1,0.2c0,0.1,0.1,0.1,0.2,0.2
		c0.1,0.1,0.1,0.1,0.3,0c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.2-0.1,0.2-0.3v-0.5h1v0.5c0,0.1,0,0.2,0.2,0.3c0.2,0.1,0.4,0.1,0.6,0.2
		c0.2,0.1,0.3,0.1,0.5-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1l0.1-0.1l0.8,0.8L17,12.4c-0.1,0.1-0.1,0.1-0.2,0.2
		c-0.1,0.1-0.1,0.2,0,0.3c0.1,0.2,0.2,0.4,0.2,0.5c0.1,0.3,0.1,0.3,0.4,0.3h0.4V14.8z" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.9,12.8c-0.4-0.4-0.9-0.6-1.4-0.6c-1.1,0-2,0.9-2,2c0,0.5,0.2,1,0.6,1.4c0.4,0.4,0.9,0.6,1.4,0.6c1.1,0,2-0.9,2-2
		C16.5,13.7,16.3,13.2,15.9,12.8z M15.5,15.4c-0.3,0.3-0.7,0.5-1.1,0.5h0c-0.9,0-1.6-0.7-1.6-1.6c0-0.4,0.2-0.8,0.5-1.1
		c0.3-0.3,0.7-0.5,1.1-0.5h0c0.9,0,1.6,0.7,1.6,1.6C16,14.7,15.8,15.1,15.5,15.4z" stroke-width="0.6" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>
  )
}

export default SuperAdmin;
