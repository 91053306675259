import React from 'react';

export const guestRoutes = [
  {
    path: '/signin',
    name: 'Signin',
    exact: true,
    component: React.lazy(() => import('../../views/auth/Login/Login')),
  },
  {
    path: '/otp-verification',
    name: 'OtpVerification',
    exact: true,
    component: React.lazy(() => import('../../views/auth/Otp/OtpScreen')),
  },
  // '/forgot-password'
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    exact: true,
    component: React.lazy(() => import('../../views/auth/Password/ForgotPassword')),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    exact: true,
    component: React.lazy(() => import('../../views/auth/Password/ResetPassword')),
  },
  {
    path: '/password-updated',
    name: 'PasswordUpdated',
    exact: true,
    component: React.lazy(() => import('../../views/auth/Password/PasswordUpdated')),
  },
  {
    path: '/terms-and-services',
    name: 'Terms',
    exact: true,
    component: React.lazy(() => import('../../views/auth/termsPrivacy/termsServices')),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    exact: true,
    component: React.lazy(() => import('../../views/auth/termsPrivacy/privacyPolicy')),
  },
  {
    redirectRoute: true,
    name: 'Signin',
    path: '/signin',
  },
];

export const userRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    exact: true,
    component: React.lazy(() => import('../../views/user/Dashboard/Dashboard')),
  },
  {
    path: '/super-admin-control/admin-management',
    name: 'SuperAdminControlAdmin',
    exact: true,
    component: React.lazy(() => import('../../views/user/SuperAdminControl/SuperAdminControl')),
  },
  {
    path: '/super-admin-control/role-management',
    name: 'SuperAdminControlRole',
    exact: true,
    component: React.lazy(() => import('../../views/user/SuperAdminControl/SuperAdminControl')),
  },
  {
    path: '/category-management',
    name: 'CategoryManagement',
    exact: true,
    component: React.lazy(() => import('../../views/user/CategoryManagement/CategoryManagement')),
  },
  {
    path: '/user-report-management',
    name: 'UserReportManagement',
    exact: true,
    component: React.lazy(() =>
      import('../../views/user/UserReportManagement/UserReportManagement'),
    ),
  },
  {
    path: '/user-management',
    name: 'UserManagement',
    exact: true,
    component: React.lazy(() => import('../../views/user/userManagement/UserManagement')),
  },
  // {
  //   path: '/transaction-management',
  //   name: 'TransactionManagement',
  //   exact: true,
  //   component: React.lazy(() =>
  //     import('../../views/user/TransactionManagement/TransactionManagement'),
  //   ),
  // },
  {
    path: '/transaction-management/nft-transactions',
    name: 'TransactionManagementNft',
    exact: true,
    component: React.lazy(() =>
      import('../../views/user/TransactionManagement/TransactionManagement'),
    ),
  },
  {
    path: '/transaction-management/fund-transactions',
    name: 'TransactionManagementFund',
    exact: true,
    component: React.lazy(() =>
      import('../../views/user/TransactionManagement/TransactionManagement'),
    ),
  },
  {
    path: '/content-management-system/terms-of-service',
    name: 'CMSTerms',
    exact: true,
    component: React.lazy(() => import('../../views/user/CMS/ContentManagementSystem')),
  },
  {
    path: '/content-management-system/privacy-policy',
    name: 'CMSPrivacyPolicy',
    exact: true,
    component: React.lazy(() => import('../../views/user/CMS/ContentManagementSystem')),
  },
  {
    path: '/content-management-system/about-us',
    name: 'CMSAboutUs',
    exact: true,
    component: React.lazy(() => import('../../views/user/CMS/ContentManagementSystem')),
  },
  {
    path: '/content-management-system/ventures',
    name: 'CMSVentures',
    exact: true,
    component: React.lazy(() => import('../../views/user/CMS/ContentManagementSystem')),
  },
  {
    path: '/content-management-system/grants',
    name: 'CMSGrants',
    exact: true,
    component: React.lazy(() => import('../../views/user/CMS/ContentManagementSystem')),
  },
  {
    path: '/content-management-system/gdpr',
    name: 'CMSGDPR',
    exact: true,
    component: React.lazy(() => import('../../views/user/CMS/ContentManagementSystem')),
  },
  {
    path: '/subscription-management',
    name: 'SubscriptionManagement',
    exact: true,
    component: React.lazy(() =>
      import('../../views/user/SubscriptionManagement/SubscriptionManagement'),
    ),
  },
  {
    path: '/profile',
    name: 'Profile',
    exact: true,
    component: React.lazy(() => import('../../views/user/profile/Profile')),
  },
  {
    path: '/collection/:collectionId',
    name: 'Collection',
    exact: true,
    component: React.lazy(() =>
      import('../../views/user/UserReportManagement/Collection/Collection'),
    ),
  },
  {
    path: '/user-collection/:userId',
    name: 'UserCollection',
    exact: true,
    component: React.lazy(() =>
      import('../../views/user/UserReportManagement/Collection/UserCollection'),
    ),
  },
  {
    path: '/nft-details/:nftId',
    name: 'NftDetails',
    exact: true,
    component: React.lazy(() =>
      import('../../views/user/UserReportManagement/Collection/NftDetails'),
    ),
  },
  {
    path: '/notifications',
    name: 'Notifications',
    exact: true,
    component: React.lazy(() => import('../../views/user/Notifications/Notifications')),
  },
  {
    path: '/user-transaction-history/nft-transactions',
    name: 'UserTransactionHistoryNft',
    exact: true,
    component: React.lazy(() =>
      import('../../views/user/UserReportManagement/userTransactionHistory'),
    ),
  },
  {
    path: '/user-transaction-history/fund-transactions',
    name: 'UserTransactionHistoryFund',
    exact: true,
    component: React.lazy(() =>
      import('../../views/user/UserReportManagement/userTransactionHistory'),
    ),
  },
  {
    path: '/drop-management/drop-requests',
    name: 'DropManagementDropRequests',
    exact: true,
    component: React.lazy(() => import('../../views/user/DropManagement/DropManagement')),
  },
  {
    path: '/drop-management/live-drops',
    name: 'DropManagementLiveDrops',
    exact: true,
    component: React.lazy(() => import('../../views/user/DropManagement/DropManagement')),
  },
  {
    path: '/notifications-management/push-notifications',
    name: 'notificationsManagementPushNotifications',
    exact: true,
    component: React.lazy(() =>
      import('../../views/user/NotificationsManagement/NotificationsManagement'),
    ),
  },
  {
    path: '/notifications-management/kyc-reminders',
    name: 'notificationsManagementKycReminders',
    exact: true,
    component: React.lazy(() =>
      import('../../views/user/NotificationsManagement/NotificationsManagement'),
    ),
  },
  {
    path: '/notifications-management/emails',
    name: 'notificationsManagementEmails',
    exact: true,
    component: React.lazy(() =>
      import('../../views/user/NotificationsManagement/NotificationsManagement'),
    ),
  },
  {
    redirectRoute: true,
    name: 'Dashboard',
    path: '/dashboard',
  },
];
