// create admin
export const CREATE_ADMIN_SAGA = 'CREATE_ADMIN_SAGA';
export const CREATE_ADMIN_START = 'CREATE_ADMIN_START';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAIL = 'CREATE_ADMIN_FAIL';

// update admin
export const UPDATE_ADMIN_SAGA = 'UPDATE_ADMIN_SAGA';
export const UPDATE_ADMIN_START = 'UPDATE_ADMIN_START';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_FAIL = 'UPDATE_ADMIN_FAIL';

// delete admin
export const DELETE_ADMIN_SAGA = 'DELETE_ADMIN_SAGA';
export const DELETE_ADMIN_START = 'DELETE_ADMIN_START';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_FAIL = 'DELETE_ADMIN_FAIL';

// get admin dropdown list
export const GET_ADMIN_LIST_SAGA = 'GET_ADMIN_LIST_SAGA';
export const GET_ADMIN_LIST_START = 'GET_ADMIN_LIST_START';
export const GET_ADMIN_LIST_SUCCESS = 'GET_ADMIN_LIST_SUCCESS';
export const GET_ADMIN_LIST_FAIL = 'GET_ADMIN_LIST_FAIL';

// get all admin
export const GET_ALL_ADMIN_SAGA = 'GET_ALL_ADMIN_SAGA';
export const GET_ALL_ADMIN_START = 'GET_ALL_ADMIN_START';
export const GET_ALL_ADMIN_SUCCESS = 'GET_ALL_ADMIN_SUCCESS';
export const GET_ALL_ADMIN_FAIL = 'GET_ALL_ADMIN_FAIL';

// get admin
export const GET_ADMIN_SAGA = 'GET_ADMIN_SAGA';
export const GET_ADMIN_START = 'GET_ADMIN_START';
export const GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS';
export const GET_ADMIN_FAIL = 'GET_ADMIN_FAIL';

//disable enable admin
export const DISABLE_ENABLE_ADMIN_SAGA = 'DISABLE_ENABLE_ADMIN_SAGA';
export const DISABLE_ENABLE_ADMIN_START = 'DISABLE_ENABLE_ADMIN_START';
export const DISABLE_ENABLE_ADMIN_SUCCESS = 'DISABLE_ENABLE_ADMIN_SUCCESS';
export const DISABLE_ENABLE_ADMIN_FAIL = 'DISABLE_ENABLE_ADMIN_FAIL';
