import { put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import errorHandler from 'utils/apiHandler';

import {
  LOGIN_URL,
  FORGOT_PASSWORD_URL,
  VERIFY_FORGOT_PASSWORD_OTP,
  VERIFY_OTP_URL,
  RESET_PASSWORD_URL,
  RESEND_OTP_URL,
} from 'apis';
import {
  loginSuccess,
  loginFail,
  loginStart,
  forgetPasswordStart,
  forgetPasswordSuccess,
  forgetPasswordFail,
  verifyForgetPasswordOtpStart,
  verifyForgetPasswordOtpSuccess,
  verifyForgetPasswordOtpFail,
  verifyOtpStart,
  verifyOtpSuccess,
  verifyOtpFail,
  resetPasswordStart,
  resetPasswordSuccess,
  resendOtpStart,
  resendOtpSuccess,
  resendOtpFail,
  resetPasswordFail,
} from '../../actions';

export function* loginSaga({ payload }) {
  yield put(loginStart());
  const { data, history } = payload;
  yield errorHandler({
    endpoint: LOGIN_URL,
    successHandler: yield function* (response) {
      yield put(loginSuccess(response));
      response?.data?.isFirstTimeLogin
        ? history.push({
            pathname: '/reset-password',
            state: { resetToken: response?.data?.resetToken },
          })
        : history.push({
            pathname: '/otp-verification',
            state: { email: response?.data?.email, type: 'LOGIN' },
          });
    },
    failHandler: yield function* (response) {
      yield put(loginFail(response));
      toast.error(response);
    },
    payload: data,
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'post',
  });
}

export function* verifyOtpSaga({ payload }) {
  yield put(verifyOtpStart());
  const { data, history } = payload;
  yield errorHandler({
    endpoint: VERIFY_OTP_URL,
    successHandler: yield function* (response) {
      yield put(verifyOtpSuccess(response));
      sessionStorage.setItem('authToken', response?.data?.authToken);
      sessionStorage.setItem('email', response?.data?.email);
      history.push('/dashboard');
      window.location.reload();
    },
    failHandler: yield function* (response) {
      yield put(verifyOtpFail(response));
      toast.error(response);
    },
    payload: data,
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'post',
  });
}

// forget password saga
export function* forgetPasswordSaga(action) {
  yield put(forgetPasswordStart());
  const { data, history } = action?.payload;
  yield errorHandler({
    endpoint: FORGOT_PASSWORD_URL,
    successHandler: yield function* (response) {
      yield put(forgetPasswordSuccess(response));
      history.push({
        pathname: '/otp-verification',
        state: { email: response?.data?.email, type: 'FORGET_PASSWORD' },
      });
      toast.success(response?.message);
    },
    failHandler: yield function* (response) {
      yield put(forgetPasswordFail(response));
      toast.error(response);
    },
    failHandlerType: 'CUSTOM',
    payload: data,
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'post',
  });
}

// verify forget password otp saga
export function* verifyForgetPasswordOtpSaga(action) {
  yield put(verifyForgetPasswordOtpStart());
  const { data, history } = action?.payload;
  yield errorHandler({
    endpoint: VERIFY_FORGOT_PASSWORD_OTP,
    successHandler: yield function* (response) {
      yield put(verifyForgetPasswordOtpSuccess(response));
      history.push({
        pathname: '/reset-password',
        state: { resetToken: response?.data?.resetToken },
      });
      toast.success(response?.message);
    },
    failHandler: yield function* (response) {
      yield put(verifyForgetPasswordOtpFail(response));
      toast.error(response);
    },
    failHandlerType: 'CUSTOM',
    payload: data,
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'post',
  });
}

// reset password
export function* resetPasswordSaga(action) {
  yield put(resetPasswordStart());
  const { data, token, history } = action?.payload;
  yield errorHandler({
    endpoint: `${RESET_PASSWORD_URL}/${token}`,
    successHandler: yield function* (response) {
      yield put(resetPasswordSuccess(response));
      history.push({ pathname: '/password-updated' });
      toast.success(response?.message);
    },
    failHandler: yield function* (response) {
      yield put(resetPasswordFail(response));
      toast.error(response);
    },
    failHandlerType: 'CUSTOM',
    payload: data,
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'put',
  });
}

// resend otp
export function* resendOtpSaga(action) {
  yield put(resendOtpStart());
  const { data } = action?.payload;
  yield errorHandler({
    endpoint: RESEND_OTP_URL,
    successHandler: yield function* (response) {
      yield put(resendOtpSuccess(response));
      toast.success(response?.message);
    },
    failHandler: yield function* (response) {
      yield put(resendOtpFail(response));
      toast.error(response);
    },
    failHandlerType: 'CUSTOM',
    payload: data,
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'post',
  });
}
