import * as actionLabels from '../../actionLabels';

// create role
export const createRoleAction = payload => ({
  type: actionLabels.CREATE_ROLE_SAGA,
  payload,
});

export const createRoleStart = payload => ({
  type: actionLabels.CREATE_ROLE_START,
  payload,
});

export const createRoleSuccess = payload => ({
  type: actionLabels.CREATE_ROLE_SUCCESS,
  payload,
});

export const createRoleFail = payload => ({
  type: actionLabels.CREATE_ROLE_FAIL,
  payload,
});

// update role
export const updateRoleAction = payload => ({
  type: actionLabels.UPDATE_ROLE_SAGA,
  payload,
});

export const updateRoleStart = payload => ({
  type: actionLabels.UPDATE_ROLE_START,
  payload,
});

export const updateRoleSuccess = payload => ({
  type: actionLabels.UPDATE_ROLE_SUCCESS,
  payload,
});

export const updateRoleFail = payload => ({
  type: actionLabels.UPDATE_ROLE_FAIL,
  payload,
});

// delete role
export const deleteRoleAction = payload => ({
  type: actionLabels.DELETE_ROLE_SAGA,
  payload,
});

export const deleteRoleStart = payload => ({
  type: actionLabels.DELETE_ROLE_START,
  payload,
});

export const deleteRoleSuccess = payload => ({
  type: actionLabels.DELETE_ROLE_SUCCESS,
  payload,
});

export const deleteRoleFail = payload => ({
  type: actionLabels.DELETE_ROLE_FAIL,
  payload,
});

// get role list
export const getRoleListAction = payload => ({
  type: actionLabels.GET_ROLE_LIST_SAGA,
  payload,
});

export const getRoleListStart = payload => ({
  type: actionLabels.GET_ROLE_LIST_START,
  payload,
});

export const getRoleListSuccess = payload => ({
  type: actionLabels.GET_ROLE_LIST_SUCCESS,
  payload,
});

export const getRoleListFail = payload => ({
  type: actionLabels.GET_ROLE_LIST_FAIL,
  payload,
});

// get all role
export const getAllRoleAction = payload => ({
  type: actionLabels.GET_ALL_ROLE_SAGA,
  payload,
});

export const getAllRoleStart = payload => ({
  type: actionLabels.GET_ALL_ROLE_START,
  payload,
});

export const getAllRoleSuccess = payload => ({
  type: actionLabels.GET_ALL_ROLE_SUCCESS,
  payload,
});

export const getAllRoleFail = payload => ({
  type: actionLabels.GET_ALL_ROLE_FAIL,
  payload,
});

// get role
export const getRoleAction = payload => ({
  type: actionLabels.GET_ROLE_SAGA,
  payload,
});

export const getRoleStart = payload => ({
  type: actionLabels.GET_ROLE_START,
  payload,
});

export const getRoleSuccess = payload => ({
  type: actionLabels.GET_ROLE_SUCCESS,
  payload,
});

export const getRoleFail = payload => ({
  type: actionLabels.GET_ROLE_FAIL,
  payload,
});

// get permissions
export const getPermissionsAction = payload => ({
  type: actionLabels.GET_PERMISSIONS_SAGA,
  payload,
});

export const getPermissionsStart = payload => ({
  type: actionLabels.GET_PERMISSIONS_START,
  payload,
});

export const getPermissionsSuccess = payload => ({
  type: actionLabels.GET_PERMISSIONS_SUCCESS,
  payload,
});

export const getPermissionsFail = payload => ({
  type: actionLabels.GET_PERMISSIONS_FAIL,
  payload,
});
