import * as actionLabels from '../../actionLabels';

// get category list
export const getCategoryListAction = payload => ({
  type: actionLabels.GET_CATEGORY_LIST_SAGA,
  payload,
});

export const getCategoryListStart = payload => ({
  type: actionLabels.GET_CATEGORY_LIST_START,
  payload,
});

export const getCategoryListSuccess = payload => ({
  type: actionLabels.GET_CATEGORY_LIST_SUCCESS,
  payload,
});

export const getCategoryListFail = payload => ({
  type: actionLabels.GET_CATEGORY_LIST_FAIL,
  payload,
});

// get category details
export const getCategoryDetailsAction = payload => ({
  type: actionLabels.GET_CATEGORY_DETAILS_SAGA,
  payload,
});

export const getCategoryDetailsStart = payload => ({
  type: actionLabels.GET_CATEGORY_DETAILS_START,
  payload,
});

export const getCategoryDetailsSuccess = payload => ({
  type: actionLabels.GET_CATEGORY_DETAILS_SUCCESS,
  payload,
});

export const getCategoryDetailsFail = payload => ({
  type: actionLabels.GET_CATEGORY_DETAILS_FAIL,
  payload,
});

// create category
export const createCategoryAction = payload => ({
  type: actionLabels.CREATE_CATEGORY_SAGA,
  payload,
});

export const createCategoryStart = payload => ({
  type: actionLabels.CREATE_CATEGORY_START,
  payload,
});

export const createCategorySuccess = payload => ({
  type: actionLabels.CREATE_CATEGORY_SUCCESS,
  payload,
});

export const createCategoryFail = payload => ({
  type: actionLabels.CREATE_CATEGORY_FAIL,
  payload,
});

// update category
export const updateCategoryAction = payload => ({
  type: actionLabels.UPDATE_CATEGORY_SAGA,
  payload,
});

export const updateCategoryStart = payload => ({
  type: actionLabels.UPDATE_CATEGORY_START,
  payload,
});

export const updateCategorySuccess = payload => ({
  type: actionLabels.UPDATE_CATEGORY_SUCCESS,
  payload,
});

export const updateCategoryFail = payload => ({
  type: actionLabels.UPDATE_CATEGORY_FAIL,
  payload,
});

// delete category
export const deleteCategoryAction = payload => ({
  type: actionLabels.DELETE_CATEGORY_SAGA,
  payload,
});

export const deleteCategoryStart = payload => ({
  type: actionLabels.DELETE_CATEGORY_START,
  payload,
});

export const deleteCategorySuccess = payload => ({
  type: actionLabels.DELETE_CATEGORY_SUCCESS,
  payload,
});

export const deleteCategoryFail = payload => ({
  type: actionLabels.DELETE_CATEGORY_FAIL,
  payload,
});
