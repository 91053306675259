import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ModalState from 'context/ModalContext/ModalState';
import { UserAccountState } from 'context/UserAccountContext/UserAccountState';

import store from 'store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/styles/index.css';
import { AuthContextProvider } from 'utils/AuthContext';

ReactDOM.render(
  <Provider store={store}>
    <ModalState>
      <UserAccountState>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </UserAccountState>
    </ModalState>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
