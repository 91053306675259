import * as actionLabels from '../../actionLabels';

export const showModal = payload => {
  return {
    type: actionLabels.SHOW_ALERT_MODAL,
    payload,
  };
};
export const hideModal = payload => {
  return {
    type: actionLabels.HIDE_MODAL,
    payload,
  };
};
export const resetModal = payload => {
  return {
    type: actionLabels.RESET_MODAL,
    payload,
  };
};
export const loaderShowModal = payload => {
  return {
    type: actionLabels.LOADER_SHOW,
    payload,
  };
};
