/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

const initialState = {
  // get notification list
  getNotificationListLoading: false,
  getNotificationListErrorMsg: '',
  notificationList: [],
  // read clear notification
  readClearNotificationLoading: false,
  readClearNotificationErrorMsg: '',
  // read clear all notification
  readClearAllNotificationLoading: false,
  readClearAllNotificationErrorMsg: '',
  //notification management
  // push notification
  // get notification history
  getNotificationHistoryLoading: false,
  getNotificationHistoryErrorMsg: '',
  notificationHistory: [],
  // send push notification
  sendPushNotificationLoading: false,
  sendPushNotificationErrorMsg: '',
  // resend push notification
  resendPushNotificationLoading: false,
  resendPushNotificationErrorMsg: '',
  // delete push notification
  deletePushNotificationLoading: false,
  deletePushNotificationErrorMsg: '',
  //kyc reminders
  //create reminder
  createReminderLoading: false,
  createReminderErrorMsg: '',
  //get reminder list
  getReminderListLoading: false,
  getReminderListErrorMsg: '',
  reminderList: [],
  //get reminder
  getReminderLoading: false,
  getReminderErrorMsg: '',
  reminder: [],
  //update reminder
  updateReminderLoading: false,
  updateReminderErrorMsg: '',
  //delete reminder
  deleteReminderLoading: false,
  deleteReminderErrorMsg: '',
  //emails
  //send email
  sendEmailLoading: false,
  sendEmailErrorMsg: '',
  //get locations
  getLocationsLoading: false,
  getLocationsErrorMsg: '',
  locations: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // get notification list
    case actionLabels.GET_NOTIFICATION_LIST_START:
      return { ...state, getNotificationListLoading: true, getNotificationListErrorMsg: '' };
    case actionLabels.GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        getNotificationListLoading: false,
        getNotificationListErrorMsg: '',
        notificationList: payload,
      };
    case actionLabels.GET_NOTIFICATION_LIST_FAIL:
      return {
        ...state,
        getNotificationListLoading: false,
        getNotificationListErrorMsg: payload,
        notificationList: [],
      };

    // read clear notification
    case actionLabels.READ_CLEAR_NOTIFICATION_START:
      return { ...state, readClearNotificationLoading: true, readClearNotificationErrorMsg: '' };
    case actionLabels.READ_CLEAR_NOTIFICATION_SUCCESS:
      return {
        ...state,
        readClearNotificationLoading: false,
        readClearNotificationErrorMsg: '',
      };
    case actionLabels.READ_CLEAR_NOTIFICATION_FAIL:
      return {
        ...state,
        readClearNotificationLoading: false,
        readClearNotificationErrorMsg: payload,
      };

    // read clear all notification
    case actionLabels.READ_CLEAR_ALL_NOTIFICATION_START:
      return {
        ...state,
        readClearAllNotificationLoading: true,
        readClearAllNotificationErrorMsg: '',
      };
    case actionLabels.READ_CLEAR_ALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        readClearAllNotificationLoading: false,
        readClearAllNotificationErrorMsg: '',
      };
    case actionLabels.READ_CLEAR_ALL_NOTIFICATION_FAIL:
      return {
        ...state,
        readClearAllNotificationLoading: false,
        readClearAllNotificationErrorMsg: payload,
      };

    //notification management
    // push notification

    // get notification history
    case actionLabels.GET_NOTIFICATION_HISTORY_START:
      return {
        ...state,
        getNotificationHistoryLoading: true,
        getNotificationHistoryErrorMsg: '',
      };
    case actionLabels.GET_NOTIFICATION_HISTORY_SUCCESS:
      return {
        ...state,
        getNotificationHistoryLoading: false,
        getNotificationHistoryErrorMsg: '',
        notificationHistory: payload,
      };
    case actionLabels.GET_NOTIFICATION_HISTORY_FAIL:
      return {
        ...state,
        getNotificationHistoryLoading: false,
        getNotificationHistoryErrorMsg: payload,
        notificationHistory: [],
      };

    // send push notification
    case actionLabels.SEND_PUSH_NOTIFICATION_START:
      return {
        ...state,
        sendPushNotificationLoading: true,
        sendPushNotificationErrorMsg: '',
      };
    case actionLabels.SEND_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        sendPushNotificationLoading: false,
        sendPushNotificationErrorMsg: '',
      };
    case actionLabels.SEND_PUSH_NOTIFICATION_FAIL:
      return {
        ...state,
        sendPushNotificationLoading: false,
        sendPushNotificationErrorMsg: payload,
      };

    // resend push notification
    case actionLabels.RESEND_PUSH_NOTIFICATION_START:
      return {
        ...state,
        resendPushNotificationLoading: true,
        resendPushNotificationErrorMsg: '',
      };
    case actionLabels.RESEND_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        resendPushNotificationLoading: false,
        resendPushNotificationErrorMsg: '',
      };
    case actionLabels.RESEND_PUSH_NOTIFICATION_FAIL:
      return {
        ...state,
        resendPushNotificationLoading: false,
        resendPushNotificationErrorMsg: payload,
      };

    // delete push notification
    case actionLabels.DELETE_PUSH_NOTIFICATION_START:
      return {
        ...state,
        deletePushNotificationLoading: true,
        deletePushNotificationErrorMsg: '',
      };
    case actionLabels.DELETE_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        deletePushNotificationLoading: false,
        deletePushNotificationErrorMsg: '',
      };
    case actionLabels.DELETE_PUSH_NOTIFICATION_FAIL:
      return {
        ...state,
        deletePushNotificationLoading: false,
        deletePushNotificationErrorMsg: payload,
      };

    //kyc reminders
    //create reminder
    case actionLabels.CREATE_REMINDER_START:
      return {
        ...state,
        createReminderLoading: true,
        createReminderErrorMsg: '',
      };
    case actionLabels.CREATE_REMINDER_SUCCESS:
      return {
        ...state,
        createReminderLoading: false,
        createReminderErrorMsg: '',
      };
    case actionLabels.CREATE_REMINDER_FAIL:
      return {
        ...state,
        createReminderLoading: false,
        createReminderErrorMsg: payload,
      };

    //get kyc reminder list
    case actionLabels.GET_REMINDER_LIST_START:
      return {
        ...state,
        getReminderListLoading: true,
        getReminderListErrorMsg: '',
      };
    case actionLabels.GET_REMINDER_LIST_SUCCESS:
      return {
        ...state,
        getReminderListLoading: false,
        getReminderListErrorMsg: '',
        reminderList: payload,
      };
    case actionLabels.GET_REMINDER_LIST_FAIL:
      return {
        ...state,
        getReminderListLoading: false,
        getReminderListErrorMsg: payload,
        reminderList: [],
      };

    //get reminder
    case actionLabels.GET_REMINDER_START:
      return {
        ...state,
        getReminderLoading: true,
        getReminderErrorMsg: '',
      };
    case actionLabels.GET_REMINDER_SUCCESS:
      return {
        ...state,
        getReminderLoading: false,
        getReminderErrorMsg: '',
        reminder: payload,
      };
    case actionLabels.GET_REMINDER_FAIL:
      return {
        ...state,
        getReminderLoading: false,
        getReminderErrorMsg: payload,
        reminder: [],
      };

    //update reminder
    case actionLabels.UPDATE_REMINDER_START:
      return {
        ...state,
        updateReminderLoading: true,
        updateReminderErrorMsg: '',
      };
    case actionLabels.UPDATE_REMINDER_SUCCESS:
      return {
        ...state,
        updateReminderLoading: false,
        updateReminderErrorMsg: '',
      };
    case actionLabels.UPDATE_REMINDER_FAIL:
      return {
        ...state,
        updateReminderLoading: false,
        updateReminderErrorMsg: payload,
      };

    //delete reminder
    case actionLabels.DELETE_REMINDER_START:
      return {
        ...state,
        deleteReminderLoading: true,
        deleteReminderErrorMsg: '',
      };
    case actionLabels.DELETE_REMINDER_SUCCESS:
      return {
        ...state,
        deleteReminderLoading: false,
        deleteReminderErrorMsg: '',
      };
    case actionLabels.DELETE_REMINDER_FAIL:
      return {
        ...state,
        deleteReminderLoading: false,
        deleteReminderErrorMsg: payload,
      };

    //emails
    // send email
    case actionLabels.SEND_EMAIL_START:
      return {
        ...state,
        sendEmailLoading: true,
        sendEmailErrorMsg: '',
      };
    case actionLabels.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmailLoading: false,
        sendEmailErrorMsg: '',
      };
    case actionLabels.SEND_EMAIL_FAIL:
      return {
        ...state,
        sendEmailLoading: false,
        sendEmailErrorMsg: payload,
      };

    // get locations
    case actionLabels.GET_LOCATIONS_START:
      return {
        ...state,
        getLocationsLoading: true,
        getLocationsErrorMsg: '',
      };

    case actionLabels.GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        getLocationsLoading: false,
        getLocationsErrorMsg: '',
        locations: payload,
      };

    case actionLabels.GET_LOCATIONS_FAIL:
      return {
        ...state,
        getLocationsLoading: false,
        getLocationsErrorMsg: payload,
        locations: [],
      };

    default:
      return state;
  }
};
