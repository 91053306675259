import * as actionLabels from '../../actionLabels';

// get profile details
export const getProfileDetailsAction = payload => ({
  type: actionLabels.GET_PROFILE_DETAILS_SAGA,
  payload,
});

export const getProfileDetailsStart = payload => ({
  type: actionLabels.GET_PROFILE_DETAILS_START,
  payload,
});

export const getProfileDetailsSuccess = payload => ({
  type: actionLabels.GET_PROFILE_DETAILS_SUCCESS,
  payload,
});

export const getProfileDetailsFail = payload => ({
  type: actionLabels.GET_PROFILE_DETAILS_FAIL,
  payload,
});

// update password
export const updatePasswordAction = payload => ({
  type: actionLabels.UPDATE_PASSWORD_SAGA,
  payload,
});

export const updatePasswordStart = payload => ({
  type: actionLabels.UPDATE_PASSWORD_START,
  payload,
});

export const updatePasswordSuccess = payload => ({
  type: actionLabels.UPDATE_PASSWORD_SUCCESS,
  payload,
});

export const updatePasswordFail = payload => ({
  type: actionLabels.UPDATE_PASSWORD_FAIL,
  payload,
});
