import {
  PUSH_CMS_CONTENT_SAGA,
  GET_CMS_CONTENT_SAGA,
  GET_CMS_CONTENT_START,
  GET_CMS_CONTENT_FAIL,
  PUSH_CMS_CONTENT_SUCCESS,
  PUSH_CMS_CONTENT_FAIL,
  GET_CMS_CONTENT_SUCCESS,
  PUSH_CMS_CONTENT_START,
} from 'store/actionLabels';

export const getCmsContentSaga = payload => {
  return {
    type: GET_CMS_CONTENT_SAGA,
    payload,
  };
};

export const getCmsContentStart = payload => ({
  type: GET_CMS_CONTENT_START,
  payload,
});
export const getCmsContentSuccess = payload => ({
  type: GET_CMS_CONTENT_SUCCESS,
  payload,
});

export const getCmsContentFail = payload => ({
  type: GET_CMS_CONTENT_FAIL,
  payload,
});

export const pushCmsContentSaga = payload => {
  return {
    type: PUSH_CMS_CONTENT_SAGA,
    payload,
  };
};

export const pushCmsContentStart = payload => {
  return {
    type: PUSH_CMS_CONTENT_START,
    payload,
  };
};

export const pushCmsContentSuccess = payload => ({
  type: PUSH_CMS_CONTENT_SUCCESS,
  payload,
});

export const pushCmsContentFail = payload => ({
  type: PUSH_CMS_CONTENT_FAIL,
  payload,
});
