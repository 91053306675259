import * as actionLabels from '../../actionLabels';

// get subscription list
export const getSubscriptionListAction = payload => ({
  type: actionLabels.GET_SUBSCRIPTION_LIST_SAGA,
  payload,
});

export const getSubscriptionListStart = payload => ({
  type: actionLabels.GET_SUBSCRIPTION_LIST_START,
  payload,
});

export const getSubscriptionListSuccess = payload => ({
  type: actionLabels.GET_SUBSCRIPTION_LIST_SUCCESS,
  payload,
});

export const getSubscriptionListFail = payload => ({
  type: actionLabels.GET_SUBSCRIPTION_LIST_FAIL,
  payload,
});

// get subscription details
export const getSubscriptionDetailsAction = payload => ({
  type: actionLabels.GET_SUBSCRIPTION_DETAILS_SAGA,
  payload,
});

export const getSubscriptionDetailsStart = payload => ({
  type: actionLabels.GET_SUBSCRIPTION_DETAILS_START,
  payload,
});

export const getSubscriptionDetailsSuccess = payload => ({
  type: actionLabels.GET_SUBSCRIPTION_DETAILS_SUCCESS,
  payload,
});

export const getSubscriptionDetailsFail = payload => ({
  type: actionLabels.GET_SUBSCRIPTION_DETAILS_FAIL,
  payload,
});

// update subscription
export const updateSubscriptionAction = payload => ({
  type: actionLabels.UPDATE_SUBSCRIPTION_SAGA,
  payload,
});

export const updateSubscriptionStart = payload => ({
  type: actionLabels.UPDATE_SUBSCRIPTION_START,
  payload,
});

export const updateSubscriptionSuccess = payload => ({
  type: actionLabels.UPDATE_SUBSCRIPTION_SUCCESS,
  payload,
});

export const updateSubscriptionFail = payload => ({
  type: actionLabels.UPDATE_SUBSCRIPTION_FAIL,
  payload,
});
