const Partner = () => {
  return (
    <><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2940_23992)">
    <path d="M16.2501 10.4771L10.0001 16.6671L3.75009 10.4771C3.33784 10.0759 3.01312 9.59374 2.79638 9.06092C2.57963 8.52811 2.47556 7.95618 2.4907 7.38117C2.50585 6.80615 2.63989 6.2405 2.88439 5.71984C3.12888 5.19917 3.47853 4.73477 3.91133 4.35587C4.34412 3.97698 4.85068 3.6918 5.3991 3.51829C5.94752 3.34479 6.52593 3.28671 7.09789 3.34773C7.66986 3.40874 8.223 3.58752 8.72248 3.87281C9.22196 4.1581 9.65696 4.54372 10.0001 5.00539C10.3447 4.54708 10.7802 4.16483 11.2793 3.88256C11.7785 3.6003 12.3305 3.4241 12.9009 3.36499C13.4712 3.30588 14.0477 3.36514 14.5941 3.53905C15.1405 3.71296 15.6451 3.99779 16.0764 4.37569C16.5077 4.7536 16.8563 5.21646 17.1004 5.7353C17.3446 6.25414 17.479 6.81779 17.4953 7.39098C17.5117 7.96417 17.4095 8.53455 17.1952 9.06643C16.9809 9.59831 16.6592 10.0802 16.2501 10.4821" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.0001 5L7.25595 7.74417C7.09973 7.90044 7.01196 8.11236 7.01196 8.33333C7.01196 8.5543 7.09973 8.76623 7.25595 8.9225L7.70845 9.375C8.28345 9.95 9.21679 9.95 9.79179 9.375L10.6251 8.54167C11.1224 8.04439 11.7969 7.76502 12.5001 7.76502C13.2034 7.76502 13.8778 8.04439 14.3751 8.54167L16.2501 10.4167" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.4167 12.917L12.0834 14.5837" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.5 10.833L14.1667 12.4997" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </svg>
    </>
  )
}

export default Partner