import {
  GET_DEVICE_LIST_START,
  GET_DEVICE_LIST_FAIL,
  GET_DEVICE_DETAILS_START,
  GET_DEVICE_DETAILS_FAIL,
  GET_DEVICE_DETAILS_SUCCESS,
  GET_DEVICE_DETAILS_SAGA,
  GET_DEVICE_LIST_SUCCESS,
  GET_DEVICE_LIST_SAGA,
} from 'store/actionLabels';

// get device list
export const getDeviceListAction = payload => ({
  type: GET_DEVICE_LIST_SAGA,
  payload,
});

export const getDeviceListStart = payload => ({
  type: GET_DEVICE_LIST_START,
  payload,
});

export const getDeviceListSuccess = payload => ({
  type: GET_DEVICE_LIST_SUCCESS,
  payload,
});

export const getDeviceListFail = payload => ({
  type: GET_DEVICE_LIST_FAIL,
  payload,
});

// get device details
export const getDeviceDetailsAction = payload => ({
  type: GET_DEVICE_DETAILS_SAGA,
  payload,
});

export const getDeviceDetailsStart = payload => ({
  type: GET_DEVICE_DETAILS_START,
  payload,
});

export const getDeviceDetailsSuccess = payload => ({
  type: GET_DEVICE_DETAILS_SUCCESS,
  payload,
});

export const getDeviceDetailsFail = payload => ({
  type: GET_DEVICE_DETAILS_FAIL,
  payload,
});
