import { Auth } from 'aws-amplify';
import { formatDate, formatTime } from './reusableFuctions';

export const compareArray = (a1, a2) => {
  let changed = false;
  if (a1.length !== a2.length) return true;
  if (!a1.length) return false;
  a1.forEach((element, index) => {
    if (JSON.stringify(element) !== JSON.stringify(a2[index])) {
      changed = true;
    }
  });
  return changed;
};

export const deleteObjKeys = (arr, obj) => {
  arr.forEach(ele => {
    delete obj[ele];
  });
  return obj;
};
export const handleResetSearchRange = (setFilterValue, filterValue, setCurrentPage) => {
  setCurrentPage(1);
  setFilterValue({
    ...filterValue,
    startDate: '',
    endDate: '',
  });
};

export const GlobalLogout = async Theme => {
  await Auth.signOut();
  sessionStorage.clear();
  window.location.reload();
};

export const downloadCSV = (data = [], filename) => {
  const csvData = convertToCSV(data);
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const convertToCSV = (data = []) => {
  const csvRows = [];
  const headers = [
    'Date of Submission and Time',
    'Full Name',
    'Registered Email Address',
    'Phone Number',
    'What industry you are selling in?',
    'How would you best describe your product?',
    'How many people are you expecting to reach?',
  ];
  csvRows.push(headers.join(','));

  for (const row of data) {
    const values = [
      `${formatDate(row?.createdAt)} ${formatTime(row?.createdAt)}` ?? '-',
      `${row?.createdUser?.firstName} ${row?.createdUser?.lastName}` ?? '-',
      row?.createdUser?.email ?? '-',
      `${(row?.createdUser?.mobileNumber).toString()}` ?? '-',
      row?.questionOne ?? '-',
      row?.questionTwo ?? '-',
      row?.questionThree ?? '-',
    ];
    csvRows.push(values.join(','));
  }

  return csvRows.join('\n');
};
