import * as actionLabels from '../../actionLabels';

// get dashboard stats
export const getDashboardStatsAction = payload => ({
  type: actionLabels.GET_DASHBOARD_STATS_SAGA,
  payload,
});

export const getDashboardStatsStart = payload => ({
  type: actionLabels.GET_DASHBOARD_STATS_START,
  payload,
});

export const getDashboardStatsSuccess = payload => ({
  type: actionLabels.GET_DASHBOARD_STATS_SUCCESS,
  payload,
});

export const getDashboardStatsFail = payload => ({
  type: actionLabels.GET_DASHBOARD_STATS_FAIL,
  payload,
});

// get recent devices
export const getRecentDeviceAction = payload => ({
  type: actionLabels.GET_RECENT_DEVICE_SAGA,
  payload,
});

export const getRecentDeviceStart = payload => ({
  type: actionLabels.GET_RECENT_DEVICE_START,
  payload,
});

export const getRecentDeviceSuccess = payload => ({
  type: actionLabels.GET_RECENT_DEVICE_SUCCESS,
  payload,
});

export const getRecentDeviceFail = payload => ({
  type: actionLabels.GET_RECENT_DEVICE_FAIL,
  payload,
});

// get dashboard sales
export const getDashboardSalesAction = payload => ({
  type: actionLabels.GET_DASHBOARD_SALES_SAGA,
  payload,
});

export const getDashboardSalesStart = payload => ({
  type: actionLabels.GET_DASHBOARD_SALES_START,
  payload,
});

export const getDashboardSalesSuccess = payload => ({
  type: actionLabels.GET_DASHBOARD_SALES_SUCCESS,
  payload,
});

export const getDashboardSalesFail = payload => ({
  type: actionLabels.GET_DASHBOARD_SALES_FAIL,
  payload,
});

// get top artists
export const getTopArtistsAction = payload => ({
  type: actionLabels.GET_TOP_ARTISTS_SAGA,
  payload,
});

export const getTopArtistsStart = payload => ({
  type: actionLabels.GET_TOP_ARTISTS_START,
  payload,
});

export const getTopArtistsSuccess = payload => ({
  type: actionLabels.GET_TOP_ARTISTS_SUCCESS,
  payload,
});

export const getTopArtistsFail = payload => ({
  type: actionLabels.GET_TOP_ARTISTS_FAIL,
  payload,
});

// get hot nft
export const getHotNftAction = payload => ({
  type: actionLabels.GET_HOT_NFT_SAGA,
  payload,
});

export const getHotNftStart = payload => ({
  type: actionLabels.GET_HOT_NFT_START,
  payload,
});

export const getHotNftSuccess = payload => ({
  type: actionLabels.GET_HOT_NFT_SUCCESS,
  payload,
});

export const getHotNftFail = payload => ({
  type: actionLabels.GET_HOT_NFT_FAIL,
  payload,
});

// get top collection
export const getTopCollectionsAction = payload => ({
  type: actionLabels.GET_TOP_COLLECTIONS_SAGA,
  payload,
});

export const getTopCollectionsStart = payload => ({
  type: actionLabels.GET_TOP_COLLECTIONS_START,
  payload,
});

export const getTopCollectionsSuccess = payload => ({
  type: actionLabels.GET_TOP_COLLECTIONS_SUCCESS,
  payload,
});

export const getTopCollectionsFail = payload => ({
  type: actionLabels.GET_TOP_COLLECTIONS_FAIL,
  payload,
});
