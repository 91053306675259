/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-return-await */
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import UserAccountContext from './UserAccountContext';
import { toast } from 'react-toastify';

const UserAccountState = ({ children }) => {
  const [UserData, setUserSuccess] = useState({});
  const getSession = async () =>
    await new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(current => {
          Auth.currentSession()
            .then(session => {
              resolve({ session, user: current });
            })
            .catch(err => {
              reject(err);
            });
        })
        .catch(err => {
          reject(err);
        });
    });

  const authenticate = async (Username, Password) =>
    await new Promise((resolve, reject) => {
      Auth.signIn(Username, Password)
        .then(user => {
          resolve(user);
        })
        .catch(err => {
          toast.error(err?.message);
          reject(err);
        });
    });

  const createUserSignup = async values =>
    new Promise((resolve, reject) => {
      const { emailId, phoneNumber, password, firstName, lastName } = values;
      const attributeList = [];
      const userAttribute = {
        dataEmail: {
          Name: 'email',
          Value: emailId,
        },
        dataPhoneNumber: {
          Name: 'phone_number',
          Value: `+${phoneNumber}`,
        },
        updateAt: {
          Name: 'updated_at',
          Value: new Date().getTime().toString(),
        },
        firstname: {
          Name: 'custom:first_name',
          Value: firstName,
        },
        lastname: {
          Name: 'custom:last_name',
          Value: lastName,
        },
        role: {
          Name: 'custom:role',
          Value: 'user',
        },
      };

      Auth.signUp({
        username: emailId,
        password,
        attributes: {
          email: emailId,
          phone_number: `+${phoneNumber}`,
          updated_at: new Date().getTime().toString(),
          'custom:first_name': firstName,
          'custom:last_name': lastName,
          'custom:role': 'user',
        },
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          // console.log('Error signing up user', err);
        });
    });
  const confirmOtp = async (Username, otp) =>
    await new Promise((resolve, reject) => {
      Auth.confirmSignUp(Username, otp)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  const resendOtp = async Username =>
    await new Promise((resolve, reject) => {
      Auth.resendSignUp(Username)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });

  const setMfaenabled = async username =>
    await new Promise((resolve, reject) => {
      getSession()
        .then(({ user }) => {
          Auth.getPreferredMFA(user)
            .then(preferedmfa => {
              Auth.setPreferredMFA(user, 'SOFTWARE_TOKEN_MFA')
                .then(data => {
                  resolve(data);
                })
                .catch(err => {
                  // console.log(err);
                  reject(err);
                });
            })
            .catch(err => {
              // console.log(err);
            });
        })
        .catch(err => {
          reject(err);
          // console.log(err);
        });
    });
  const setUserAuthsuccess = async () => {
    try {
      const { user } = await getSession();
      setUserSuccess(user);
    } catch (error) {
      console.log(error);
    }
  };

  const forgotPasswordOTP = async Username =>
    await new Promise((resolve, reject) => {
      Auth.forgotPassword(Username)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
          let error = JSON.parse(JSON.stringify(err));
          if (error?.code === 'UserNotFoundException') toast.error('Username not found.');
          else toast.error(err?.message);
        });
    });
  const forgotPasswordSubmit = async (Username, code, newPassword) =>
    await new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(Username, code, newPassword)
        .then(data => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
          toast.error(err?.message);
        });
    });

  const changePassword = async (oldPassword, newPassword) => {
    await new Promise(async (resolve, reject) => {
      return Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(user, oldPassword, newPassword);
        })
        .then(data => resolve(data))
        .catch(err => reject(err));
    });
  };
  return (
    <UserAccountContext.Provider
      value={{
        authenticate,
        createUserSignup,
        getSession,
        confirmOtp,
        setMfaenabled,
        resendOtp,
        UserData,
        setUserAuthsuccess,
        forgotPasswordOTP,
        forgotPasswordSubmit,
        changePassword,
      }}
    >
      {children}
    </UserAccountContext.Provider>
  );
};

export { UserAccountState };
