import { toast } from 'react-toastify';

import errorHandler from 'utils/apiHandler';
import { put } from 'redux-saga/effects';
import * as actions from '../../actions';
import { GET_ALL_ROLE_URL, GET_ROLE_URL, ROLE_LIST_URL } from 'apis';
import { GET_PERMISSIONS_URL } from 'apis';

// get role dropdown list saga
export function* getRoleListSaga() {
  yield put(actions.getRoleListStart());
  yield errorHandler({
    endpoint: `${ROLE_LIST_URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getRoleListSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getRoleListFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get role saga
export function* getRoleSaga({ payload }) {
  const { id } = payload;
  yield put(actions.getRoleStart());
  yield errorHandler({
    endpoint: `${GET_ROLE_URL}/${id}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getRoleSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getRoleFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get all role saga
export function* getAllRoleSaga({ payload }) {
  const { limit, offset, URL = '' } = payload;
  yield put(actions.getAllRoleStart());
  yield errorHandler({
    endpoint: `${GET_ALL_ROLE_URL}?limit=${limit}&offset=${offset}${URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getAllRoleSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getAllRoleFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// create role
export function* createRoleSaga({ payload }) {
  const { data, handleAllRole } = payload;
  yield put(actions.createRoleStart());
  yield errorHandler({
    endpoint: GET_ROLE_URL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.createRoleSuccess(data));
      toast.success(response?.message);
      if (handleAllRole) handleAllRole();
    },
    failHandler: yield function* (response) {
      yield put(actions.createRoleFail(response));
      toast.error(response?.message);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'post',
    payload: data,
    token: true,
  });
}

// update role
export function* updateRoleSaga({ payload }) {
  const { roleId, data, handleAllRole, closeRoleModal } = payload;
  yield put(actions.updateRoleStart());
  yield errorHandler({
    endpoint: `${GET_ROLE_URL}/${roleId}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.updateRoleSuccess(data));
      toast.success(response?.message);
      if (closeRoleModal) closeRoleModal();
      if (handleAllRole) handleAllRole();
    },
    failHandler: yield function* (response) {
      yield put(actions.updateRoleFail(response));
      toast.error(response?.message);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'put',
    payload: data,
    token: true,
  });
}

// delete role
export function* deleteRoleSaga({ payload }) {
  yield put(actions.deleteRoleStart());
  const { id, handleAllRole } = payload;
  yield errorHandler({
    endpoint: `${GET_ROLE_URL}/${id}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.deleteRoleSuccess(data));
      toast.success(response?.message);
      if (handleAllRole) handleAllRole();
    },
    failHandler: yield function* (response) {
      yield put(actions.deleteRoleFail(response));
      toast.error(response);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'delete',
    token: true,
  });
}

// get permissions
export function* getPermissionsSaga() {
  yield put(actions.getPermissionsStart());
  yield errorHandler({
    endpoint: `${GET_PERMISSIONS_URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getPermissionsSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getPermissionsFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}
