import { toast } from 'react-toastify';

import errorHandler from 'utils/apiHandler';
import { put } from 'redux-saga/effects';
import * as actions from '../../actions';
import {
  GET_SUBSCRIPTION_LIST_URL,
  GET_SUBSCRIPTION_DETAILS_URL,
  UPDATE_SUBSCRIPTION_URL,
} from 'apis';

// get subscription list saga
export function* getSubscriptionListSaga({ payload }) {
  const { limit, offset, URL = '' } = payload;
  yield put(actions.getSubscriptionListStart());
  yield errorHandler({
    endpoint: `${GET_SUBSCRIPTION_LIST_URL}?limit=${limit}&offset=${offset}${URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getSubscriptionListSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getSubscriptionListFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get subscription details saga
export function* getSubscriptionDetailsSaga({ payload }) {
  const { id } = payload;
  yield put(actions.getSubscriptionDetailsStart());
  yield errorHandler({
    endpoint: `${GET_SUBSCRIPTION_DETAILS_URL}/${id}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getSubscriptionDetailsSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getSubscriptionDetailsFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// update subscription
export function* updateSubscriptionSaga({ payload }) {
  console.log(payload, 'payyyyy');
  const { subscriptionId, data, handleGetSubscriptionList, closeSubscriptionModal } = payload;
  yield put(actions.updateSubscriptionStart());
  yield errorHandler({
    endpoint: `${UPDATE_SUBSCRIPTION_URL}/${subscriptionId}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.updateSubscriptionSuccess(data));
      toast.success(response?.message);
      if (closeSubscriptionModal) closeSubscriptionModal();
      if (handleGetSubscriptionList) handleGetSubscriptionList();
    },
    failHandler: yield function* (response) {
      yield put(actions.updateSubscriptionFail(response));
      toast.error(response?.message);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'put',
    payload: data,
    token: true,
  });
}
