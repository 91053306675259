export const GET_CATEGORY_LIST_SAGA = 'GET_CATEGORY_LIST_SAGA';
export const GET_CATEGORY_LIST_START = 'GET_CATEGORY_LIST_START';
export const GET_CATEGORY_LIST_SUCCESS = 'GET_CATEGORY_LIST_SUCCESS';
export const GET_CATEGORY_LIST_FAIL = 'GET_CATEGORY_LIST_FAIL';

export const GET_CATEGORY_DETAILS_SAGA = 'GET_CATEGORY_DETAILS_SAGA';
export const GET_CATEGORY_DETAILS_START = 'GET_CATEGORY_DETAILS_START';
export const GET_CATEGORY_DETAILS_SUCCESS = 'GET_CATEGORY_DETAILS_SUCCESS';
export const GET_CATEGORY_DETAILS_FAIL = 'GET_CATEGORY_DETAILS_FAIL';

export const CREATE_CATEGORY_SAGA = 'CREATE_CATEGORY_SAGA';
export const CREATE_CATEGORY_START = 'CREATE_CATEGORY_START';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL';

export const UPDATE_CATEGORY_SAGA = 'UPDATE_CATEGORY_SAGA';
export const UPDATE_CATEGORY_START = 'UPDATE_CATEGORY_START';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';

export const DELETE_CATEGORY_SAGA = 'DELETE_CATEGORY_SAGA';
export const DELETE_CATEGORY_START = 'DELETE_CATEGORY_START';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';
