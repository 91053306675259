/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

const initialState = {
  // get category list
  getCategoryListLoading: false,
  getCategoryListErrorMsg: '',
  categoryList: [],
  // get category details
  getCategoryDetailsLoading: false,
  getCategoryDetailsErrorMsg: '',
  categoryDetails: null,
  // create category
  createCategoryLoading: false,
  createCategoryErrorMsg: '',
  // update category
  updateCategoryLoading: false,
  updateCategoryErrorMsg: '',
  // delete category
  deleteCategoryLoading: false,
  deleteCategoryErrorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // get category list
    case actionLabels.GET_CATEGORY_LIST_START:
      return { ...state, getCategoryListLoading: true, getCategoryListErrorMsg: '' };
    case actionLabels.GET_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        getCategoryListLoading: false,
        getCategoryListErrorMsg: '',
        categoryList: payload,
      };
    case actionLabels.GET_CATEGORY_LIST_FAIL:
      return {
        ...state,
        getCategoryListLoading: false,
        getCategoryListErrorMsg: payload,
        categoryList: [],
      };

    // get category details
    case actionLabels.GET_CATEGORY_DETAILS_START:
      return { ...state, getCategoryDetailsLoading: true, getCategoryDetailsErrorMsg: '' };
    case actionLabels.GET_CATEGORY_DETAILS_SUCCESS:
      return {
        ...state,
        getCategoryDetailsLoading: false,
        getCategoryDetailsErrorMsg: '',
        categoryDetails: payload,
      };
    case actionLabels.GET_CATEGORY_DETAILS_FAIL:
      return { ...state, getCategoryDetailsLoading: false, getCategoryDetailsErrorMsg: payload };

    // create category
    case actionLabels.CREATE_CATEGORY_START:
      return { ...state, createCategoryLoading: true, createCategoryErrorMsg: '' };
    case actionLabels.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        createCategoryLoading: false,
        createCategoryErrorMsg: '',
      };
    case actionLabels.CREATE_CATEGORY_FAIL:
      return {
        ...state,
        createCategoryLoading: false,
        createCategoryErrorMsg: payload,
      };

    // update category
    case actionLabels.UPDATE_CATEGORY_START:
      return { ...state, updateCategoryLoading: true, updateCategoryErrorMsg: '' };
    case actionLabels.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateCategoryLoading: false,
        updateCategoryErrorMsg: '',
      };
    case actionLabels.UPDATE_CATEGORY_FAIL:
      return {
        ...state,
        updateCategoryLoading: false,
        updateCategoryErrorMsg: payload,
      };

    // delete category
    case actionLabels.DELETE_CATEGORY_START:
      return { ...state, deleteCategoryLoading: true, deleteCategoryErrorMsg: '' };
    case actionLabels.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        deleteCategoryLoading: false,
        deleteCategoryErrorMsg: '',
      };
    case actionLabels.DELETE_CATEGORY_FAIL:
      return {
        ...state,
        deleteCategoryLoading: false,
        deleteCategoryErrorMsg: payload,
      };

    default:
      return state;
  }
};
