import errorHandler from 'utils/apiHandler';
import { put } from 'redux-saga/effects';

import { GET_DEVICE_LIST_URL, GET_DEVICE_DETAILS_URL } from 'apis';

import {
  getDeviceListStart,
  getDeviceListSuccess,
  getDeviceListFail,
  getDeviceDetailsStart,
  getDeviceDetailsSuccess,
  getDeviceDetailsFail,
} from 'store/actions';

// get device list saga
export function* getDeviceListSaga({ payload }) {
  const { limit, offset, search } = payload;
  yield put(getDeviceListStart());
  yield errorHandler({
    endpoint: `${GET_DEVICE_LIST_URL}?limit=${limit}&offset=${offset}&search=${search}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getDeviceListSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getDeviceListFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get device details saga
export function* getDeviceDetailsSaga({ payload }) {
  const { id } = payload;
  yield put(getDeviceDetailsStart());
  yield errorHandler({
    endpoint: `${GET_DEVICE_DETAILS_URL}/${id}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getDeviceDetailsSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getDeviceDetailsFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}
