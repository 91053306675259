// get drop requests
export const GET_DROPS_REQUESTS_SAGA = 'GET_DROPS_REQUESTS_SAGA';
export const GET_DROPS_REQUESTS_START = 'GET_DROPS_REQUESTS_START';
export const GET_DROPS_REQUESTS_SUCCESS = 'GET_DROPS_REQUESTS_SUCCESS';
export const GET_DROPS_REQUESTS_FAIL = 'GET_DROPS_REQUESTS_FAIL';

// get live drop
export const GET_LIVE_DROPS_SAGA = 'GET_LIVE_DROPS_SAGA';
export const GET_LIVE_DROPS_START = 'GET_LIVE_DROPS_START';
export const GET_LIVE_DROPS_SUCCESS = 'GET_LIVE_DROPS_SUCCESS';
export const GET_LIVE_DROPS_FAIL = 'GET_LIVE_DROPS_FAIL';

// get drop details
export const GET_DROPS_DETAILS_SAGA = 'GET_DROPS_DETAILS_SAGA';
export const GET_DROPS_DETAILS_START = 'GET_DROPS_DETAILS_START';
export const GET_DROPS_DETAILS_SUCCESS = 'GET_DROPS_DETAILS_SUCCESS';
export const GET_DROPS_DETAILS_FAIL = 'GET_DROPS_DETAILS_FAIL';

// approve drop
export const APPROVE_DROPS_SAGA = 'APPROVE_DROPS_SAGA';
export const APPROVE_DROPS_START = 'APPROVE_DROPS_START';
export const APPROVE_DROPS_SUCCESS = 'APPROVE_DROPS_SUCCESS';
export const APPROVE_DROPS_FAIL = 'APPROVE_DROPS_FAIL';

// reject drop
export const REJECT_DROPS_SAGA = 'REJECT_DROPS_SAGA';
export const REJECT_DROPS_START = 'REJECT_DROPS_START';
export const REJECT_DROPS_SUCCESS = 'REJECT_DROPS_SUCCESS';
export const REJECT_DROPS_FAIL = 'REJECT_DROPS_FAIL';
