import { toast } from 'react-toastify';
import { put } from 'redux-saga/effects';
import errorHandler from 'utils/apiHandler';
import * as actions from '../../actions';
import {
  GET_NOTIFICATION_LIST_URL,
  READ_CLEAR_NOTIFICATION_URL,
  READ_CLEAR_ALL_NOTIFICATION_URL,
  GET_NOTIFICATION_MANAGEMENT_HISTORY_URL,
  GET_KYC_REMINDER_LIST_URL,
  SEND_PUSH_NOTIFICATION_URL,
  RESEND_PUSH_NOTIFICATION_URL,
  DELETE_PUSH_NOTIFICATION_URL,
  CREATE_KYC_REMINDER_URL,
  GET_KYC_REMINDER_URL,
  UPDATE_KYC_REMINDER_URL,
  DELETE_KYC_REMINDER_URL,
  SEND_EMAIL_URL,
} from 'apis';
import { GET_LOCATIONS_URL } from 'apis';

// get notification list saga
export function* getNotificationListSaga({ payload }) {
  const { limit, offset } = payload;
  yield put(actions.getNotificationListStart());
  yield errorHandler({
    endpoint: `${GET_NOTIFICATION_LIST_URL}?limit=${limit}&offset=${offset}`,

    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getNotificationListSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getNotificationListFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// read clear notification
export function* readClearNotificationSaga({ payload }) {
  const { id, data, handleGetNotificationList } = payload;
  yield put(actions.readClearNotificationStart());
  yield errorHandler({
    endpoint: `${READ_CLEAR_NOTIFICATION_URL}/${id}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.readClearNotificationSuccess(data));
      toast.success(response?.message);
      if (handleGetNotificationList) handleGetNotificationList();
    },
    failHandler: yield function* (response) {
      yield put(actions.readClearNotificationFail(response));
      toast.error(response?.message);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'put',
    payload: data,
    token: true,
  });
}

// read clear all notification
export function* readClearAllNotificationSaga({ payload }) {
  const { data, handleGetNotificationList } = payload;
  yield put(actions.readClearAllNotificationStart());
  yield errorHandler({
    endpoint: READ_CLEAR_ALL_NOTIFICATION_URL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.readClearAllNotificationSuccess(data));
      toast.success(response?.message);
      if (handleGetNotificationList) handleGetNotificationList();
    },
    failHandler: yield function* (response) {
      yield put(actions.readClearAllNotificationFail(response));
      toast.error(response?.message);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'put',
    payload: data,
    token: true,
  });
}

// notification management
// push notification
// get notification history
export function* getNotificationHistorySaga({ payload }) {
  const { limit, offset } = payload;
  yield put(actions.getNotificationHistoryStart());
  yield errorHandler({
    endpoint: `${GET_NOTIFICATION_MANAGEMENT_HISTORY_URL}?limit=${limit}&offset=${offset}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getNotificationHistorySuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getNotificationHistoryFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// send push notification
export function* sendPushNotificationSaga({ payload }) {
  const { data, allNotificationHistory } = payload;
  yield put(actions.sendPushNotificationStart());
  yield errorHandler({
    endpoint: `${SEND_PUSH_NOTIFICATION_URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.sendPushNotificationSuccess(data));
      toast.success(response?.message);
      if (allNotificationHistory) allNotificationHistory();
    },
    failHandler: yield function* (response) {
      yield put(actions.sendPushNotificationFail(response));
      toast.error(response?.message);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'post',
    payload: data,
    token: true,
  });
}

// resend push notification
export function* resendPushNotificationSaga({ payload }) {
  const { id, allNotificationHistory } = payload;
  yield put(actions.resendPushNotificationStart());
  yield errorHandler({
    endpoint: `${RESEND_PUSH_NOTIFICATION_URL}/${id}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.resendPushNotificationSuccess(data));
      toast.success(response?.message);
      if (allNotificationHistory) allNotificationHistory();
    },
    failHandler: yield function* (response) {
      yield put(actions.resendPushNotificationFail(response));
      toast.error(response?.message);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'post',
    payload: payload,
    token: true,
  });
}

// delete push notification
export function* deletePushNotificationSaga({ payload }) {
  const { id, allNotificationHistory } = payload;
  yield put(actions.deletePushNotificationStart());
  yield errorHandler({
    endpoint: `${DELETE_PUSH_NOTIFICATION_URL}/${id}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.deletePushNotificationSuccess(data));
      toast.success(response?.message);
      if (allNotificationHistory) allNotificationHistory();
    },
    failHandler: yield function* (response) {
      yield put(actions.deletePushNotificationFail(response));
      toast.error(response?.message);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'delete',
    payload: payload,
    token: true,
  });
}

// kyc reminder
// create reminder
export function* createKycReminderSaga({ payload }) {
  const { data, allKycReminder, closeModal } = payload;
  yield put(actions.createKycReminderStart());
  yield errorHandler({
    endpoint: `${CREATE_KYC_REMINDER_URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.createKycReminderSuccess(data));
      toast.success(response?.message);
      if (allKycReminder) allKycReminder();
      if (closeModal) closeModal();
    },
    failHandler: yield function* (response) {
      yield put(actions.createKycReminderFail(response));
      toast.error(response?.message);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'post',
    payload: data,
    token: true,
  });
}

// get kyc reminder list
export function* getKycReminderListSaga({ payload }) {
  const { limit, offset } = payload;
  yield put(actions.getKycReminderListStart());
  yield errorHandler({
    endpoint: `${GET_KYC_REMINDER_LIST_URL}?limit=${limit}&offset=${offset}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getKycReminderListSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getKycReminderListFail(response));
      // yield put(actions.getNotificationListAction());
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get kyc reminder
export function* getKycReminderSaga({ payload }) {
  const { id } = payload;
  yield put(actions.getKycReminderStart());
  yield errorHandler({
    endpoint: `${GET_KYC_REMINDER_URL}/${id}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getKycReminderSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getKycReminderFail(response));
      toast.error(response?.message);
      // yield put(actions.getNotificationListAction());
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// update reminder
export function* updateKycReminderSaga({ payload }) {
  const { id, data, allKycReminder, closeModal } = payload;
  yield put(actions.updateKycReminderStart());
  yield errorHandler({
    endpoint: `${UPDATE_KYC_REMINDER_URL}/${id}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.updateKycReminderSuccess(data));
      toast.success(response?.message);
      if (allKycReminder) allKycReminder();
      if (closeModal) closeModal();
    },
    failhandler: yield function* (response) {
      yield put(actions.updateKycReminderFail(response));
      toast.error(response?.message);
      // yield put(actions.getNotificationListAction());
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'put',
    payload: data,
    token: true,
  });
}

// delete reminder
export function* deleteKycReminderSaga({ payload }) {
  const { id, allKycReminder } = payload;
  yield put(actions.deleteKycReminderStart());
  yield errorHandler({
    endpoint: `${DELETE_KYC_REMINDER_URL}/${id}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.deleteKycReminderSuccess(data));
      toast.success(response?.message);
      if (allKycReminder) allKycReminder();
    },
    failHandler: yield function* (response) {
      yield put(actions.deleteKycReminderFail(response));
      toast.error(response?.message);
      // yield put(actions.getNotificationListAction());
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'delete',
    payload: payload,
    token: true,
  });
}

//emails
// send email
export function* sendEmailSaga({ payload }) {
  const { data } = payload;
  yield put(actions.sendEmailStart());
  yield errorHandler({
    endpoint: `${SEND_EMAIL_URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.sendEmailSuccess(data));
      toast.success(response?.message);
    },
    failHandler: yield function* (response) {
      yield put(actions.sendEmailFail(response));
      toast.error(response?.message);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'post',
    payload: data,
    token: true,
  });
}

//get locations
export function* getLocationsSaga() {
  yield put(actions.getLocationsStart());
  yield errorHandler({
    endpoint: `${GET_LOCATIONS_URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getLocationsSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getLocationsFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}
