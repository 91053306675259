import errorHandler from 'utils/apiHandler';
import { put } from 'redux-saga/effects';

import { GET_DASHBOARD_STATS_URL, GET_RECENT_DEVICE_URL, GET_DASHBOARD_SALES_URL } from 'apis';
import {
  getRecentDeviceStart,
  getRecentDeviceFail,
  getRecentDeviceSuccess,
  getDashboardStatsFail,
  getDashboardStatsSuccess,
  getDashboardStatsStart,
  getDashboardSalesStart,
  getDashboardSalesFail,
  getDashboardSalesSuccess,
  getTopArtistsStart,
  getTopArtistsFail,
  getTopArtistsSuccess,
  getHotNftStart,
  getHotNftSuccess,
  getHotNftFail,
  getTopCollectionsStart,
  getTopCollectionsFail,
  getTopCollectionsSuccess,
} from 'store/actions';
import { GET_TOP_ARTISTS_URL } from 'apis';
import { GET_HOT_NFT_URL } from 'apis';
import { GET_TOP_COLLECTIONS_URL } from 'apis';

// get dashboard stats saga
export function* getDashboardStatsSaga() {
  yield put(getDashboardStatsStart());
  yield errorHandler({
    endpoint: GET_DASHBOARD_STATS_URL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getDashboardStatsSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getDashboardStatsFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get recent device saga
export function* getRecentDeviceSaga() {
  yield put(getRecentDeviceStart());
  yield errorHandler({
    endpoint: GET_RECENT_DEVICE_URL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getRecentDeviceSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getRecentDeviceFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get dashboard sales saga
export function* getDashboardSalesSaga({ payload }) {
  const { filterBy } = payload;
  yield put(getDashboardSalesStart());
  yield errorHandler({
    endpoint: `${GET_DASHBOARD_SALES_URL}?filterBy=${filterBy}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getDashboardSalesSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getDashboardSalesFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get top artists
export function* getTopArtistSaga({ payload }) {
  const { limit = 10, offset = 1 } = payload;
  yield put(getTopArtistsStart());
  yield errorHandler({
    endpoint: `${GET_TOP_ARTISTS_URL}?limit=${limit}&offset=${offset}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getTopArtistsSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getTopArtistsFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get hot nft
export function* getHotNftSaga({ payload }) {
  const { limit = 10, offset = 1 } = payload;
  yield put(getHotNftStart());
  yield errorHandler({
    endpoint: `${GET_HOT_NFT_URL}?limit=${limit}&offset=${offset}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getHotNftSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getHotNftFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get top collection
export function* getTopCollectionsSaga({ payload }) {
  const { limit = 10, offset = 1 } = payload;
  yield put(getTopCollectionsStart());
  yield errorHandler({
    endpoint: `${GET_TOP_COLLECTIONS_URL}?limit=${limit}&offset=${offset}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getTopCollectionsSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getTopCollectionsFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}
