import { toast } from 'react-toastify';

import errorHandler from 'utils/apiHandler';
import { put } from 'redux-saga/effects';
import * as actions from '../../actions';
import {
  GET_LIVE_DROPS_URL,
  GET_DROPS_REQUESTS_URL,
  GET_DROPS_DETAILS_URL,
  APPROVE_DROPS_URL,
  REJECT_DROPS_URL,
} from 'apis';

// get drops requests
export function* getDropsRequestsSaga({ payload }) {
  const { limit, offset, URL = '' } = payload;
  yield put(actions.getDropsRequestsStart());
  yield errorHandler({
    endpoint: `${GET_DROPS_REQUESTS_URL}&limit=${limit}&offset=${offset}${URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getDropsRequestsSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getDropsRequestsFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get live drops
export function* getLiveDropsSaga({ payload }) {
  const { limit, offset, URL = '' } = payload;
  yield put(actions.getLiveDropsStart());
  yield errorHandler({
    endpoint: `${GET_LIVE_DROPS_URL}&limit=${limit}&offset=${offset}${URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getLiveDropsSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getLiveDropsFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get drops details
export function* getDropsDetailsSaga({ payload }) {
  const { _drop } = payload;
  yield put(actions.getDropsDetailsStart());
  yield errorHandler({
    endpoint: `${GET_DROPS_DETAILS_URL}/${_drop}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getDropsDetailsSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getDropsDetailsFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// approve drops
export function* approveDropsSaga({ payload }) {
  const { _drop, handleAllDrop, handleCloseActionModal } = payload;
  yield put(actions.approveDropsStart());
  yield errorHandler({
    endpoint: `${APPROVE_DROPS_URL}/${_drop}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.approveDropsSuccess(data));
      toast.success(response?.message);
      if (handleAllDrop) handleAllDrop();
      if (handleCloseActionModal) handleCloseActionModal();
    },
    failHandler: yield function* (response) {
      yield put(actions.approveDropsFail(response));
      toast.error(response?.message);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'post',
    // payload: data,
    token: true,
  });
}

// reject drops
export function* rejectDropsSaga({ payload }) {
  const { _drop, handleAllDrop, data, handleCloseActionModal } = payload;
  yield put(actions.rejectDropsStart());
  yield errorHandler({
    endpoint: `${REJECT_DROPS_URL}/${_drop}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.rejectDropsSuccess(data));
      toast.success(response?.message);
      if (handleAllDrop) handleAllDrop();
      if (handleCloseActionModal) handleCloseActionModal();
    },
    failHandler: yield function* (response) {
      yield put(actions.rejectDropsFail(response));
      toast.error(response?.message);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'post',
    payload: data,
    token: true,
  });
}
