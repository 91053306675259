/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

const initialState = {
  // get user list
  getUserListLoading: false,
  getUserListErrorMsg: '',
  userList: [],
  // disable user
  disableUserLoading: false,
  disableUserErrorMSg: '',
  // get user device list
  getUserDeviceListLoading: false,
  getUserDeviceListErrorMsg: '',
  userDeviceList: [],
  //  suppend user
  suspendUserLoading: false,
  suspendUserErrorMsg: '',
  //  get user collecton list
  getUserCollectionListLoading: false,
  getUserCollectionListErrorMsg: '',
  getUserCollectionList: [],
  //  get user details
  getUserDetailsLoading: false,
  getUserDetailsErrorMsg: '',
  userDetails: null,
  //  get user transaction list
  getUserTransactionListLoading: false,
  getUserTransactionListErrorMsg: '',
  getUserTransactionList: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // get user list
    case actionLabels.GET_USER_LIST_START:
      return { ...state, getUserListLoading: true, getUserListErrorMsg: '' };
    case actionLabels.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        getUserListLoading: false,
        getUserListErrorMsg: '',
        userList: payload,
      };
    case actionLabels.GET_USER_LIST_FAIL:
      return {
        ...state,
        getUserListLoading: false,
        getUserListErrorMsg: payload,
        userList: [],
      };

    //  get user details
    case actionLabels.GET_USER_DETAILS_START:
      return {
        ...state,
        getUserDetailsLoading: true,
        getUserDetailsErrorMsg: '',
      };
    case actionLabels.GET_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        getUserDetailsLoading: false,
        userDetails: payload?.data,
        getUserDetailsErrorMsg: '',
      };
    }
    case actionLabels.GET_USER_DETAILS_FAIL: {
      return {
        ...state,
        getUserDetailsLoading: false,
        getUserDetailsErrorMsg: payload,
        userDetails: null,
      };
    }
    // disable user
    case actionLabels.DISABLE_USER_START:
      return { ...state, disableUserLoading: true, disableUserErrorMsg: '' };
    case actionLabels.DISABLE_USER_SUCCESS:
      return {
        ...state,
        disableUserLoading: false,
        disableUserErrorMsg: '',
      };
    case actionLabels.DISABLE_USER_FAIL:
      return {
        ...state,
        disableUserLoading: false,
        disableUserErrorMsg: payload,
      };
    // get user device list
    case actionLabels.GET_USER_DEVICE_LIST_START:
      return { ...state, getUserDeviceListLoading: true, getUserDeviceListErrorMsg: '' };
    case actionLabels.GET_USER_DEVICE_LIST_SUCCESS:
      return {
        ...state,
        getUserDeviceListLoading: false,
        getUserDeviceListErrorMsg: '',
        userDeviceList: payload,
      };
    case actionLabels.GET_USER_DEVICE_LIST_FAIL:
      return {
        ...state,
        getUserDeviceListLoading: false,
        getUserDeviceListErrorMsg: payload,
        userDeviceList: [],
      };

    //  suspend user
    case actionLabels.SUSPEND_USER_START:
      return {
        ...state,
        suspendUserLoading: true,
        suspendUserErrorMsg: '',
      };
    case actionLabels.SUSPEND_USER_SUCCESS: {
      return {
        ...state,
        suspendUserLoading: false,
        suspendUserErrorMsg: '',
      };
    }
    case actionLabels.SUSPEND_USER_FAIL: {
      return {
        ...state,
        suspendUserLoading: false,
        suspendUserErrorMsg: payload,
      };
    }

    //  get user collecton list
    case actionLabels.GET_USER_COLLECTION_LIST_START:
      return {
        ...state,
        getUserCollectionListLoading: true,
        getUserCollectionListErrorMsg: '',
      };
    case actionLabels.GET_USER_COLLECTION_LIST_SUCCESS: {
      return {
        ...state,
        getUserCollectionListLoading: false,
        getUserCollectionList: payload?.data,
        getUserCollectionListErrorMsg: '',
      };
    }
    case actionLabels.GET_USER_COLLECTION_LIST_FAIL: {
      return {
        ...state,
        getUserCollectionListLoading: false,
        getUserCollectionListErrorMsg: payload,
        getUserCollectionList: [],
      };
    }
    //  get user transaction list
    case actionLabels.GET_USER_TRANSACTION_LIST_START:
      return {
        ...state,
        getUserTransactionListLoading: true,
        getUserTransactionListErrorMsg: '',
      };
    case actionLabels.GET_USER_TRANSACTION_LIST_SUCCESS: {
      return {
        ...state,
        getUserTransactionListLoading: false,
        getUserTransactionList: payload?.data,
        getUserTransactionListErrorMsg: '',
      };
    }
    case actionLabels.GET_USER_TRANSACTION_LIST_FAIL: {
      return {
        ...state,
        getUserTransactionListLoading: false,
        getUserTransactionListErrorMsg: payload,
        getUserTransactionList: [],
      };
    }
    default:
      return state;
  }
};
