import {
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SAGA,
} from 'store/actionLabels';

// change password  
export const changePasswordAction = payload => ({
  type: CHANGE_PASSWORD_SAGA,
  payload,
});

export const changePasswordStart = payload => ({
  type: CHANGE_PASSWORD_START,
  payload,
});

export const changePasswordSuccess = payload => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload,
});

export const changePasswordFail = payload => ({
  type: CHANGE_PASSWORD_FAIL,
  payload,
});
