const Drop = () => {
  return (
<svg width="20" height="20" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2940_23972)">
<path d="M20 14.5714C20 18.7526 16.3364 22 12 22C7.66359 22 4 18.7526 4 14.5714C4 12 5.30472 9.45232 6.71637 7.42349C8.1468 5.36767 9.79177 3.69743 10.6777 2.85537M20 14.5714L10.6777 2.85537M20 14.5714C20 12 18.6953 9.45232 17.2836 7.42349C15.8532 5.36767 14.2082 3.69743 13.3223 2.85537C12.5778 2.14778 11.4222 2.14778 10.6777 2.85537M20 14.5714L10.6777 2.85537" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 18C11.4747 18 10.9546 17.8965 10.4693 17.6955C9.98396 17.4945 9.54301 17.1999 9.17157 16.8284C8.80014 16.457 8.5055 16.016 8.30448 15.5307C8.10346 15.0454 8 14.5253 8 14" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 5.83301V7.49967" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 12.5V14.1667" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>
)
}

export default Drop;

