/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

const initialState = {
  open: false,
  title: '',
  message: '',
  isLoading: false,
  withButton: true,
  buttonText: '',
  handleClick: '',
  handleCancel: '',
  notifyType: 1,
  autoClose: true,
  redirectURL: '',
  currentUserId: '',
  buttonClick: undefined,
  showPrimaryButton: true,
  showCloseButton: true,
  CustomComponent: '',
  handleCloseModal: null,
  FooterComponent: '',
  customModalOpen: false,
  customModalType: '',
  usedWallet: '',
  tempCustomModalData: null,
  geolocationModalOpen: false,
  profileTab: '',
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionLabels.SHOW_ALERT_MODAL: {
      return {
        ...state,
        title: payload?.title,
        open: true,
        message: payload?.message,
        withButton: payload?.withButton,
        buttonClick: payload?.buttonClick,
        buttonText: payload?.buttonText,
        notifyType: payload?.notifyType,
        CustomComponent: payload?.CustomComponent,
        autoClose: payload?.autoClose,
        usedWallet: payload?.usedWallet,
        handleClick: payload?.handleClick,
        handleCancel: payload?.handleCancel,
        redirectURL: payload?.redirectURL,
        currentUserId: payload?.currentUserId,
        showPrimaryButton: payload?.showPrimaryButton,
        showCloseButton: payload?.showCloseButton,
        FooterComponent: payload?.FooterComponent,
        profileTab: payload?.profileTab,
      };
    }
    case actionLabels.HIDE_MODAL: {
      return {
        ...state,
        open: false,
        isLoading: false,
      };
    }
    case actionLabels.RESET_MODAL: {
      return {
        open: false,
        title: '',
        message: '',
        isLoading: false,
        withButton: true,
        buttonText: '',
        autoClose: true,
        redirectURL: '',
        handleClick: '',
        handleCancel: '',
        currentUserId: '',
        buttonClick: undefined,
        showPrimaryButton: true,
        showCloseButton: true,
        handleCloseModal: null,
        usedWallet: '',
        FooterComponent: '',
        customModalOpen: false,
        CustomComponent: '',
        customModalType: '',
        tempCustomModalData: null,
        geolocationModalOpen: false,
        profileTab: '',
      };
    }
    case actionLabels.LOADER_SHOW: {
      return {
        ...state,
        isLoading: true,
      };
    }
    default:
      return state;
  }
};
