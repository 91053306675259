import {
  GET_REWARD_LIST_SAGA,
  GET_REWARD_LIST_START,
  GET_REWARD_LIST_SUCCESS,
  GET_REWARD_LIST_FAIL,
} from 'store/actionLabels';

// get reward list
export const getRewardListAction = payload => ({
  type: GET_REWARD_LIST_SAGA,
  payload,
});

export const getRewardListStart = payload => ({
  type: GET_REWARD_LIST_START,
  payload,
});

export const getRewardListSuccess = payload => ({
  type: GET_REWARD_LIST_SUCCESS,
  payload,
});

export const getRewardListFail = payload => ({
  type: GET_REWARD_LIST_FAIL,
  payload,
});
