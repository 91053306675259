import countryList from 'constants/countryList';
import { toTitleCase } from './reusableFuctions';

export const getCode = label => {
  const country = countryList?.filter(item => item.label === label);
  return country?.length > 0 ? country[0]?.value : undefined;
};

export const getName = value => {
  const country = countryList?.filter(item => item.value === value);
  return country?.length > 0 ? country[0]?.label : undefined;
};

export const getFullName = data => {
  return data?.firstName && data?.lastName
    ? toTitleCase(data?.firstName) + ' ' + toTitleCase(data?.lastName)
    : toTitleCase(data?.firstName) || '-';
};

export const replaceString = (arr, list) => {
  const newArr = arr.split(',').map(item => item.trim());
  let tempString = '';
  for (const x of newArr) {
    if (Object.keys(list).includes(x)) {
      tempString += list[x] + ', ';
    }
  }
  return tempString.substring(0, tempString.length - 2);
};

export const getChipCount = item => {
  let count = 0;
  item?.map(i => {
    if (i?.chipQuantity) {
      return (count = count + parseInt(i?.chipQuantity));
    }
  });
  return count;
};

export const encodedString = value => {
  return encodeURIComponent(value);
};
