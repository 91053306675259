import { put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import errorHandler from 'utils/apiHandler';
import * as actions from '../../actions';

import {
  GET_COLLECTION_ITEM_LIST_URL,
  GET_USER_REPORT_LIST_URL,
  HIDE_COLLECTION_URL,
  GET_USER_REPORT_DETAILS_URL,
  GET_USER_REPORT_COLLECTION_LIST_URL,
  GET_COLLECTION_DETAILS_URL,
  GET_USER_REPORT_COLLECTION_ITEM_LIST_URL,
  GET_NFT_ITEM_DETAILS_URL,
  HIDE_NFT_DETAILS_URL,
} from 'apis';

// get user report list
export function* getUserReportListSaga(action) {
  const { limit, offset, search, URL } = action?.payload;
  yield put(actions.getUserReporListStart());

  yield errorHandler({
    endpoint: `${GET_USER_REPORT_LIST_URL}?offset=${offset}&limit=${limit}&search=${search}&orderBy=createdAt${URL}`,
    successHandler: yield function* (response) {
      yield put(actions.getUserReporListSuccess(response));
    },
    failHandler: yield function* (response) {
      yield put(actions.getUserReporListFail(response));
      toast.error(response);
    },

    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

// get user report details
export function* getUserReportDetailsSaga(action) {
  const { id, type, offset, limit } = action?.payload;
  yield put(actions.getUserReportDetailsStart());

  yield errorHandler({
    endpoint: `${GET_USER_REPORT_DETAILS_URL}/${id}?type=${type}&offset=${offset}&limit=${limit}`,
    successHandler: yield function* (response) {
      yield put(actions.getUserReportDetailsSuccess(response));
    },
    failHandler: yield function* (response) {
      yield put(actions.getUserReportDetailsFail(response));
      toast.error(response);
    },

    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

// get user report collection list
export function* getUserReportCollectionListSaga(action) {
  const { id, limit, offset, search } = action?.payload;
  yield put(actions.getUserReportCollectionListStart());

  yield errorHandler({
    endpoint: `${GET_USER_REPORT_COLLECTION_LIST_URL}/${id}?offset=${offset}&limit=${limit}&search=${search}`,
    successHandler: yield function* (response) {
      yield put(actions.getUserReportCollectionListSuccess(response));
    },
    failHandler: yield function* (response) {
      yield put(actions.getUserReportCollectionListFail(response));
      toast.error(response);
    },

    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

// get collection details
export function* getCollectionDetailsSaga(action) {
  const { id } = action?.payload;
  yield put(actions.getCollectionDetailsStart());

  yield errorHandler({
    endpoint: `${GET_COLLECTION_DETAILS_URL}/${id}`,
    successHandler: yield function* (response) {
      yield put(actions.getCollectionDetailsSuccess(response));
    },
    failHandler: yield function* (response) {
      yield put(actions.getCollectionDetailsFail(response));
      toast.error(response);
    },

    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

// hide collection
export function* hideCollectionSaga(action) {
  const { id, handleGetCollectionDetails, hideModal, dispatch } = action?.payload;
  yield put(actions.hideCollectionStart());

  yield errorHandler({
    endpoint: `${HIDE_COLLECTION_URL}/${id}`,
    successHandler: yield function* (response) {
      yield put(actions.hideCollectionSuccess(response));
      if (handleGetCollectionDetails) handleGetCollectionDetails();
      if (hideModal) dispatch(hideModal());
      toast.success(response?.message);
    },
    failHandler: yield function* (response) {
      yield put(actions.hideCollectionFail(response));
      toast.error(response);
    },

    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'put',
    token: true,
  });
}

//  get collection item list
export function* getCollectionItemListSaga(action) {
  const { id, limit, offset, search, orderBy, orderType } = action?.payload;
  yield put(actions.getCollectionItemListStart());

  yield errorHandler({
    endpoint: `${GET_COLLECTION_ITEM_LIST_URL}/${id}?offset=${offset}&limit=${limit}&search=${search}&orderBy=${orderBy}&orderType=${orderType}`,
    successHandler: yield function* (response) {
      yield put(actions.getCollectionItemListSuccess(response));
    },
    failHandler: yield function* (response) {
      yield put(actions.getCollectionItemListFail(response));
      toast.error(response);
    },

    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

//  get user report collection item list
export function* getUserReportCollectionItemListSaga(action) {
  const { id, limit, offset, search, orderBy, orderType } = action?.payload;
  yield put(actions.getUserReportCollectionItemListStart());
  yield errorHandler({
    endpoint: `${GET_USER_REPORT_COLLECTION_ITEM_LIST_URL}/${id}?offset=${offset}&limit=${limit}&search=${search}&orderBy=${orderBy}&orderType=${orderType}`,
    successHandler: yield function* (response) {
      yield put(actions.getUserReportCollectionItemListSuccess(response));
    },
    failHandler: yield function* (response) {
      yield put(actions.getUserReportCollectionItemListFail(response));
    },

    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

//  get nft item details
export function* getNftItemDetailsSaga(action) {
  const { id } = action?.payload;
  yield put(actions.getNftItemDetailsStart(action));
  yield errorHandler({
    endpoint: `${GET_NFT_ITEM_DETAILS_URL}/${id}`,
    successHandler: yield function* (response) {
      yield put(actions.getNftItemDetailsSuccess(response));
    },
    failHandler: yield function* (response) {
      yield put(actions.getNftItemDetailsFail(response));
    },

    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

//  hide nft details
export function* hideNftDetailsSaga(action) {
  const { id, handleGetNftDetails, hideModal, dispatch } = action?.payload;
  yield put(actions.hideNftDetailsStart());
  yield errorHandler({
    endpoint: `${HIDE_NFT_DETAILS_URL}/${id}`,
    successHandler: yield function* (response) {
      yield put(actions.hideNftDetailsSuccess(response));
      if (handleGetNftDetails) handleGetNftDetails();
      if (hideModal) dispatch(hideModal());
      toast.success(response?.message);
    },
    failHandler: yield function* (response) {
      yield put(actions.hideNftDetailsFail(response));
      toast.error(response);
    },

    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'put',
    token: true,
  });
}
