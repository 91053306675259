import { toast } from 'react-toastify';

import errorHandler from 'utils/apiHandler';
import { put } from 'redux-saga/effects';
import * as actions from '../../actions';
import {
  GET_CATEGORY_LIST_URL,
  GET_CATEGORY_DETAILS_URL,
  CREATE_CATEGORY_URL,
  UPDATE_CATEGORY_URL,
  DELETE_CATEGORY_URL,
} from 'apis';

// get category list saga
export function* getCategoryListSaga({ payload }) {
  const { limit, offset, URL = '' } = payload;
  yield put(actions.getCategoryListStart());
  yield errorHandler({
    endpoint: `${GET_CATEGORY_LIST_URL}?limit=${limit}&offset=${offset}${URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getCategoryListSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getCategoryListFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get category details saga
export function* getCategoryDetailsSaga({ payload }) {
  const { id } = payload;
  yield put(actions.getCategoryDetailsStart());
  yield errorHandler({
    endpoint: `${GET_CATEGORY_DETAILS_URL}/${id}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getCategoryDetailsSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getCategoryDetailsFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// create category
export function* createCategorySaga({ payload }) {
  const { data, handleGetCategoryList, closeCategoryModal } = payload;
  yield put(actions.createCategoryStart());
  yield errorHandler({
    endpoint: CREATE_CATEGORY_URL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.createCategorySuccess(data));
      toast.success(response?.message);
      if (closeCategoryModal) closeCategoryModal();
      if (handleGetCategoryList) handleGetCategoryList();
    },
    failHandler: yield function* (response) {
      yield put(actions.createCategoryFail(response));
      toast.error(response?.message);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'post',
    payload: data,
    token: true,
  });
}

// update category
export function* updateCategorySaga({ payload }) {
  const { categoryId, data, handleGetCategoryList, closeCategoryModal } = payload;
  yield put(actions.updateCategoryStart());
  yield errorHandler({
    endpoint: `${UPDATE_CATEGORY_URL}/${categoryId}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.updateCategorySuccess(data));
      toast.success(response?.message);
      if (closeCategoryModal) closeCategoryModal();
      if (handleGetCategoryList) handleGetCategoryList();
    },
    failHandler: yield function* (response) {
      yield put(actions.updateCategoryFail(response));
      toast.error(response?.message);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'put',
    payload: data,
    token: true,
  });
}

// delete category
export function* deleteCategorySaga({ payload }) {
  yield put(actions.deleteCategoryStart());
  const { id, handleGetCategoryList, hideModal, dispatch } = payload;
  yield errorHandler({
    endpoint: `${DELETE_CATEGORY_URL}/${id}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.deleteCategorySuccess(data));
      toast.success(response?.message);
      if (hideModal) dispatch(hideModal());
      if (handleGetCategoryList) handleGetCategoryList();
    },
    failHandler: yield function* (response) {
      yield put(actions.deleteCategoryFail(response));
      if (hideModal) dispatch(hideModal());
      toast.error(response);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'delete',
    token: true,
  });
}
