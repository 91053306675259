/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

const initialState = {
  // get profile details
  getProfileDetailsLoading: false,
  getProfileDetailsErrorMsg: '',
  profileDetails: null,
  profilePermissions: null,
  // update password
  updatePasswordLoading: false,
  updatePasswordErrorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // get profile details
    case actionLabels.GET_PROFILE_DETAILS_START:
      return { ...state, getProfileDetailsLoading: true, getProfileDetailsErrorMsg: '' };
    case actionLabels.GET_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        getProfileDetailsLoading: false,
        getProfileDetailsErrorMsg: '',
        profileDetails: payload,
        profilePermissions: payload?._role?.permissions,
      };
    case actionLabels.GET_PROFILE_DETAILS_FAIL:
      return {
        ...state,
        getProfileDetailsLoading: false,
        getProfileDetailsErrorMsg: payload,
        profileDetails: null,
        profilePermissions: null,
      };

    // update password
    case actionLabels.UPDATE_PASSWORD_START:
      return { ...state, updatePasswordLoading: true, updatePasswordErrorMsg: '' };
    case actionLabels.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatePasswordLoading: false,
        updatePasswordErrorMsg: '',
      };
    case actionLabels.UPDATE_PASSWORD_FAIL:
      return {
        ...state,
        updatePasswordLoading: false,
        updatePasswordErrorMsg: payload,
      };

    default:
      return state;
  }
};
