import { put } from 'redux-saga/effects';
import { axiosMain, setBaseUrl } from '../http/axios/axios_main';

export default function* errorHandler({
  endpoint,
  params = null,
  successHandler = () => null,
  failHandler = () => null,
  failHandlerType = '',
  payload = {},
  apiType = '',
  token = false,
  isLogoutCall = false,
  baseAxios = '',
}) {
  token = yield sessionStorage.getItem('authToken');
  setBaseUrl(baseAxios);
  let queryString = '';
  if (params) {
    queryString = new URLSearchParams(JSON.parse(params)).toString();
  }
  if (apiType.trim() === '') {
    throw new Error('apiType is require');
  }
  try {
    let response;
    if (!token) {
      if (apiType === 'get') {
        // response = yield axiosMain.get(endpoint + queryString ? '?' : '' + queryString);
        response = yield axiosMain.get(queryString ? endpoint + '?' + queryString : endpoint);
      } else if (apiType === 'post') {
        response = yield axiosMain.post(endpoint, payload);
      } else if (apiType === 'put') {
        response = yield axiosMain.put(endpoint, payload);
      } else if (apiType === 'delete') {
        response = yield axiosMain.delete(endpoint);
      }
    } else {
      const authToken = yield sessionStorage.getItem('authToken');
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        // data: payload,
      };
      if (apiType === 'get') {
        response = yield axiosMain.get(
          queryString ? endpoint + '?' + queryString : endpoint,
          config,
        );
      } else if (apiType === 'post') {
        response = yield axiosMain.post(endpoint, payload, config);
      } else if (apiType === 'put') {
        response = yield axiosMain.put(endpoint, payload, config);
      } else if (apiType === 'patch') {
        response = yield axiosMain.patch(endpoint, payload, config);
      } else if (apiType === 'delete') {
        response = yield axiosMain.delete(endpoint, config);
      }
    }
    if (response && (response.status === 200 || response.status === 201) && response.data) {
      yield successHandler(response.data);
      // showToast && successToast(response.data);
    } else if (response !== undefined && response.status !== undefined) {
      if (
        response.data.msg !== undefined &&
        response.data.msg !== '' &&
        typeof response.data.msg === 'string'
      ) {
        if (failHandlerType === 'CUSTOM') {
          yield failHandler(response.data.msg);
        } else {
          yield put(failHandler(response.data.msg));
        }
      } else if (failHandlerType === 'CUSTOM') {
        yield failHandler('Server error! Please try again.');
      } else {
        yield put(failHandler('Server error! Please try again.'));
      }
    } else if (failHandlerType === 'CUSTOM') {
      yield failHandler('Something went wrong! Please try again.');
    } else {
      yield put(failHandler('Something went wrong! Please try again.'));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        if (failHandlerType === 'CUSTOM') {
          yield failHandler(error?.response?.data?.message);
        } else {
          yield put(failHandler(error?.response?.data?.message));
        }
      } else if (error.response.status === 401) {
        // toast.error('Session expired! please login again ');
        console.error('Session expired! please login again ');
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        // if (isLogoutCall) {
        //   successHandler({});
        // } else {
        //   // yield put(logout({ logoutType: "manual" }));
        // }
      } else if (error.response.status === 403) {
        if (failHandlerType === 'CUSTOM') {
          yield failHandler(error?.response?.data?.message);
        } else {
          yield put(failHandler(error?.response?.data));
        }
      } else if (
        (error?.response?.data?.message !== undefined &&
          error?.response?.data?.message !== '' &&
          error.response.data.errorMessage !== '' &&
          typeof error?.response?.data?.message === 'string') ||
        typeof error.response.data.errorMessage === 'string'
      ) {
        if (failHandlerType === 'CUSTOM') {
          yield failHandler(error?.response?.data?.message);
        } else {
          yield put(failHandler(error?.response?.data?.message));
        }
      } else if (failHandlerType === 'CUSTOM') {
        yield failHandler('Server error! Please try again later.');
      } else {
        yield put(failHandler('Server error! Please try again later.'));
      }
    } else if (failHandlerType === 'CUSTOM') {
      yield failHandler('Something went wrong! Please try again later.');
    } else {
      yield put(failHandler('Something went wrong! Please try again later.'));
    }
  }
}
