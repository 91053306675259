const generateURL = payload => {
  let url = '';
  // remove searchValue if its empty
  if (payload?.searchValue === '') {
    delete payload?.searchValue;
  }

  if (
    payload?.startDate === null ||
    payload?.startDate === undefined ||
    payload?.startDate === ''
  ) {
    delete payload?.startDate;
  }
  if (payload?.endDate === null || payload?.endDate === undefined || payload?.endDate === '') {
    delete payload?.endDate;
  }

  // remove null values if removeNull is true
  if (payload !== null && payload !== undefined) {
    for (let [key, value] of Object.entries(payload)) {
      if (value === null || value === 'undefined' || value === '') delete payload[key];
    }
  }

  if (payload !== null && payload !== undefined) {
    for (const [key, value] of Object.entries(payload)) {
      if (value !== '') url += `&${key}=${value}`;
    }
    return url;
  }
};

export default generateURL;
