const Dashboard = () => {
    return (
      <>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2940_23526)">
            <path
              d="M3.33325 3.33301H8.33325V9.99967H3.33325V3.33301Z"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.33325 13.333H8.33325V16.6663H3.33325V13.333Z"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.6667 10H16.6667V16.6667H11.6667V10Z"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.6667 3.33301H16.6667V6.66634H11.6667V3.33301Z"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </>
    );
  };
  
  export default Dashboard;