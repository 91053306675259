import { toast } from 'react-toastify';
import errorHandler from 'utils/apiHandler';
import { put } from 'redux-saga/effects';

import {
  GET_USER_LIST_URL,
  GET_USER_DETAILS_URL,
  DISABLE_USER_URL,
  GET_USER_DEVICE_LIST_URL,
  SUSPEND_USER_URL,
  GET_USER_COLLECTION_LIST_URL,
  GET_USER_TRANSACTION_LIST_URL,
} from 'apis';

import {
  getUserListStart,
  getUserListSuccess,
  getUserListFail,
  getUserDetailsStart,
  getUserDetailsSuccess,
  getUserDetailsFail,
  disableUserStart,
  disableUserSuccess,
  disableUserFail,
  getUserDeviceListStart,
  getUserDeviceListSuccess,
  getUserDeviceListFail,
  suspendUserStart,
  suspendUserSuccess,
  suspendUserFail,
  getUserCollectionListStart,
  getUserCollectionListSuccess,
  getUserCollectionListFail,
  getUserTransactionListStart,
  getUserTransactionListSuccess,
  getUserTransactionListFail,
} from 'store/actions';

// get user list saga
export function* getUserListSaga({ payload }) {
  const { limit, offset, URL } = payload;
  yield put(getUserListStart());
  yield errorHandler({
    endpoint: `${GET_USER_LIST_URL}?limit=${limit}&offset=${offset}${URL}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getUserListSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getUserListFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

//  get user details
export function* getUserDetailsSaga(action) {
  const { id } = action?.payload;
  yield put(getUserDetailsStart());

  yield errorHandler({
    endpoint: `${GET_USER_DETAILS_URL}/${id}`,
    successHandler: yield function* (response) {
      yield put(getUserDetailsSuccess(response));
    },
    failHandler: yield function* (response) {
      yield put(getUserDetailsFail(response));
      toast.error(response);
    },

    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}

// disable user saga
export function* disabeUserSaga({ payload }) {
  const { id, toast, handleGetUserList, handleCloseDeleteUserModal } = payload;
  yield put(disableUserStart());
  yield errorHandler({
    endpoint: `${DISABLE_USER_URL}/${id}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(disableUserSuccess(data));
      toast.success(response?.message);
      if (handleGetUserList) handleGetUserList();
      if (handleCloseDeleteUserModal) handleCloseDeleteUserModal();
    },
    failHandler: yield function* (response) {
      yield put(disableUserFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'put',
    token: true,
  });
}

// get user device list saga
export function* getUserDeviceListSaga({ payload }) {
  const { id, limit, offset } = payload;
  yield put(getUserDeviceListStart());
  yield errorHandler({
    endpoint: `${GET_USER_DEVICE_LIST_URL}/${id}?limit=${limit}&offset=${offset}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getUserDeviceListSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getUserDeviceListFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

//  suspend user
export function* suspendUserSaga(action) {
  const { id, handleGetUserDetails, hideModal, dispatch } = action?.payload;
  yield put(suspendUserStart());

  yield errorHandler({
    endpoint: `${SUSPEND_USER_URL}/${id}`,
    successHandler: yield function* (response) {
      yield put(suspendUserSuccess(response));
      if (hideModal) dispatch(hideModal());
      if (handleGetUserDetails) handleGetUserDetails();
      toast.success(response?.message);
    },
    failHandler: yield function* (response) {
      yield put(suspendUserFail(response));
      toast.error(response);
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'put',
    token: true,
  });
}

//  get user collecton list
export function* getUserCollectionListSaga() {
  yield put(getUserCollectionListStart());

  yield errorHandler({
    endpoint: GET_USER_COLLECTION_LIST_URL,
    successHandler: yield function* (response) {
      yield put(getUserCollectionListSuccess(response));
    },
    failHandler: yield function* (response) {
      yield put(getUserCollectionListFail(response));
      toast.error(response);
    },

    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'put',
    token: true,
  });
}

//  get user transaction list
export function* getUserTransactionListSaga({ payload }) {
  const { id, limit, offset, URL = '' } = payload;
  yield put(getUserTransactionListStart());
  yield errorHandler({
    endpoint: `${GET_USER_TRANSACTION_LIST_URL}/${id}?limit=${limit}&offset=${offset}${URL}`,
    successHandler: yield function* (response) {
      yield put(getUserTransactionListSuccess(response));
    },
    failHandler: yield function* (response) {
      yield put(getUserTransactionListFail(response));
    },
    failHandlerType: 'CUSTOM',
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    apiType: 'get',
    token: true,
  });
}
