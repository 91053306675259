import * as actionLabels from '../../actionLabels';

// get notification list
export const getNotificationListAction = payload => ({
  type: actionLabels.GET_NOTIFICATION_LIST_SAGA,
  payload,
});

export const getNotificationListStart = payload => ({
  type: actionLabels.GET_NOTIFICATION_LIST_START,
  payload,
});

export const getNotificationListSuccess = payload => ({
  type: actionLabels.GET_NOTIFICATION_LIST_SUCCESS,
  payload,
});

export const getNotificationListFail = payload => ({
  type: actionLabels.GET_NOTIFICATION_LIST_FAIL,
  payload,
});

// read clear notification
export const readClearNotificationAction = payload => ({
  type: actionLabels.READ_CLEAR_NOTIFICATION_SAGA,
  payload,
});

export const readClearNotificationStart = payload => ({
  type: actionLabels.READ_CLEAR_NOTIFICATION_START,
  payload,
});

export const readClearNotificationSuccess = payload => ({
  type: actionLabels.READ_CLEAR_NOTIFICATION_SUCCESS,
  payload,
});

export const readClearNotificationFail = payload => ({
  type: actionLabels.READ_CLEAR_NOTIFICATION_FAIL,
  payload,
});

// read clear all notification
export const readClearAllNotificationAction = payload => ({
  type: actionLabels.READ_CLEAR_ALL_NOTIFICATION_SAGA,
  payload,
});

export const readClearAllNotificationStart = payload => ({
  type: actionLabels.READ_CLEAR_ALL_NOTIFICATION_START,
  payload,
});

export const readClearAllNotificationSuccess = payload => ({
  type: actionLabels.READ_CLEAR_ALL_NOTIFICATION_SUCCESS,
  payload,
});

export const readClearAllNotificationFail = payload => ({
  type: actionLabels.READ_CLEAR_ALL_NOTIFICATION_FAIL,
  payload,
});

//push notification
// get notification history
export const getNotificationHistoryAction = payload => ({
  type: actionLabels.GET_NOTIFICATION_HISTORY_SAGA,
  payload,
});

export const getNotificationHistoryStart = payload => ({
  type: actionLabels.GET_NOTIFICATION_HISTORY_START,
  payload,
});

export const getNotificationHistorySuccess = payload => ({
  type: actionLabels.GET_NOTIFICATION_HISTORY_SUCCESS,
  payload,
});

export const getNotificationHistoryFail = payload => ({
  type: actionLabels.GET_NOTIFICATION_HISTORY_FAIL,
  payload,
});

// send push notification
export const sendPushNotificationAction = payload => ({
  type: actionLabels.SEND_PUSH_NOTIFICATION_SAGA,
  payload,
});

export const sendPushNotificationStart = payload => ({
  type: actionLabels.SEND_PUSH_NOTIFICATION_START,
  payload,
});

export const sendPushNotificationSuccess = payload => ({
  type: actionLabels.SEND_PUSH_NOTIFICATION_SUCCESS,
  payload,
});

export const sendPushNotificationFail = payload => ({
  type: actionLabels.SEND_PUSH_NOTIFICATION_FAIL,
  payload,
});

// resend push notification
export const resendPushNotificationAction = payload => ({
  type: actionLabels.RESEND_PUSH_NOTIFICATION_SAGA,
  payload,
});

export const resendPushNotificationStart = payload => ({
  type: actionLabels.RESEND_PUSH_NOTIFICATION_START,
  payload,
});

export const resendPushNotificationSuccess = payload => ({
  type: actionLabels.RESEND_PUSH_NOTIFICATION_SUCCESS,
  payload,
});

export const resendPushNotificationFail = payload => ({
  type: actionLabels.RESEND_PUSH_NOTIFICATION_FAIL,
  payload,
});

// delete push notification
export const deletePushNotificationAction = payload => ({
  type: actionLabels.DELETE_PUSH_NOTIFICATION_SAGA,
  payload,
});

export const deletePushNotificationStart = payload => ({
  type: actionLabels.DELETE_PUSH_NOTIFICATION_START,
  payload,
});

export const deletePushNotificationSuccess = payload => ({
  type: actionLabels.DELETE_PUSH_NOTIFICATION_SUCCESS,
  payload,
});

export const deletePushNotificationFail = payload => ({
  type: actionLabels.DELETE_PUSH_NOTIFICATION_FAIL,
  payload,
});

//kyc reminders
//create reminder
export const createKycReminderAction = payload => ({
  type: actionLabels.CREATE_REMINDER_SAGA,
  payload,
});

export const createKycReminderStart = payload => ({
  type: actionLabels.CREATE_REMINDER_START,
  payload,
});

export const createKycReminderSuccess = payload => ({
  type: actionLabels.CREATE_REMINDER_SUCCESS,
  payload,
});

export const createKycReminderFail = payload => ({
  type: actionLabels.CREATE_REMINDER_FAIL,
  payload,
});

//get reminder list
export const getKycReminderListAction = payload => ({
  type: actionLabels.GET_REMINDER_LIST_SAGA,
  payload,
});

export const getKycReminderListStart = payload => ({
  type: actionLabels.GET_REMINDER_LIST_START,
  payload,
});

export const getKycReminderListSuccess = payload => ({
  type: actionLabels.GET_REMINDER_LIST_SUCCESS,
  payload,
});

export const getKycReminderListFail = payload => ({
  type: actionLabels.GET_REMINDER_LIST_FAIL,
  payload,
});

//get reminder
export const getKycReminderAction = payload => ({
  type: actionLabels.GET_REMINDER_SAGA,
  payload,
});

export const getKycReminderStart = payload => ({
  type: actionLabels.GET_REMINDER_START,
  payload,
});

export const getKycReminderSuccess = payload => ({
  type: actionLabels.GET_REMINDER_SUCCESS,
  payload,
});

export const getKycReminderFail = payload => ({
  type: actionLabels.GET_REMINDER_FAIL,
  payload,
});

//update reminder
export const updateKycReminderAction = payload => ({
  type: actionLabels.UPDATE_REMINDER_SAGA,
  payload,
});

export const updateKycReminderStart = payload => ({
  type: actionLabels.UPDATE_REMINDER_START,
  payload,
});

export const updateKycReminderSuccess = payload => ({
  type: actionLabels.UPDATE_REMINDER_SUCCESS,
  payload,
});

export const updateKycReminderFail = payload => ({
  type: actionLabels.UPDATE_REMINDER_FAIL,
  payload,
});

//delete reminder
export const deleteKycReminderAction = payload => ({
  type: actionLabels.DELETE_REMINDER_SAGA,
  payload,
});

export const deleteKycReminderStart = payload => ({
  type: actionLabels.DELETE_REMINDER_START,
  payload,
});

export const deleteKycReminderSuccess = payload => ({
  type: actionLabels.DELETE_REMINDER_SUCCESS,
  payload,
});

export const deleteKycReminderFail = payload => ({
  type: actionLabels.DELETE_REMINDER_FAIL,
  payload,
});

//emails
//send email
export const sendEmailAction = payload => ({
  type: actionLabels.SEND_EMAIL_SAGA,
  payload,
});

export const sendEmailStart = payload => ({
  type: actionLabels.SEND_EMAIL_START,
  payload,
});

export const sendEmailSuccess = payload => ({
  type: actionLabels.SEND_EMAIL_SUCCESS,
  payload,
});

export const sendEmailFail = payload => ({
  type: actionLabels.SEND_EMAIL_FAIL,
  payload,
});

//get locations
export const getLocationsAction = payload => ({
  type: actionLabels.GET_LOCATIONS_SAGA,
  payload,
});

export const getLocationsStart = payload => ({
  type: actionLabels.GET_LOCATIONS_START,
  payload,
});

export const getLocationsSuccess = payload => ({
  type: actionLabels.GET_LOCATIONS_SUCCESS,
  payload,
});

export const getLocationsFail = payload => ({
  type: actionLabels.GET_LOCATIONS_FAIL,
  payload,
});
