/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

export const initialState = {
  // login
  isLoading: false,
  errorMsg: '',
  //forget password
  forgetPasswordLoading: false,
  forgetPasswordErrorMsg: '',
  // verify otp
  verifyOtpLoading: false,
  verifyOtpErrorMsg: '',
  // verify forget password otp
  verifyForgetPasswordOtpLoading: false,
  verifyForgetPasswordOtpErrorMsg: '',
  // reset password
  resetPasswordLoading: '',
  resetPasswordErrorMsg: '',
  // resend otp
  resendOtpLoading: '',
  resendOtpErrorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // login
    case actionLabels.LOGIN_START:
      return { ...state, isLoading: true, errorMsg: '' };
    case actionLabels.LOGIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
      };
    }
    case actionLabels.LOGIN_FAIL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload || '',
      };
    }
    case actionLabels.CLEAR_AUTH: {
      return { ...state, authToken: '', errorMsg: '' };
    }
    
    //forget password
    case actionLabels.FORGET_PASSWORD_START:
      return { ...state, forgetPasswordLoading: true, forgetPasswordErrorMsg: '' };
    case actionLabels.FORGET_PASSWORD_SUCCESS: {
      return {
        ...state,
        forgetPasswordLoading: false,
        forgetPasswordErrorMsg: '',
      };
    }
    case actionLabels.FORGET_PASSWORD_FAIL: {
      return {
        ...state,
        forgetPasswordLoading: false,
        forgetPasswordErrorMsg: payload || '',
      };
    }

    // verify otp
    case actionLabels.VERIFY_OTP_START:
      return { ...state, verifyOtpLoading: true, verifyOtpErrorMsg: '' };
    case actionLabels.VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        verifyOtpLoading: false,
        verifyOtpErrorMsg: '',
      };
    }
    case actionLabels.VERIFY_OTP_FAIL: {
      return {
        ...state,
        verifyOtpLoading: false,
        verifyOtpErrorMsg: payload || '',
      };
    }

    // verify forget password otp
    case actionLabels.VERIFY_FORGET_PASSWORD_OTP_START:
      return {
        ...state,
        verifyForgetPasswordOtpLoading: true,
        verifyForgetPasswordOtpErrorMsg: '',
      };
    case actionLabels.VERIFY_FORGET_PASSWORD_OTP_SUCCESS: {
      return {
        ...state,
        verifyForgetPasswordOtpLoading: false,
        verifyForgetPasswordOtpErrorMsg: '',
      };
    }
    case actionLabels.VERIFY_FORGET_PASSWORD_OTP_FAIL: {
      return {
        ...state,
        verifyForgetPasswordOtpLoading: false,
        verifyForgetPasswordOtpErrorMsg: payload || '',
      };
    }

    // reset password
    case actionLabels.RESET_PASSWORD_START:
      return {
        ...state,
        resetPasswordLoading: true,
        resetPasswordErrorMsg: '',
      };
    case actionLabels.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordErrorMsg: '',
      };
    }
    case actionLabels.RESET_PASSWORD_FAIL: {
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordErrorMsg: payload || '',
      };
    }

    // resend otp
    case actionLabels.RESEND_OTP_START:
      return {
        ...state,
        resendOtpLoading: true,
        resendOtpErrorMsg: '',
      };
    case actionLabels.RESEND_OTP_SUCCESS: {
      return {
        ...state,
        resendOtpLoading: false,
        resendOtpErrorMsg: '',
      };
    }
    case actionLabels.RESEND_OTP_FAIL: {
      return {
        ...state,
        resendOtpLoading: false,
        resendOtpErrorMsg: payload || '',
      };
    }

    default:
      return state;
  }
};
