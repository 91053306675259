/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

const initialState = {
  // get drop requests
  getDropsRequestsLoading: false,
  getDropsRequestsErrorMsg: '',
  dropsRequests: [],
  // get live drop
  getLiveDropsLoading: false,
  getLiveDropsErrorMsg: '',
  liveDrops: [],
  // get drop details
  getDropsDetailsLoading: false,
  getDropsDetailsErrorMsg: '',
  dropsDeatils: [],
  // approve drop
  appoveDropsLoading: false,
  appoveDropsErrorMsg: '',
  // reject drop
  rejectDropsLoading: false,
  rejectDropsErrorMsg: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // get drops requests
    case actionLabels.GET_DROPS_REQUESTS_START:
      return { ...state, getDropsRequestsLoading: true, getDropsRequestsErrorMsg: '' };
    case actionLabels.GET_DROPS_REQUESTS_SUCCESS:
      return {
        ...state,
        getDropsRequestsLoading: false,
        getDropsRequestsErrorMsg: '',
        dropsRequests: payload,
      };
    case actionLabels.GET_DROPS_REQUESTS_FAIL:
      return {
        ...state,
        getDropsRequestsLoading: false,
        getDropsRequestsErrorMsg: payload,
        dropsRequests: [],
      };

    // get live drops
    case actionLabels.GET_LIVE_DROPS_START:
      return { ...state, getLiveDropsLoading: true, getLiveDropsErrorMsg: '' };
    case actionLabels.GET_LIVE_DROPS_SUCCESS:
      return {
        ...state,
        getLiveDropsLoading: false,
        getLiveDropsErrorMsg: '',
        liveDrops: payload,
      };
    case actionLabels.GET_LIVE_DROPS_FAIL:
      return {
        ...state,
        getLiveDropsLoading: false,
        getLiveDropsErrorMsg: payload,
        liveDrops: [],
      };

    // get drops details
    case actionLabels.GET_DROPS_DETAILS_START:
      return { ...state, getDropsDetailsLoading: true, getDropsDetailsErrorMsg: '' };
    case actionLabels.GET_DROPS_DETAILS_SUCCESS:
      return {
        ...state,
        getDropsDetailsLoading: false,
        getDropsDetailsErrorMsg: '',
        dropsDeatils: payload,
      };
    case actionLabels.GET_DROPS_DETAILS_FAIL:
      return {
        ...state,
        getDropsDetailsLoading: false,
        getDropsDetailsErrorMsg: payload,
        dropsDeatils: [],
      };

    // approve drops
    case actionLabels.APPROVE_DROPS_START:
      return { ...state, appoveDropsLoading: true, appoveDropsErrorMsg: '' };
    case actionLabels.APPROVE_DROPS_SUCCESS:
      return {
        ...state,
        appoveDropsLoading: false,
        appoveDropsErrorMsg: '',
      };
    case actionLabels.APPROVE_DROPS_FAIL:
      return {
        ...state,
        appoveDropsLoading: false,
        appoveDropsErrorMsg: payload,
      };

    // reject drops
    case actionLabels.REJECT_DROPS_START:
      return { ...state, rejectDropsLoading: true, rejectDropsErrorMsg: '' };
    case actionLabels.REJECT_DROPS_SUCCESS:
      return {
        ...state,
        rejectDropsLoading: false,
        rejectDropsErrorMsg: '',
      };
    case actionLabels.REJECT_DROPS_FAIL:
      return {
        ...state,
        rejectDropsLoading: false,
        rejectDropsErrorMsg: payload,
      };

    default:
      return state;
  }
};
