import { toast } from 'react-toastify';
import { put } from 'redux-saga/effects';
import errorHandler from 'utils/apiHandler';
import * as actions from '../../actions';
import { GET_PROFILE_DETAILS_URL, UPDATE_PASSWORD_URL } from 'apis';

// get profile details
export function* getProfileDetailsSaga() {
  yield put(actions.getProfileDetailsStart());
  yield errorHandler({
    endpoint: GET_PROFILE_DETAILS_URL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.getProfileDetailsSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(actions.getProfileDetailsFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get update password
export function* updatePasswordSaga({ payload }) {
  const { data, close } = payload;
  yield put(actions.updatePasswordStart());
  yield errorHandler({
    endpoint: UPDATE_PASSWORD_URL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(actions.updatePasswordSuccess(data));
      if (close) close();
      toast.success(response?.message);
    },
    failHandler: yield function* (response) {
      yield put(actions.updatePasswordFail(response));
      toast.error(response);
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'put',
    token: true,
    payload: data,
  });
}
