/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

const initialState = {
  // get device list
  getDeviceListLoading: false,
  getDeviceListErrorMsg: '',
  deviceList: [],
  // get device details
  getDeviceDetailsLoading: false,
  getDeviceDetailsErrorMsg: '',
  deviceDetails: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // get device list
    case actionLabels.GET_DEVICE_LIST_START:
      return { ...state, getDeviceListLoading: true, getDeviceListErrorMsg: '' };
    case actionLabels.GET_DEVICE_LIST_SUCCESS:
      return {
        ...state,
        getDeviceListLoading: false,
        getDeviceListErrorMsg: '',
        deviceList: payload,
      };
    case actionLabels.GET_DEVICE_LIST_FAIL:
      return {
        ...state,
        getDeviceListLoading: false,
        getDeviceListErrorMsg: payload,
        deviceList: [],
      };
    // get device details
    case actionLabels.GET_DEVICE_DETAILS_START:
      return { ...state, getDeviceDetailsLoading: true, getDeviceDetailsErrorMsg: '' };
    case actionLabels.GET_DEVICE_DETAILS_SUCCESS:
      return {
        ...state,
        getDeviceDetailsLoading: false,
        getDeviceDetailsErrorMsg: '',
        deviceDetails: payload,
      };
    case actionLabels.GET_DEVICE_DETAILS_FAIL:
      return {
        ...state,
        getDeviceDetailsLoading: false,
        getDeviceDetailsErrorMsg: payload,
        deviceDetails: null,
      };
    default:
      return state;
  }
};
