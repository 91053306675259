import errorHandler from 'utils/apiHandler';
import { put } from 'redux-saga/effects';

import { GET_REWARD_LIST_URL } from 'apis';
import { getRewardListStart, getRewardListSuccess, getRewardListFail } from 'store/actions';

// get reward list saga
export function* getRewardListSaga({ payload }) {
  const { limit, offset, search } = payload;
  yield put(getRewardListStart());
  yield errorHandler({
    endpoint: `${GET_REWARD_LIST_URL}?limit=${limit}&offset=${offset}&search=${search}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getRewardListSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getRewardListFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}
