export const LOGIN_SAGA = 'LOGIN_SAGA';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const CLEAR_AUTH = 'CLEAR_AUTH';

export const FORGET_PASSWORD_SAGA = 'FORGET_PASSWORD_SAGA';
export const FORGET_PASSWORD_START = 'FORGET_PASSWORD_START';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL';

export const VERIFY_OTP_SAGA = 'VERIFY_OTP_SAGA';
export const VERIFY_OTP_START = 'VERIFY_OTP_START';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL';

export const VERIFY_FORGET_PASSWORD_OTP_SAGA = 'VERIFY_FORGET_PASSWORD_OTP_SAGA';
export const VERIFY_FORGET_PASSWORD_OTP_START = 'VERIFY_FORGET_PASSWORD_OTP_START';
export const VERIFY_FORGET_PASSWORD_OTP_SUCCESS = 'VERIFY_FORGET_PASSWORD_OTP_SUCCESS';
export const VERIFY_FORGET_PASSWORD_OTP_FAIL = 'VERIFY_FORGET_PASSWORD_OTP_FAIL';

export const RESET_PASSWORD_SAGA = 'RESET_PASSWORD_SAGA';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const RESEND_OTP_SAGA = 'RESEND_OTP_SAGA';
export const RESEND_OTP_START = 'RESEND_OTP_START';
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_FAIL = 'RESEND_OTP_FAIL';
    