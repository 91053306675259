export const GET_NOTIFICATION_LIST_SAGA = 'GET_NOTIFICATION_LIST_SAGA';
export const GET_NOTIFICATION_LIST_START = 'GET_NOTIFICATION_LIST_START';
export const GET_NOTIFICATION_LIST_SUCCESS = 'GET_NOTIFICATION_LIST_SUCCESS';
export const GET_NOTIFICATION_LIST_FAIL = 'GET_NOTIFICATION_LIST_FAIL';

export const READ_CLEAR_NOTIFICATION_SAGA = 'READ_CLEAR_NOTIFICATION_SAGA';
export const READ_CLEAR_NOTIFICATION_START = 'READ_CLEAR_NOTIFICATION_START';
export const READ_CLEAR_NOTIFICATION_SUCCESS = 'READ_CLEAR_NOTIFICATION_SUCCESS';
export const READ_CLEAR_NOTIFICATION_FAIL = 'READ_CLEAR_NOTIFICATION_FAIL';

export const READ_CLEAR_ALL_NOTIFICATION_SAGA = 'READ_CLEAR_ALL_NOTIFICATION_SAGA';
export const READ_CLEAR_ALL_NOTIFICATION_START = 'READ_CLEAR_ALL_NOTIFICATION_START';
export const READ_CLEAR_ALL_NOTIFICATION_SUCCESS = 'READ_CLEAR_ALL_NOTIFICATION_SUCCESS';
export const READ_CLEAR_ALL_NOTIFICATION_FAIL = 'READ_CLEAR_ALL_NOTIFICATION_FAIL';

//push notification
export const GET_NOTIFICATION_HISTORY_SAGA = 'GET_NOTIFICATION_HISTORY_SAGA';
export const GET_NOTIFICATION_HISTORY_START = 'GET_NOTIFICATION_HISTORY_START';
export const GET_NOTIFICATION_HISTORY_SUCCESS = 'GET_NOTIFICATION_HISTORY_SUCCESS';
export const GET_NOTIFICATION_HISTORY_FAIL = 'GET_NOTIFICATION_HISTORY_FAIL';

export const SEND_PUSH_NOTIFICATION_SAGA = 'SEND_PUSH_NOTIFICATION_SAGA';
export const SEND_PUSH_NOTIFICATION_START = 'SEND_PUSH_NOTIFICATION_START';
export const SEND_PUSH_NOTIFICATION_SUCCESS = 'SEND_PUSH_NOTIFICATION_SUCCESS';
export const SEND_PUSH_NOTIFICATION_FAIL = 'SEND_PUSH_NOTIFICATION_FAIL';

export const RESEND_PUSH_NOTIFICATION_SAGA = 'RESEND_PUSH_NOTIFICATION_SAGA';
export const RESEND_PUSH_NOTIFICATION_START = 'RESEND_PUSH_NOTIFICATION_START';
export const RESEND_PUSH_NOTIFICATION_SUCCESS = 'RESEND_PUSH_NOTIFICATION_SUCCESS';
export const RESEND_PUSH_NOTIFICATION_FAIL = 'RESEND_PUSH_NOTIFICATION_FAIL';

export const DELETE_PUSH_NOTIFICATION_SAGA = 'DELETE_PUSH_NOTIFICATION_SAGA';
export const DELETE_PUSH_NOTIFICATION_START = 'DELETE_PUSH_NOTIFICATION_START';
export const DELETE_PUSH_NOTIFICATION_SUCCESS = 'DELETE_PUSH_NOTIFICATION_SUCCESS';
export const DELETE_PUSH_NOTIFICATION_FAIL = 'DELETE_PUSH_NOTIFICATION_FAIL';

//kyc reminders
export const CREATE_REMINDER_SAGA = 'CREATE_REMINDER_SAGA';
export const CREATE_REMINDER_START = 'CREATE_REMINDER_START';
export const CREATE_REMINDER_SUCCESS = 'CREATE_REMINDER_SUCCESS';
export const CREATE_REMINDER_FAIL = 'CREATE_REMINDER_FAIL';

export const GET_REMINDER_LIST_SAGA = 'GET_REMINDER_LIST_SAGA';
export const GET_REMINDER_LIST_START = 'GET_REMINDER_LIST_START';
export const GET_REMINDER_LIST_SUCCESS = 'GET_REMINDER_LIST_SUCCESS';
export const GET_REMINDER_LIST_FAIL = 'GET_REMINDER_LIST_FAIL';

export const GET_REMINDER_SAGA = 'GET_REMINDER_SAGA';
export const GET_REMINDER_START = 'GET_REMINDER_START';
export const GET_REMINDER_SUCCESS = 'GET_REMINDER_SUCCESS';
export const GET_REMINDER_FAIL = 'GET_REMINDER_FAIL';

export const UPDATE_REMINDER_SAGA = 'UPDATE_REMINDER_SAGA';
export const UPDATE_REMINDER_START = 'UPDATE_REMINDER_START';
export const UPDATE_REMINDER_SUCCESS = 'UPDATE_REMINDER_SUCCESS';
export const UPDATE_REMINDER_FAIL = 'UPDATE_REMINDER_FAIL';

export const DELETE_REMINDER_SAGA = 'DELETE_REMINDER_SAGA';
export const DELETE_REMINDER_START = 'DELETE_REMINDER_START';
export const DELETE_REMINDER_SUCCESS = 'DELETE_REMINDER_SUCCESS';
export const DELETE_REMINDER_FAIL = 'DELETE_REMINDER_FAIL';

//emails
export const SEND_EMAIL_SAGA = 'SEND_EMAIL_SAGA';
export const SEND_EMAIL_START = 'SEND_EMAIL_START';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAIL = 'SEND_EMAIL_FAIL';

//get locations
export const GET_LOCATIONS_SAGA = 'GET_LOCATIONS_SAGA';
export const GET_LOCATIONS_START = 'GET_LOCATIONS_START';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAIL = 'GET_LOCATIONS_FAIL';
