import * as actionLabels from '../../actionLabels';

// create admin
export const createAdminAction = payload => ({
  type: actionLabels.CREATE_ADMIN_SAGA,
  payload,
});

export const createAdminStart = payload => ({
  type: actionLabels.CREATE_ADMIN_START,
  payload,
});

export const createAdminSuccess = payload => ({
  type: actionLabels.CREATE_ADMIN_SUCCESS,
  payload,
});

export const createAdminFail = payload => ({
  type: actionLabels.CREATE_ADMIN_FAIL,
  payload,
});

// update admin
export const updateAdminAction = payload => ({
  type: actionLabels.UPDATE_ADMIN_SAGA,
  payload,
});

export const updateAdminStart = payload => ({
  type: actionLabels.UPDATE_ADMIN_START,
  payload,
});

export const updateAdminSuccess = payload => ({
  type: actionLabels.UPDATE_ADMIN_SUCCESS,
  payload,
});

export const updateAdminFail = payload => ({
  type: actionLabels.UPDATE_ADMIN_FAIL,
  payload,
});

// delete admin
export const deleteAdminAction = payload => ({
  type: actionLabels.DELETE_ADMIN_SAGA,
  payload,
});

export const deleteAdminStart = payload => ({
  type: actionLabels.DELETE_ADMIN_START,
  payload,
});

export const deleteAdminSuccess = payload => ({
  type: actionLabels.DELETE_ADMIN_SUCCESS,
  payload,
});

export const deleteAdminFail = payload => ({
  type: actionLabels.DELETE_ADMIN_FAIL,
  payload,
});

// disable enable admin
export const disableEnableAdminAction = payload => ({
  type: actionLabels.DISABLE_ENABLE_ADMIN_SAGA,
  payload,
});

export const disableEnableAdminStart = payload => ({
  type: actionLabels.DISABLE_ENABLE_ADMIN_START,
  payload,
});

export const disableEnableAdminSuccess = payload => ({
  type: actionLabels.DISABLE_ENABLE_ADMIN_SUCCESS,
  payload,
});

export const disableEnableAdminFail = payload => ({
  type: actionLabels.DISABLE_ENABLE_ADMIN_FAIL,
  payload,
});

// get admin list
export const getAdminListAction = payload => ({
  type: actionLabels.GET_ADMIN_LIST_SAGA,
  payload,
});

export const getAdminListStart = payload => ({
  type: actionLabels.GET_ADMIN_LIST_START,
  payload,
});

export const getAdminListSuccess = payload => ({
  type: actionLabels.GET_ADMIN_LIST_SUCCESS,
  payload,
});

export const getAdminListFail = payload => ({
  type: actionLabels.GET_ADMIN_LIST_FAIL,
  payload,
});

// get all admin
export const getAllAdminAction = payload => ({
  type: actionLabels.GET_ALL_ADMIN_SAGA,
  payload,
});

export const getAllAdminStart = payload => ({
  type: actionLabels.GET_ALL_ADMIN_START,
  payload,
});

export const getAllAdminSuccess = payload => ({
  type: actionLabels.GET_ALL_ADMIN_SUCCESS,
  payload,
});

export const getAllAdminFail = payload => ({
  type: actionLabels.GET_ALL_ADMIN_FAIL,
  payload,
});

// get admin
export const getAdminAction = payload => ({
  type: actionLabels.GET_ADMIN_SAGA,
  payload,
});

export const getAdminStart = payload => ({
  type: actionLabels.GET_ADMIN_START,
  payload,
});

export const getAdminSuccess = payload => ({
  type: actionLabels.GET_ADMIN_SUCCESS,
  payload,
});

export const getAdminFail = payload => ({
  type: actionLabels.GET_ADMIN_FAIL,
  payload,
});
